// ProductCard.tsx
import React from "react";
import styled from "styled-components";
import { Card, Checkbox } from "antd";

const { Meta } = Card;

interface ProductCardProps {
  name: string;
  image: string;
  brand?: string;
  onClick: any;
}

const StyledCard = styled(Card)`
  // Adjust to match the design

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 290px; // Adjust to match the design
    padding: 65px 40px;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain; // Ensures the image fits nicely
    }
    .ant-card-meta {
      max-width: 100%;
    }

    .ant-checkbox-wrapper {
      align-self: end;
    }

    .ant-card-meta-detail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .ant-card-meta-title {
        text-align: center;
        color: var(--Black, #000);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        white-space: normal;
        margin: 20px 0 10px;
        max-width: 100%;
        overflow: initial;
      }
      .ant-card-meta-description {
        color: var(--Text---Grey, #989898);
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  @media (max-width: 768px) {
    border-radius: 8px;
    .ant-card-body {
      padding: 8px;
    }
  }
`;

export const ProductCard: React.FC<ProductCardProps> = ({
  name,
  image,
  brand,
  onClick,
}) => {
  return (
    <StyledCard hoverable cover={<></>} onClick={onClick}>
      {/* <Checkbox checked={checked} /> */}
      <img alt={name} src={image} />
      <Meta title={name} description={brand}>
        <span>{brand}</span>
      </Meta>
    </StyledCard>
  );
};

export default ProductCard;
