import React from "react";
import { Button, Image, Modal } from "antd";
import styled from "styled-components";
import icon3 from "../assets/images/Splash3.png";
import { ArrowRightOutlined } from "@ant-design/icons";
import RedesignStyling from "../constants/RedesignStyling";

const SplashDiv = styled.div`
  height: 100%;
  width: 100% !important;
  display: flex;
  top: 0;
  left: 0;
  position: relative;
  min-height: 100vh;
  flex-direction: column;
  padding: 50px 0 10px;
  div {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
    & > span {
      flex: 1;
      color: var(--Heading-Color, #2e2e2e);
      text-align: center;
      font-size: 44px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -2px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        margin: 0;
      }
    }
    .ant-image {
      flex: 1;
      width: 200px;
    }
    .ant-btn {
      flex: 0 0 65px;
      width: 600px;
      margin: 40px 0;
    }
  }
  @media (max-width: 768px) {
    padding: ${RedesignStyling.PAGE_PADDING_MOBILE};
    div {
      padding: ${RedesignStyling.PAGE_PADDING_MOBILE};
      .ant-btn {
        width: 100%;
      }
    }
  }
`;

export const SuccessSplash = ({
  visible,
  setVisible,
  setStep,
}: {
  visible: boolean;
  setVisible: any;
  setStep: any;
}) => {
  const handleClose = () => {
    setStep(0);
    setVisible(false);
  };

  return (
    <SplashDiv /* visible={visible} onCancel={handleClose} bodyStyle={{height: "100%", width: "100%", top: 0, left: 0}} footer={null} */
    >
      <div>
        <span>
          SUCCESS
          <p>You will get all alerts via email</p>
        </span>
        <Image src={icon3} preview={false} />
        <Button
          type="primary"
          icon={<ArrowRightOutlined />}
          onClick={handleClose}
        >
          Done
        </Button>
      </div>
    </SplashDiv>
  );
};
