type PostApiCheckbookRetryautopayoutInput = ((({} & {}) & {}) & {}) & {};

type PostApiCheckbookRetryautopayoutResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiChequesInput = ((({} & {}) & {}) & {}) & {
  payoutId: string;
};

type GetApiChequesResponse =
  | {
      data: {
        cheques?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiConsignersInput = ((({} & {}) & {}) & {}) & {
  search: string;
};

type GetApiConsignersResponse =
  | {
      data: {
        consigners?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiConsignersInput = (({} & {}) & {}) & {
  firstName: string;
  lastName: string;
  email: string;
  payoutData: string;
  payoutType: string;
  phone: string;
  address: string;
  idNumber: string;
  accountType: string;
  accountName: string;
  accountNumber: string;
  bank?: any;
  bankType?: string | undefined;
  branchCode: string;
  acceptedTOS: boolean;
  fbUserId?: string | undefined;
  storeId: string;
};

type PostApiConsignersResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiConsignersConsignerreportInput = (({} & {}) & {}) & {
  status: string;
  category: string;
  location: string;
  consigner: string;
  dateRangeStart: string;
  dateRangeEnd: string;
};

type GetApiConsignersConsignerreportResponse =
  | {
      data: {
        inventoriesByAge?: any;
        inventoriesByStatus?: any;
        inventoriesByCategory?: any;
        inventoriesByLocation?: any;
        bestSellingProducts?: any;
        sales?: any;
        dailySales?: any;
        pendingPayouts?: any;
        dailyPendingPayouts?: any;
        activeListings?: any;
        activeListingsGrowth?: any;
        dailyActiveListings?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiConsignersCreatefromadminInput = ((({} & {}) & {}) & {}) & {
  firstName?: any;
  lastName?: any;
  email?: any;
  phone?: any;
  address?: any;
  accountType?: any;
  accountName?: any;
  accountNumber?: any;
  bank?: any;
  bankType?: any;
  branchCode?: any;
  bankData?: any;
  storeId: number;
};

type PostApiConsignersCreatefromadminResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiConsignersVerifyInput = (({} & {}) & {}) & {
  email: string;
};

type PostApiConsignersVerifyResponse =
  | {
      data: {
        link: string;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiConsignersDeleteconsignerIdInput = ((({} & {}) & {}) & {}) & {
  id: string;
};

type PostApiConsignersDeleteconsignerIdResponse =
  | {
      data: {
        message: string;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiConsignersIdInput = (({} & {}) & {}) & {
  id: string;
  type?: string | undefined;
};

type GetApiConsignersIdResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PutApiConsignersIdInput = (({} & {}) & {}) & {
  id: string;
  isRecalculateInventoryPayout?: boolean | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  email?: string | undefined;
  payoutData?: string | undefined;
  payoutType?: string | undefined;
  phone?: string | undefined;
  address?: string | undefined;
  idNumber?: string | undefined;
  accountType?: string | undefined;
  accountName?: string | undefined;
  accountNumber?: string | undefined;
  bank?: string | undefined;
  bankType?: any;
  bankData?: any;
  branchCode?: string | undefined;
  acceptedTOS?: boolean | undefined;
  isActive?: boolean | undefined;
  emailNotifications?: any;
};

type PutApiConsignersIdResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiConsignertypesInput = ((({} & {}) & {}) & {}) & {};

type GetApiConsignertypesResponse =
  | {
      data: {
        consignerTypes: {
          id: number;
          accountType: string | null;
          sellingFee?: any;
          isDefault: boolean | null;
          categories?: any;
          minFee?: any;
          option1Value: string | null;
          option2Value: string | null;
          option3Value: string | null;
          category: string | null;
          flatFee?: any;
          storeId: number | null;
          createdAt?: any;
          updatedAt?: any;
        }[];
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiConsignertypesInput = ((({} & {}) & {}) & {}) & {
  accountType: string;
  sellingFee: number;
  minFee: number;
  flatFee: number | null;
  category?: string | undefined;
  option1Value?: string | undefined;
  option2Value?: string | undefined;
  option3Value?: string | undefined;
};

type PostApiConsignertypesResponse =
  | {
      data: {
        id: number;
        accountType: string | null;
        sellingFee?: any;
        isDefault: boolean | null;
        categories?: any;
        minFee?: any;
        option1Value: string | null;
        option2Value: string | null;
        option3Value: string | null;
        category: string | null;
        flatFee?: any;
        storeId: number | null;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiConsignertypesIdInput = (({} & {}) & {}) & {
  id: string;
};

type GetApiConsignertypesIdResponse =
  | {
      data: {
        id: number;
        accountType: string | null;
        sellingFee?: any;
        isDefault: boolean | null;
        categories?: any;
        minFee?: any;
        option1Value: string | null;
        option2Value: string | null;
        option3Value: string | null;
        category: string | null;
        flatFee?: any;
        storeId: number | null;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PutApiConsignertypesIdInput = (({} & {}) & {}) & {
  id: string;
  accountType: string;
  sellingFee?: any;
  minFee?: any;
  flatFee?: any;
  isDefault: boolean;
  category: string | null;
  option1Value: string | null;
  option2Value: string | null;
  option3Value: string | null;
};

type PutApiConsignertypesIdResponse =
  | {
      data: {
        id: number;
        accountType: string | null;
        sellingFee?: any;
        isDefault: boolean | null;
        categories?: any;
        minFee?: any;
        option1Value: string | null;
        option2Value: string | null;
        option3Value: string | null;
        category: string | null;
        flatFee?: any;
        storeId: number | null;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiEmployeesInput = ((({} & {}) & {}) & {}) & {
  search?: string | undefined;
};

type GetApiEmployeesResponse =
  | {
      data: {
        employees: {
          id: number;
          firstName: string;
          lastName: string;
          email: string;
          phone: string | null;
          accessControls?: any;
          fbUserId: string | null;
          isActive: boolean | null;
          storeId: number;
          createdAt?: any;
          updatedAt?: any;
        }[];
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiEmployeesInput = ((({} & {}) & {}) & {}) & {
  email: string;
  firstName: string;
  lastName: string;
};

type PostApiEmployeesResponse =
  | {
      data: {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        phone: string | null;
        accessControls?: any;
        fbUserId: string | null;
        isActive: boolean | null;
        storeId: number;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PutApiEmployeesAcceptIdInput = ((({} & {}) & {}) & {}) & {
  id: string;
  fbUserId: string;
};

type PutApiEmployeesAcceptIdResponse =
  | {
      data: {
        id: number;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        phone: string | null;
        address: string | null;
        idNumber: string | null;
        accountName: string | null;
        accountNumber: string | null;
        bank: string | null;
        bankType: string | null;
        branchCode: string | null;
        fbUserId: string | null;
        isStoreAccount: boolean | null;
        negativeBalance: string | null;
        ffData?: any;
        payoutData: string | null;
        payoutType: string | null;
        accountType: string | null;
        isActive: boolean | null;
        depreciatedId: number | null;
        acceptedTOS: boolean | null;
        phoneZelle: string | null;
        emailNotifications?: any;
        bankData?: any;
        consignerTypeId: number | null;
        storeId: number | null;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiEmployeesSendIdInput = ((({} & {}) & {}) & {}) & {
  id: string;
};

type PostApiEmployeesSendIdResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiEmployeesDeleteemployeeIdInput = ((({} & {}) & {}) & {}) & {
  id: string;
};

type PostApiEmployeesDeleteemployeeIdResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiEmployeesShopreportInput = ((({} & {}) & {}) & {}) & {
  dateRangeStart: string;
  dateRangeEnd: string;
};

type GetApiEmployeesShopreportResponse =
  | {
      data: {
        inventoriesPendingPayouts?: any;
        inventoriesPendingPriceRequests?: any;
        inventoriesPendingWithdrawalRequests?: any;
        sales?: any;
        totalInventoryValue?: any;
        totalActiveListings?: any;
        allConsigners?: any;
        totalPendingPayouts?: any;
        dailyInventoryValue?: any;
        dailyPendingPayouts?: any;
        dailySales?: any;
        profit?: any;
        dailyProfits?: any;
        activeListings?: any;
        activeListingsGrowth?: any;
        totalPendingPayoutsGrowth?: any;
        dailyPendingPayoutsGrowth?: any;
        dailyActiveListings?: any;
        dailyTotalListings?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiEmployeesIdInput = ((({} & {}) & {}) & {}) & {
  id: string;
  type?: string | undefined;
};

type GetApiEmployeesIdResponse =
  | {
      data: {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        phone: string | null;
        accessControls?: any;
        fbUserId: string | null;
        isActive: boolean | null;
        storeId: number;
        createdAt?: any;
        updatedAt?: any;
      } & {
        store: {
          id: number;
          name: string | null;
          handle: string | null;
          domain: string | null;
          tenantId: string | null;
          shop?: any;
          currency: string | null;
          prioritizeStore: boolean | null;
          consignerCname: string | null;
          consignerUrl: string | null;
          inStoreUrl: string | null;
          labelOptions: string[];
          logo: string | null;
          payoutOptions: string[];
          signupInfo: string[];
          shipInstructions: string | null;
          supportEmail: string | null;
          sendSoldNotif: boolean | null;
          termsOfService: string | null;
          labelSize?: any;
          absorbDiscount: boolean | null;
          automatedPayout: boolean | null;
          automatedPayoutFee?: any;
          redirectURL: string | null;
          sendFromEmail: string | null;
          customBarcode: string | null;
          livePriceChanges: boolean | null;
          emailMsgs: string | null;
          declineOldPendingInventory: boolean | null;
          shopifyTrackingParams?: any;
          customShopifyUrl: string | null;
          maintenanceMode: boolean | null;
          createdAt?: any;
          updatedAt?: any;
        };
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PutApiEmployeesIdInput = ((({} & {}) & {}) & {}) & {
  id: string;
  accessControls?: any;
  isActive?: boolean | undefined;
};

type PutApiEmployeesIdResponse =
  | {
      data: {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        phone: string | null;
        accessControls?: any;
        fbUserId: string | null;
        isActive: boolean | null;
        storeId: number;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiInventoriesInput = (({} & {}) & {}) & {
  printed?: any;
  status?: any;
  option1Value?: any;
  option2Value?: any;
  option3Value?: any;
  category?: any;
  productId?: string | undefined;
  consigner?: any;
  search?: any;
  dateRangeStart?: any;
  dateRangeEnd?: any;
  payoutId?: any;
  location?: any;
  subLocation?: any;
  sortOption?: any;
  scan?: any;
};

type GetApiInventoriesResponse =
  | {
      data: {
        inventories?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesInput = (({} & {}) & {}) & {
  inventoryFormArray?: any;
};

type PostApiInventoriesResponse =
  | {
      data: {
        inventory?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiInventoriesPaginatedinventoriesInput = (({} & {}) & {}) & {
  printed?: any;
  status?: any;
  option1Value?: any;
  option2Value?: any;
  option3Value?: any;
  category?: any;
  productId?: string | undefined;
  consigner?: any;
  search?: any;
  dateRangeStart?: any;
  dateRangeEnd?: any;
  payoutId?: any;
  location?: any;
  subLocation?: any;
  sortOption?: any;
  pageSize: string;
  currentPage?: any;
};

type GetApiInventoriesPaginatedinventoriesResponse =
  | {
      data: {
        rows?: any;
        count: number;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiInventoriesExportinventoryInput = ((({} & {}) & {}) & {}) & {
  printed: string;
  status: string;
  option1Value: string;
  option2Value: string;
  option3Value: string;
  category: string;
  productId: string;
  consigner: string;
  search: string;
  dateRangeStart: string;
  dateRangeEnd: string;
  payoutId: string;
  location: string;
  subLocation: string;
};

type GetApiInventoriesExportinventoryResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiInventoriesFilteroptionsInput = (({} & {}) & {}) & {};

type GetApiInventoriesFilteroptionsResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesRequestpricechangeInput = (({} & {}) & {}) & {
  inventory?: any;
};

type PostApiInventoriesRequestpricechangeResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesRequestinventorywithdrawalInput = (({} & {}) & {}) & {
  inventory?: any;
};

type PostApiInventoriesRequestinventorywithdrawalResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesAcceptinventorywithdrawalInput =
  ((({} & {}) & {}) & {}) & {
    inventory?: any;
  };

type PostApiInventoriesAcceptinventorywithdrawalResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesDeclineinventorywithdrawalInput =
  ((({} & {}) & {}) & {}) & {
    inventory?: any;
  };

type PostApiInventoriesDeclineinventorywithdrawalResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesDeleteinventoryInput = (({} & {}) & {}) & {
  data?: any;
};

type PostApiInventoriesDeleteinventoryResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesDeclineinventorypriceInput = (({} & {}) & {}) & {
  inventory?: any;
};

type PostApiInventoriesDeclineinventorypriceResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesAcceptinventorypriceInput = ((({} & {}) & {}) & {}) & {
  inventory?: any;
};

type PostApiInventoriesAcceptinventorypriceResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesWithdrawinventoryInput = (({} & {}) & {}) & {
  inventory?: any;
  withdrawalFee?: any;
  isWithdrawnEarly?: any;
  lastUpdatedBy?: any;
};

type PostApiInventoriesWithdrawinventoryResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesDeclineinventoryInput = (({} & {}) & {}) & {
  inventory?: any;
};

type PostApiInventoriesDeclineinventoryResponse =
  | {
      data: {
        id: number;
        code: string | null;
        location: string | null;
        packingSlip: string | null;
        requestedPrice: string | null;
        subLocation: string | null;
        status: string | null;
        shopifyId: string | null;
        isWithdrawnEarly: boolean | null;
        acceptedOn?: any | null;
        withdrawalFee: string | null;
        negativeBalance: string | null;
        ffData?: any;
        option1Value: string | null;
        option2Value: string | null;
        option3Value: string | null;
        payoutAmount?: any;
        price?: any;
        printed: string | null;
        depreciatedId: number | null;
        chequeId: number[];
        notes: string | null;
        discount?: any;
        lastUpdatedBy?: any;
        cost?: any;
        consignerId: number | null;
        orderId: number | null;
        payoutId: number | null;
        productId: number | null;
        productTemplateId: number | null;
        storeId: number | null;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesAcceptinventoryInput = (({} & {}) & {}) & {
  inventory?: any;
};

type PostApiInventoriesAcceptinventoryResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesBulkacceptinventoryInput = ((({} & {}) & {}) & {}) & {
  inventories?: any;
  location?: any;
  subLocation?: any;
  lastUpdatedBy?: any;
};

type PostApiInventoriesBulkacceptinventoryResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesBulkprintinventoryInput = ((({} & {}) & {}) & {}) & {
  bulkPrintInventory?: any;
};

type PostApiInventoriesBulkprintinventoryResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesFeesInput = (({} & {}) & {}) & {
  price?: any;
  quantity?: any;
  option1Value?: any;
  option2Value?: any;
  option3Value?: any;
  cost?: any;
  category?: any;
  consigner?: any;
};

type PostApiInventoriesFeesResponse =
  | {
      data: {
        selling?: any;
        payout?: any;
        total?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesTransferInput = (({} & {}) & {}) & {
  inventories?: any;
  location?: any;
  shouldOverrideLocationOption?: any;
  locationOption?: any;
  lastUpdatedBy?: any;
};

type PostApiInventoriesTransferResponse =
  | {
      data: {
        updateCount: number;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesAccepttransferInput = ((({} & {}) & {}) & {}) & {
  inventories?: any;
  location?: any;
  lastUpdatedBy?: any;
};

type PostApiInventoriesAccepttransferResponse =
  | {
      data: {
        updateCount: number;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiInventoriesScanInput = (({} & {}) & {}) & {
  gtin?: any;
  consigner?: any;
  location?: any;
};

type PostApiInventoriesScanResponse =
  | {
      data: {
        scanGTINInventories?: any;
        scanGTINProduct?: any;
        gtinSize?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiInventoriesIdInput = (({} & {}) & {}) & {
  id: string;
};

type GetApiInventoriesIdResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PutApiInventoriesIdInput = (({} & {}) & {}) & {
  id: string;
  consigner?: any;
  location?: any;
  option1Value?: any;
  option2Value?: any;
  option3Value?: any;
  price?: any;
  subLocation?: any;
  status?: any;
  payoutAmount?: any;
  notes?: any;
  lastUpdatedBy?: any;
};

type PutApiInventoriesIdResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiInventorylogsIdInput = (({} & {}) & {}) & {
  id: string;
};

type GetApiInventorylogsIdResponse =
  | {
      data: {
        inventoryLogs: {
          id: number;
          lastUpdatedBy?: any;
          modifiedFields?: any;
          inventoryId: number | null;
          storeId: number | null;
          createdAt?: any;
          updatedAt?: any;
        }[];
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiOrdersInput = ((({} & {}) & {}) & {}) & {
  consigner?: any;
  search?: any;
  dateRangeStart?: any;
  dateRangeEnd?: any;
};

type GetApiOrdersResponse =
  | {
      data: {
        orders: {
          id: number;
          name: string | null;
          orderDate?: any | null;
          customer?: any;
          shopifyId: string | null;
          total?: any;
          consignerId: number | null;
          storeId: number | null;
          createdAt?: any;
          updatedAt?: any;
        }[];
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiOrdersExportInput = ((({} & {}) & {}) & {}) & {
  orders?: any;
};

type PostApiOrdersExportResponse =
  | {
      data: {
        result: string;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiOrdersResyncInput = ((({} & {}) & {}) & {}) & {};

type PostApiOrdersResyncResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiOrdersResyncIdInput = ((({} & {}) & {}) & {}) & {
  id: string;
};

type GetApiOrdersResyncIdResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiOrdersIdInput = ((({} & {}) & {}) & {}) & {
  id: string;
};

type GetApiOrdersIdResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiPayoutsInput = ((({} & {}) & {}) & {}) & {
  status?: string | undefined;
  consigner?: string | undefined;
  search?: string | undefined;
  dateRangeStart?: string | undefined;
  dateRangeEnd?: string | undefined;
};

type GetApiPayoutsResponse =
  | {
      data: {
        payouts: any[];
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiPayoutsFilteroptionsInput = (({} & {}) & {}) & {};

type GetApiPayoutsFilteroptionsResponse =
  | {
      data: {
        statuses: {
          label: string;
          value: string;
        }[];
        consigners: {
          label: string;
          value: {
            id: number;
            firstName: string | null;
            lastName: string | null;
            email: string | null;
            phone: string | null;
            address: string | null;
            idNumber: string | null;
            accountName: string | null;
            accountNumber: string | null;
            bank: string | null;
            bankType: string | null;
            branchCode: string | null;
            fbUserId: string | null;
            isStoreAccount: boolean | null;
            negativeBalance: string | null;
            ffData?: any;
            payoutData: string | null;
            payoutType: string | null;
            accountType: string | null;
            isActive: boolean | null;
            depreciatedId: number | null;
            acceptedTOS: boolean | null;
            phoneZelle: string | null;
            emailNotifications?: any;
            bankData?: any;
            consignerTypeId: number | null;
            storeId: number | null;
            createdAt?: any;
            updatedAt?: any;
          };
        }[];
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiPayoutsExportInput = ((({} & {}) & {}) & {}) & {
  inventoriesToExport?: any;
};

type PostApiPayoutsExportResponse =
  | {
      data: {
        result: string;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiPayoutsPaidIdInput = ((({} & {}) & {}) & {}) & {
  id: string;
  consignersInventoryToBeMarkedAsPaid?: any;
};

type PostApiPayoutsPaidIdResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiPayoutsPayInput = ((({} & {}) & {}) & {}) & {};

type PostApiPayoutsPayResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiPayoutsIdInput = ((({} & {}) & {}) & {}) & {
  id: string;
};

type GetApiPayoutsIdResponse =
  | {
      data: {
        payout: {
          id: number;
          name: string | null;
          total?: any;
          itemCount: number | null;
          status: string | null;
          depreciatedId: number | null;
          storeId: number | null;
          createdAt?: any;
          updatedAt?: any;
        };
        inventories: (
          | {
              id: number;
              code: string | null;
              location: string | null;
              packingSlip: string | null;
              requestedPrice: string | null;
              subLocation: string | null;
              status: string | null;
              shopifyId: string | null;
              isWithdrawnEarly: boolean | null;
              acceptedOn?: any | null;
              withdrawalFee: string | null;
              negativeBalance: string | null;
              ffData?: any;
              option1Value: string | null;
              option2Value: string | null;
              option3Value: string | null;
              payoutAmount?: any;
              price?: any;
              printed: string | null;
              depreciatedId: number | null;
              chequeId: number[];
              notes: string | null;
              discount?: any;
              lastUpdatedBy?: any;
              cost?: any;
              consignerId: number | null;
              orderId: number | null;
              payoutId: number | null;
              productId: number | null;
              productTemplateId: number | null;
              storeId: number | null;
              createdAt?: any;
              updatedAt?: any;
            }
          | {
              id: number;
              firstName: string | null;
              lastName: string | null;
              email: string | null;
              phone: string | null;
              address: string | null;
              idNumber: string | null;
              accountName: string | null;
              accountNumber: string | null;
              bank: string | null;
              bankType: string | null;
              branchCode: string | null;
              fbUserId: string | null;
              isStoreAccount: boolean | null;
              negativeBalance: string | null;
              ffData?: any;
              payoutData: string | null;
              payoutType: string | null;
              accountType: string | null;
              isActive: boolean | null;
              depreciatedId: number | null;
              acceptedTOS: boolean | null;
              phoneZelle: string | null;
              emailNotifications?: any;
              bankData?: any;
              consignerTypeId: number | null;
              storeId: number | null;
              createdAt?: any;
              updatedAt?: any;
            }
          | {
              id: number;
              title: string | null;
              price: string | null;
              image: string | null;
              sku: string | null;
              brand: string | null;
              category: string | null;
              lastSale: string | null;
              currentLowest: string | null;
              stockXId: string | null;
              stockXHandle: string | null;
              color: string | null;
              desc: string | null;
              gender: string | null;
              shopifyId: string | null;
              ffData?: any;
              depreciatedId: number | null;
              handle: string | null;
              GTIN?: any;
              productTemplateId: number | null;
              storeId: number | null;
              createdAt?: any;
              updatedAt?: any;
            }
        )[];
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiProductsShopifyproductsInput = ((({} & {}) & {}) & {}) & {
  shopifyProductIds?: any;
};

type PostApiProductsShopifyproductsResponse =
  | {
      data: {
        products?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiProductsSubmittedproductsInput = (({} & {}) & {}) & {
  search?: any;
};

type GetApiProductsSubmittedproductsResponse =
  | {
      data: {
        submittedProducts: {
          id: number;
          title: string | null;
          image: string | null;
          sku: string | null;
          brand: string | null;
          category: string | null;
          gender: string | null;
          productTemplateId: number | null;
          storeId: number | null;
          createdAt?: any;
          updatedAt?: any;
        }[];
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiProductsSubmittedproductsInput = (({} & {}) & {}) & {
  brand?: any;
  category?: any;
  sku?: any;
  images?: any;
  title?: any;
  productTemplateId?: any;
  gender?: any;
};

type PostApiProductsSubmittedproductsResponse =
  | {
      data: {
        id: number;
        title: string | null;
        image: string | null;
        sku: string | null;
        brand: string | null;
        category: string | null;
        gender: string | null;
        productTemplateId: number | null;
        storeId: number | null;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiProductsResyncIdInput = (({} & {}) & {}) & {
  id?: any;
};

type GetApiProductsResyncIdResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiProductsInput = (({} & {}) & {}) & {
  search?: any;
  page?: any;
  pageSize?: any;
};

type GetApiProductsResponse =
  | {
      data: {
        products?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiProductsInput = (({} & {}) & {}) & {
  brand: string;
  category: string;
  sku: string;
  images?: any;
  title: string;
  productTemplateId: string;
  gender: string;
  GTIN?: any;
};

type PostApiProductsResponse =
  | {
      data: {
        id: number;
        title: string | null;
        price: string | null;
        image: string | null;
        sku: string | null;
        brand: string | null;
        category: string | null;
        lastSale: string | null;
        currentLowest: string | null;
        stockXId: string | null;
        stockXHandle: string | null;
        color: string | null;
        desc: string | null;
        gender: string | null;
        shopifyId: string | null;
        ffData?: any;
        depreciatedId: number | null;
        handle: string | null;
        GTIN?: any;
        productTemplateId: number | null;
        storeId: number | null;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiProductsMergeInput = ((({} & {}) & {}) & {}) & {
  fromProductId: string;
  toProductId: string;
};

type PostApiProductsMergeResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiProductsIdInput = (({} & {}) & {}) & {
  id: string;
};

type GetApiProductsIdResponse =
  | {
      data: {
        product?: any;
        duplicateProducts:
          | {
              id: number;
              title: string | null;
              price: string | null;
              image: string | null;
              sku: string | null;
              brand: string | null;
              category: string | null;
              lastSale: string | null;
              currentLowest: string | null;
              stockXId: string | null;
              stockXHandle: string | null;
              color: string | null;
              desc: string | null;
              gender: string | null;
              shopifyId: string | null;
              ffData?: any;
              depreciatedId: number | null;
              handle: string | null;
              GTIN?: any;
              productTemplateId: number | null;
              storeId: number | null;
              createdAt?: any;
              updatedAt?: any;
            }[]
          | null;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PutApiProductsIdInput = (({} & {}) & {}) & {
  id?: any;
  brand?: any | undefined;
  category?: any | undefined;
  sku?: any | undefined;
  stockXId?: any | undefined;
  shopifyId?: any | undefined;
  images?: any | undefined;
  title?: any | undefined;
  productTemplateId?: any | undefined;
  gender?: any | undefined;
  GTIN?: any | undefined;
};

type PutApiProductsIdResponse =
  | {
      data: {
        id: number;
        title: string | null;
        price: string | null;
        image: string | null;
        sku: string | null;
        brand: string | null;
        category: string | null;
        lastSale: string | null;
        currentLowest: string | null;
        stockXId: string | null;
        stockXHandle: string | null;
        color: string | null;
        desc: string | null;
        gender: string | null;
        shopifyId: string | null;
        ffData?: any;
        depreciatedId: number | null;
        handle: string | null;
        GTIN?: any;
        productTemplateId: number | null;
        storeId: number | null;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiProducttemplatesInput = (({} & {}) & {}) & {};

type GetApiProducttemplatesResponse =
  | {
      data: {
        productTemplates: {
          id: number;
          title: string | null;
          brand: string | null;
          category: string | null;
          option1: string | null;
          option2: string | null;
          option3: string | null;
          option1Values: string[];
          option2Values: string[];
          option3Values: string[];
          gender: string | null;
          weight: number | null;
          storeId: number | null;
          createdAt?: any;
          updatedAt?: any;
        }[];
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiProducttemplatesInput = ((({} & {}) & {}) & {}) & {
  title: string;
  brand: string;
  category: string;
  option1: string;
  option2?: string | undefined;
  option3?: string | undefined;
  option1Values?: any;
  option2Values?: any;
  option3Values?: any;
  weight?: string | undefined;
  gender?: string | undefined;
};

type PostApiProducttemplatesResponse =
  | {
      data: {
        id: number;
        title: string | null;
        brand: string | null;
        category: string | null;
        option1: string | null;
        option2: string | null;
        option3: string | null;
        option1Values: string[];
        option2Values: string[];
        option3Values: string[];
        gender: string | null;
        weight: number | null;
        storeId: number | null;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiProducttemplatesIdInput = (({} & {}) & {}) & {
  id: string;
};

type GetApiProducttemplatesIdResponse =
  | {
      data: {
        id: number;
        title: string | null;
        brand: string | null;
        category: string | null;
        option1: string | null;
        option2: string | null;
        option3: string | null;
        option1Values: string[];
        option2Values: string[];
        option3Values: string[];
        gender: string | null;
        weight: number | null;
        storeId: number | null;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PutApiProducttemplatesIdInput = ((({} & {}) & {}) & {}) & {
  id: string;
  title: string;
  brand: string;
  category: string;
  option1?: any;
  option2?: any;
  option3?: any;
  option1Values?: any;
  option2Values?: any;
  option3Values?: any;
  weight: string;
  gender: string;
};

type PutApiProducttemplatesIdResponse =
  | {
      data: {
        id: number;
        title: string | null;
        brand: string | null;
        category: string | null;
        option1: string | null;
        option2: string | null;
        option3: string | null;
        option1Values: string[];
        option2Values: string[];
        option3Values: string[];
        gender: string | null;
        weight: number | null;
        storeId: number | null;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type DeleteApiProducttemplatesIdInput = ((({} & {}) & {}) & {}) & {
  id: string;
};

type DeleteApiProducttemplatesIdResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiStoresInput = ((({} & {}) & {}) & {}) & {
  handle: string;
  name: string;
  email: string;
  shop: string | null;
  accessToken: string | null;
  platform: string | null;
};

type PostApiStoresResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiStoresResyncInput = ((({} & {}) & {}) & {}) & {};

type PostApiStoresResyncResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiStoresConnectshopInput = ((({} & {}) & {}) & {}) & {
  shop?: any;
};

type PostApiStoresConnectshopResponse =
  | {
      data: {
        id: number;
        name: string | null;
        handle: string | null;
        domain: string | null;
        tenantId: string | null;
        shop?: any;
        currency: string | null;
        prioritizeStore: boolean | null;
        consignerCname: string | null;
        consignerUrl: string | null;
        inStoreUrl: string | null;
        labelOptions: string[];
        logo: string | null;
        payoutOptions: string[];
        signupInfo: string[];
        shipInstructions: string | null;
        supportEmail: string | null;
        sendSoldNotif: boolean | null;
        termsOfService: string | null;
        labelSize?: any;
        absorbDiscount: boolean | null;
        automatedPayout: boolean | null;
        automatedPayoutFee?: any;
        redirectURL: string | null;
        sendFromEmail: string | null;
        customBarcode: string | null;
        livePriceChanges: boolean | null;
        emailMsgs: string | null;
        declineOldPendingInventory: boolean | null;
        shopifyTrackingParams?: any;
        customShopifyUrl: string | null;
        maintenanceMode: boolean | null;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiStoresIdInput = (({} & {}) & {}) & {
  id: string;
};

type GetApiStoresIdResponse =
  | {
      data: {
        id: number;
        name: string | null;
        handle: string | null;
        domain: string | null;
        tenantId: string | null;
        shop?: any;
        currency: string | null;
        prioritizeStore: boolean | null;
        consignerCname: string | null;
        consignerUrl: string | null;
        inStoreUrl: string | null;
        labelOptions: string[];
        logo: string | null;
        payoutOptions: string[];
        signupInfo: string[];
        shipInstructions: string | null;
        supportEmail: string | null;
        sendSoldNotif: boolean | null;
        termsOfService: string | null;
        labelSize?: any;
        absorbDiscount: boolean | null;
        automatedPayout: boolean | null;
        automatedPayoutFee?: any;
        redirectURL: string | null;
        sendFromEmail: string | null;
        customBarcode: string | null;
        livePriceChanges: boolean | null;
        emailMsgs: string | null;
        declineOldPendingInventory: boolean | null;
        shopifyTrackingParams?: any;
        customShopifyUrl: string | null;
        maintenanceMode: boolean | null;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PutApiStoresIdInput = ((({} & {}) & {}) & {}) & {
  id: string;
  name?: any;
  supportEmail?: any;
  domain?: any;
  currency?: any;
  signupInfo?: any;
  payoutOptions?: any;
  prioritizeStore?: any;
  livePriceChanges?: any;
  labelOptions?: any;
  logo?: any;
  consignerUrl?: any;
  inStoreUrl?: any;
  consignerCname?: any;
  shipInstructions?: any;
  termsOfService?: any;
  labelSize?: any;
  absorbDiscount?: any;
  emailMsgs?: any;
  redirectURL?: any;
  customBarcode?: any;
  shopifyTrackingParams?: any;
  customShopifyUrl?: any;
};

type PutApiStoresIdResponse =
  | {
      data: {
        id: number;
        name: string | null;
        handle: string | null;
        domain: string | null;
        tenantId: string | null;
        shop?: any;
        currency: string | null;
        prioritizeStore: boolean | null;
        consignerCname: string | null;
        consignerUrl: string | null;
        inStoreUrl: string | null;
        labelOptions: string[];
        logo: string | null;
        payoutOptions: string[];
        signupInfo: string[];
        shipInstructions: string | null;
        supportEmail: string | null;
        sendSoldNotif: boolean | null;
        termsOfService: string | null;
        labelSize?: any;
        absorbDiscount: boolean | null;
        automatedPayout: boolean | null;
        automatedPayoutFee?: any;
        redirectURL: string | null;
        sendFromEmail: string | null;
        customBarcode: string | null;
        livePriceChanges: boolean | null;
        emailMsgs: string | null;
        declineOldPendingInventory: boolean | null;
        shopifyTrackingParams?: any;
        customShopifyUrl: string | null;
        maintenanceMode: boolean | null;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiSublocationsInput = (({} & {}) & {}) & {};

type GetApiSublocationsResponse =
  | {
      data: {
        subLocations: {
          id: number;
          name: string | null;
          locationName: string | null;
          storeId: number;
          createdAt?: any;
          updatedAt?: any;
        }[];
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostApiSublocationsInput = ((({} & {}) & {}) & {}) & {
  name: string;
  locationName: string;
};

type PostApiSublocationsResponse =
  | {
      data: {
        id: number;
        name: string | null;
        locationName: string | null;
        storeId: number;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetApiSublocationsIdInput = (({} & {}) & {}) & {
  id: string;
};

type GetApiSublocationsIdResponse =
  | {
      data: {
        id: number;
        name: string | null;
        locationName: string | null;
        storeId: number;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PutApiSublocationsIdInput = ((({} & {}) & {}) & {}) & {
  id: string;
  name: string;
};

type PutApiSublocationsIdResponse =
  | {
      data: {
        id: number;
        name: string | null;
        locationName: string | null;
        storeId: number;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetOpenChequesInput = {} & {
  dateRangeStart: string;
  dateRangeEnd: string;
};

type GetOpenChequesResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetOpenEmployeesIdInput = {} & {
  id: string;
  type?: string | undefined;
};

type GetOpenEmployeesIdResponse =
  | {
      data: {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        phone: string | null;
        accessControls?: any;
        fbUserId: string | null;
        isActive: boolean | null;
        storeId: number;
        createdAt?: any;
        updatedAt?: any;
      } & {
        store: {
          id: number;
          name: string | null;
          handle: string | null;
          domain: string | null;
          tenantId: string | null;
          shop?: any;
          currency: string | null;
          prioritizeStore: boolean | null;
          consignerCname: string | null;
          consignerUrl: string | null;
          inStoreUrl: string | null;
          labelOptions: string[];
          logo: string | null;
          payoutOptions: string[];
          signupInfo: string[];
          shipInstructions: string | null;
          supportEmail: string | null;
          sendSoldNotif: boolean | null;
          termsOfService: string | null;
          labelSize?: any;
          absorbDiscount: boolean | null;
          automatedPayout: boolean | null;
          automatedPayoutFee?: any;
          redirectURL: string | null;
          sendFromEmail: string | null;
          customBarcode: string | null;
          livePriceChanges: boolean | null;
          emailMsgs: string | null;
          declineOldPendingInventory: boolean | null;
          shopifyTrackingParams?: any;
          customShopifyUrl: string | null;
          maintenanceMode: boolean | null;
          createdAt?: any;
          updatedAt?: any;
        };
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PutOpenEmployeesAcceptIdInput = {} & {
  id: string;
  accessControls?: any;
  isActive?: boolean | undefined;
};

type PutOpenEmployeesAcceptIdResponse =
  | {
      data: {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        phone: string | null;
        accessControls?: any;
        fbUserId: string | null;
        isActive: boolean | null;
        storeId: number;
        createdAt?: any;
        updatedAt?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetOpenPosCodeInput = {} & {
  code: string;
};

type GetOpenPosCodeResponse =
  | {
      data: {
        type: string;
        inventory?: any;
      };
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostOpenStoresInput = {} & {
  handle: string;
  name: string;
  email: string;
  shop: string | null;
  accessToken: string | null;
  platform: string | null;
};

type PostOpenStoresResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type PostOpenStoresAddshoptostoreInput = {} & {
  shop: string;
  accessToken: string;
  storeId: string;
  devLocalTunnelUrl?: string | undefined;
};

type PostOpenStoresAddshoptostoreResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetOpenStoresDomainInput = {} & {
  domain: string;
};

type GetOpenStoresDomainResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type GetOpenRedirectverificationdomainInput = ({} & {}) & {
  domain: string;
};

type GetOpenRedirectverificationdomainResponse =
  | {
      data: {
        redirect: string;
      };
      error: null;
    }
  | {
      data: null;
      error: string;
    };

type GetShopifyInput = ({} & {}) & {
  shop?: any;
};

type GetShopifyResponse =
  | {
      data: {
        redirect: string;
        stateCookie: string;
      };
      error: null;
    }
  | {
      data: null;
      error: string;
    };

type GetShopifyCallbackInput = ({} & {}) & {
  shop?: any;
  hmac?: any;
  code?: any;
  state?: any;
};

type GetShopifyCallbackResponse =
  | {
      data: {
        redirect: string;
      };
      error: null;
    }
  | {
      data: null;
      error: string;
    };

type PostWebhooksShopifyIdInput = ({} & {}) & {};

type PostWebhooksShopifyIdResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: string;
    };

type BookDemoResponse =
  | {
      data: {};
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        statusCode: number;
      };
    };

type BookDemoInput = (({} & {}) & {}) & {
  value?: boolean | string;
};
type PostApiStoreMigrateInput = ((({} & {}) & {}) & {}) & {
  isMigrate?: any;
};

export type Path =
  | "/api/checkbook/retryAutoPayout"
  | "/api/cheques"
  | "/api/consigners"
  | "/api/consigners"
  | "/api/consigners/consignerReport"
  | "/api/consigners/createFromAdmin"
  | "/api/consigners/verify"
  | "/api/consigners/sendStoreRequestedItemsEmail"
  | "/api/consigners/deleteConsigner/:id"
  | "/api/consigners/updatePassword"
  | "/api/consigners/:id"
  | "/api/consigners/:id"
  | "/api/consignerTypes"
  | "/api/consignerTypes"
  | "/api/consignerTypes/:id"
  | "/api/consignerTypes/:id"
  | "/api/employees"
  | "/api/employees"
  | "/api/employees/accept/:id"
  | "/api/employees/send/:id"
  | "/api/employees/deleteEmployee/:id"
  | "/api/employees/shopReport"
  | "/api/employees/storeSaleSummary"
  | "/api/employees/:id"
  | "/api/employees/:id"
  | "/api/inventories"
  | "/api/inventories"
  | "/api/inventories/getLowestPriceForVariant"
  | "/api/inventories/paginatedInventories"
  | "/api/inventories/ordersPayoutInventories"
  | "/api/inventories/consignerRequestedItems"
  | "/api/inventories/exportInventory"
  | "/api/inventories/filterOptions"
  | "/api/inventories/requestPriceChange"
  | "/api/inventories/storeRequestPriceChange"
  | "/api/inventories/requestInventoryWithdrawal"
  | "/api/inventories/acceptInventoryWithdrawal"
  | "/api/inventories/declineInventoryWithdrawal"
  | "/api/inventories/deleteInventory"
  | "/api/inventories/declineInventoryPrice"
  | "/api/inventories/acceptInventoryPrice"
  | "/api/inventories/withdrawInventory"
  | "/api/inventories/declineInventory"
  | "/api/inventories/acceptInventory"
  | "/api/inventories/storeBuyRequestedInventory"
  | "/api/inventories/acceptRequestToSell"
  | "/api/inventories/bulkAcceptInventory"
  | "/api/inventories/bulkAcceptRequestToSell"
  | "/api/inventories/bulkPrintInventory"
  | "/api/inventories/preapprovalStatus"
  | "/api/inventories/cancelDropoff"
  | "/api/inventories/fees"
  | "/api/inventories/transfer"
  | "/api/inventories/acceptTransfer"
  | "/api/inventories/scan"
  | "/api/inventories/:id"
  | "/api/inventories/code/:code"
  | "/api/inventories/getStoreProducts"
  | "/api/inventories/inStoreInventoryDetails"
  | "/api/inventories/sendMessageToSeller"
  | "/api/inventories/getStoreInventoryStatus"
  | "/api/inventoryExtras"
  | "/api/inventoryLogs/:id"
  | "/api/orders"
  | "/api/orders/export"
  | "/api/orders/resync"
  | "/api/orders/createDraftOrder"
  | "/api/orders/resync/:id"
  | "/api/orders/:id"
  | "/api/payouts"
  | "/api/payouts/filterOptions"
  | "/api/payouts/export"
  | "/api/payouts/consigner/:id"
  | "/api/payouts/paid/:id"
  | "/api/payouts/pay"
  | "/api/payouts/:id"
  | "/api/products/shopifyProducts"
  | "/api/products/submittedProducts"
  | "/api/products/submittedProducts"
  | "/api/products/resync/:id"
  | "/api/products"
  | "/api/products"
  | "/api/products/merge"
  | "/api/products/lowestVariantPrices/:id"
  | "/api/products/:id"
  | "/api/products/:id"
  | "/api/productTemplates"
  | "/api/productTemplates"
  | "/api/productTemplates/:id"
  | "/api/productTemplates/:id"
  | "/api/productTemplates/:id"
  | "/api/stores"
  | "/api/stores/resync"
  | "/api/stores/connectShop"
  | "/api/stores/:id"
  | "/api/stores/:id"
  | "/api/subLocations"
  | "/api/subLocations"
  | "/api/subLocations/:id"
  | "/api/subLocations/:id"
  | "/api/scans"
  | "/api/scans"
  | "/api/scans/:id"
  | "/api/scans/:id"
  | "/open/cheques"
  | "/open/employees/:id"
  | "/open/employees/accept/:id"
  | "/open/pos/code"
  | "/open/stores"
  | "/open/stores/addShopToStore"
  | "/open/stores/:domain"
  | "/open/redirectVerificationDomain"
  | "/open/stores/getStoreProducts"
  | "/open/stores/getStoreBuyandSellInfo"
  | "/open/stores/createBuyOrder"
  | "/open/stores/products/:id"
  | "/open/stores/acceptRequestedInventoryPriceChange/:code"
  | "/open/stores/rejectRequestedInventoryPriceChange/:code"
  | "/shopify"
  | "/shopify/callback"
  | "/webhooks/shopify/:id"
  | "/api/stores/storeMigrate"
  | "/api/stores/bookDemo";

export type Method = "get" | "post" | "put" | "delete" | "patch";

export type MethodPath = `${Method} ${Path}`;

export interface Input extends Record<MethodPath, any> {
  "post /api/checkbook/retryAutoPayout": PostApiCheckbookRetryautopayoutInput;
  "get /api/cheques": GetApiChequesInput;
  "get /api/consigners": GetApiConsignersInput;
  "post /api/consigners": PostApiConsignersInput;
  "get /api/consigners/consignerReport": GetApiConsignersConsignerreportInput;
  "post /api/consigners/createFromAdmin": PostApiConsignersCreatefromadminInput;
  "post /api/consigners/verify": PostApiConsignersVerifyInput;
  "post /api/consigners/deleteConsigner/:id": PostApiConsignersDeleteconsignerIdInput;
  "get /api/consigners/:id": GetApiConsignersIdInput;
  "put /api/consigners/:id": PutApiConsignersIdInput;
  "get /api/consignerTypes": GetApiConsignertypesInput;
  "post /api/consignerTypes": PostApiConsignertypesInput;
  "get /api/consignerTypes/:id": GetApiConsignertypesIdInput;
  "put /api/consignerTypes/:id": PutApiConsignertypesIdInput;
  "get /api/employees": GetApiEmployeesInput;
  "post /api/employees": PostApiEmployeesInput;
  "put /api/employees/accept/:id": PutApiEmployeesAcceptIdInput;
  "post /api/employees/send/:id": PostApiEmployeesSendIdInput;
  "post /api/employees/deleteEmployee/:id": PostApiEmployeesDeleteemployeeIdInput;
  "get /api/employees/shopReport": GetApiEmployeesShopreportInput;
  "get /api/employees/:id": GetApiEmployeesIdInput;
  "put /api/employees/:id": PutApiEmployeesIdInput;
  "get /api/inventories": GetApiInventoriesInput;
  "post /api/inventories": PostApiInventoriesInput;
  "get /api/inventories/paginatedInventories": GetApiInventoriesPaginatedinventoriesInput;
  "get /api/inventories/exportInventory": GetApiInventoriesExportinventoryInput;
  "get /api/inventories/filterOptions": GetApiInventoriesFilteroptionsInput;
  "post /api/inventories/requestPriceChange": PostApiInventoriesRequestpricechangeInput;
  "post /api/inventories/requestInventoryWithdrawal": PostApiInventoriesRequestinventorywithdrawalInput;
  "post /api/inventories/acceptInventoryWithdrawal": PostApiInventoriesAcceptinventorywithdrawalInput;
  "post /api/inventories/declineInventoryWithdrawal": PostApiInventoriesDeclineinventorywithdrawalInput;
  "post /api/inventories/deleteInventory": PostApiInventoriesDeleteinventoryInput;
  "post /api/inventories/declineInventoryPrice": PostApiInventoriesDeclineinventorypriceInput;
  "post /api/inventories/acceptInventoryPrice": PostApiInventoriesAcceptinventorypriceInput;
  "post /api/inventories/withdrawInventory": PostApiInventoriesWithdrawinventoryInput;
  "post /api/inventories/declineInventory": PostApiInventoriesDeclineinventoryInput;
  "post /api/inventories/acceptInventory": PostApiInventoriesAcceptinventoryInput;
  "post /api/inventories/acceptRequestToSell": PostApiInventoriesAcceptinventoryInput;
  "post /api/inventories/bulkAcceptInventory": PostApiInventoriesBulkacceptinventoryInput;
  "post /api/inventories/bulkAcceptRequestToSell": PostApiInventoriesBulkacceptinventoryInput;
  "post /api/inventories/bulkPrintInventory": PostApiInventoriesBulkprintinventoryInput;
  "post /api/inventories/fees": PostApiInventoriesFeesInput;
  "post /api/inventories/transfer": PostApiInventoriesTransferInput;
  "post /api/inventories/acceptTransfer": PostApiInventoriesAccepttransferInput;
  "post /api/inventories/scan": PostApiInventoriesScanInput;
  "get /api/inventories/:id": GetApiInventoriesIdInput;
  "put /api/inventories/:id": PutApiInventoriesIdInput;
  "get /api/inventoryLogs/:id": GetApiInventorylogsIdInput;
  "get /api/orders": GetApiOrdersInput;
  "post /api/orders/export": PostApiOrdersExportInput;
  "post /api/orders/resync": PostApiOrdersResyncInput;
  "get /api/orders/resync/:id": GetApiOrdersResyncIdInput;
  "get /api/orders/:id": GetApiOrdersIdInput;
  "get /api/payouts": GetApiPayoutsInput;
  "get /api/payouts/filterOptions": GetApiPayoutsFilteroptionsInput;
  "post /api/payouts/export": PostApiPayoutsExportInput;
  "post /api/payouts/paid/:id": PostApiPayoutsPaidIdInput;
  "post /api/payouts/pay": PostApiPayoutsPayInput;
  "get /api/payouts/:id": GetApiPayoutsIdInput;
  "post /api/products/shopifyProducts": PostApiProductsShopifyproductsInput;
  "get /api/products/submittedProducts": GetApiProductsSubmittedproductsInput;
  "post /api/products/submittedProducts": PostApiProductsSubmittedproductsInput;
  "get /api/products/resync/:id": GetApiProductsResyncIdInput;
  "get /api/products": GetApiProductsInput;
  "post /api/products": PostApiProductsInput;
  "post /api/products/merge": PostApiProductsMergeInput;
  "get /api/products/:id": GetApiProductsIdInput;
  "put /api/products/:id": PutApiProductsIdInput;
  "get /api/productTemplates": GetApiProducttemplatesInput;
  "post /api/productTemplates": PostApiProducttemplatesInput;
  "get /api/productTemplates/:id": GetApiProducttemplatesIdInput;
  "put /api/productTemplates/:id": PutApiProducttemplatesIdInput;
  "delete /api/productTemplates/:id": DeleteApiProducttemplatesIdInput;
  "post /api/stores": PostApiStoresInput;
  "post /api/stores/resync": PostApiStoresResyncInput;
  "post /api/stores/connectShop": PostApiStoresConnectshopInput;
  "get /api/stores/:id": GetApiStoresIdInput;
  "put /api/stores/:id": PutApiStoresIdInput;
  "get /api/subLocations": GetApiSublocationsInput;
  "post /api/subLocations": PostApiSublocationsInput;
  "get /api/subLocations/:id": GetApiSublocationsIdInput;
  "put /api/subLocations/:id": PutApiSublocationsIdInput;
  "get /open/cheques": GetOpenChequesInput;
  "get /open/employees/:id": GetOpenEmployeesIdInput;
  "put /open/employees/accept/:id": PutOpenEmployeesAcceptIdInput;
  "get /open/pos/code": GetOpenPosCodeInput;
  "post /open/stores": PostOpenStoresInput;
  "post /open/stores/addShopToStore": PostOpenStoresAddshoptostoreInput;
  "get /open/stores/:domain": GetOpenStoresDomainInput;
  "get /open/redirectVerificationDomain": GetOpenRedirectverificationdomainInput;
  "get /shopify": GetShopifyInput;
  "get /shopify/callback": GetShopifyCallbackInput;
  "post /webhooks/shopify/:id": PostWebhooksShopifyIdInput;
  "post /api/stores/bookDemo": BookDemoInput;
  "put  /api/stores/storeMigrate": PostApiStoreMigrateInput;
}

export interface Response extends Record<MethodPath, any> {
  "post /api/checkbook/retryAutoPayout": PostApiCheckbookRetryautopayoutResponse;
  "get /api/cheques": GetApiChequesResponse;
  "get /api/consigners": GetApiConsignersResponse;
  "post /api/consigners": PostApiConsignersResponse;
  "get /api/consigners/consignerReport": GetApiConsignersConsignerreportResponse;
  "post /api/consigners/createFromAdmin": PostApiConsignersCreatefromadminResponse;
  "post /api/consigners/verify": PostApiConsignersVerifyResponse;
  "post /api/consigners/deleteConsigner/:id": PostApiConsignersDeleteconsignerIdResponse;
  "get /api/consigners/:id": GetApiConsignersIdResponse;
  "put /api/consigners/:id": PutApiConsignersIdResponse;
  "get /api/consignerTypes": GetApiConsignertypesResponse;
  "post /api/consignerTypes": PostApiConsignertypesResponse;
  "get /api/consignerTypes/:id": GetApiConsignertypesIdResponse;
  "put /api/consignerTypes/:id": PutApiConsignertypesIdResponse;
  "get /api/employees": GetApiEmployeesResponse;
  "post /api/employees": PostApiEmployeesResponse;
  "put /api/employees/accept/:id": PutApiEmployeesAcceptIdResponse;
  "post /api/employees/send/:id": PostApiEmployeesSendIdResponse;
  "post /api/employees/deleteEmployee/:id": PostApiEmployeesDeleteemployeeIdResponse;
  "get /api/employees/shopReport": GetApiEmployeesShopreportResponse;
  "get /api/employees/:id": GetApiEmployeesIdResponse;
  "put /api/employees/:id": PutApiEmployeesIdResponse;
  "get /api/inventories": GetApiInventoriesResponse;
  "post /api/inventories": PostApiInventoriesResponse;
  "get /api/inventories/paginatedInventories": GetApiInventoriesPaginatedinventoriesResponse;
  "get /api/inventories/exportInventory": GetApiInventoriesExportinventoryResponse;
  "get /api/inventories/filterOptions": GetApiInventoriesFilteroptionsResponse;
  "post /api/inventories/requestPriceChange": PostApiInventoriesRequestpricechangeResponse;
  "post /api/inventories/requestInventoryWithdrawal": PostApiInventoriesRequestinventorywithdrawalResponse;
  "post /api/inventories/acceptInventoryWithdrawal": PostApiInventoriesAcceptinventorywithdrawalResponse;
  "post /api/inventories/declineInventoryWithdrawal": PostApiInventoriesDeclineinventorywithdrawalResponse;
  "post /api/inventories/deleteInventory": PostApiInventoriesDeleteinventoryResponse;
  "post /api/inventories/declineInventoryPrice": PostApiInventoriesDeclineinventorypriceResponse;
  "post /api/inventories/acceptInventoryPrice": PostApiInventoriesAcceptinventorypriceResponse;
  "post /api/inventories/withdrawInventory": PostApiInventoriesWithdrawinventoryResponse;
  "post /api/inventories/declineInventory": PostApiInventoriesDeclineinventoryResponse;
  "post /api/inventories/acceptInventory": PostApiInventoriesAcceptinventoryResponse;
  "post /api/inventories/acceptRequestToSell": PostApiInventoriesAcceptinventoryResponse;
  "post /api/inventories/bulkAcceptInventory": PostApiInventoriesBulkacceptinventoryResponse;
  "post /api/inventories/bulkAcceptRequestToSell": PostApiInventoriesBulkacceptinventoryResponse;
  "post /api/inventories/bulkPrintInventory": PostApiInventoriesBulkprintinventoryResponse;
  "post /api/inventories/fees": PostApiInventoriesFeesResponse;
  "post /api/inventories/transfer": PostApiInventoriesTransferResponse;
  "post /api/inventories/acceptTransfer": PostApiInventoriesAccepttransferResponse;
  "post /api/inventories/scan": PostApiInventoriesScanResponse;
  "get /api/inventories/:id": GetApiInventoriesIdResponse;
  "put /api/inventories/:id": PutApiInventoriesIdResponse;
  "get /api/inventoryLogs/:id": GetApiInventorylogsIdResponse;
  "get /api/orders": GetApiOrdersResponse;
  "post /api/orders/export": PostApiOrdersExportResponse;
  "post /api/orders/resync": PostApiOrdersResyncResponse;
  "get /api/orders/resync/:id": GetApiOrdersResyncIdResponse;
  "get /api/orders/:id": GetApiOrdersIdResponse;
  "get /api/payouts": GetApiPayoutsResponse;
  "get /api/payouts/filterOptions": GetApiPayoutsFilteroptionsResponse;
  "post /api/payouts/export": PostApiPayoutsExportResponse;
  "post /api/payouts/paid/:id": PostApiPayoutsPaidIdResponse;
  "post /api/payouts/pay": PostApiPayoutsPayResponse;
  "get /api/payouts/:id": GetApiPayoutsIdResponse;
  "post /api/products/shopifyProducts": PostApiProductsShopifyproductsResponse;
  "get /api/products/submittedProducts": GetApiProductsSubmittedproductsResponse;
  "post /api/products/submittedProducts": PostApiProductsSubmittedproductsResponse;
  "get /api/products/resync/:id": GetApiProductsResyncIdResponse;
  "get /api/products": GetApiProductsResponse;
  "post /api/products": PostApiProductsResponse;
  "post /api/products/merge": PostApiProductsMergeResponse;
  "get /api/products/:id": GetApiProductsIdResponse;
  "put /api/products/:id": PutApiProductsIdResponse;
  "get /api/productTemplates": GetApiProducttemplatesResponse;
  "post /api/productTemplates": PostApiProducttemplatesResponse;
  "get /api/productTemplates/:id": GetApiProducttemplatesIdResponse;
  "put /api/productTemplates/:id": PutApiProducttemplatesIdResponse;
  "delete /api/productTemplates/:id": DeleteApiProducttemplatesIdResponse;
  "post /api/stores": PostApiStoresResponse;
  "post /api/stores/resync": PostApiStoresResyncResponse;
  "post /api/stores/connectShop": PostApiStoresConnectshopResponse;
  "get /api/stores/:id": GetApiStoresIdResponse;
  "put /api/stores/:id": PutApiStoresIdResponse;
  "get /api/subLocations": GetApiSublocationsResponse;
  "post /api/subLocations": PostApiSublocationsResponse;
  "get /api/subLocations/:id": GetApiSublocationsIdResponse;
  "put /api/subLocations/:id": PutApiSublocationsIdResponse;
  "get /open/cheques": GetOpenChequesResponse;
  "get /open/employees/:id": GetOpenEmployeesIdResponse;
  "put /open/employees/accept/:id": PutOpenEmployeesAcceptIdResponse;
  "get /open/pos/code": GetOpenPosCodeResponse;
  "post /open/stores": PostOpenStoresResponse;
  "post /open/stores/addShopToStore": PostOpenStoresAddshoptostoreResponse;
  "get /open/stores/:domain": GetOpenStoresDomainResponse;
  "get /open/redirectVerificationDomain": GetOpenRedirectverificationdomainResponse;
  "get /shopify": GetShopifyResponse;
  "get /shopify/callback": GetShopifyCallbackResponse;
  "post /webhooks/shopify/:id": PostWebhooksShopifyIdResponse;
  "post /api/stores/bookDemo": BookDemoResponse;
  "put  /api/stores/storeMigrate": PostApiStoreMigrateInput;
}

export const jsonEndpoints = {
  "post /api/checkbook/retryAutoPayout": true,
  "get /api/cheques": true,
  "get /api/consigners": true,
  "post /api/consigners": true,
  "get /api/consigners/consignerReport": true,
  "post /api/consigners/createFromAdmin": true,
  "post /api/consigners/verify": true,
  "post /api/consigners/deleteConsigner/:id": true,
  "get /api/consigners/:id": true,
  "put /api/consigners/:id": true,
  "get /api/consignerTypes": true,
  "post /api/consignerTypes": true,
  "get /api/consignerTypes/:id": true,
  "put /api/consignerTypes/:id": true,
  "get /api/employees": true,
  "post /api/employees": true,
  "put /api/employees/accept/:id": true,
  "post /api/employees/send/:id": true,
  "post /api/employees/deleteEmployee/:id": true,
  "get /api/employees/shopReport": true,
  "get /api/employees/:id": true,
  "put /api/employees/:id": true,
  "get /api/inventories": true,
  "post /api/inventories": true,
  "get /api/inventories/paginatedInventories": true,
  "get /api/inventories/exportInventory": true,
  "get /api/inventories/filterOptions": true,
  "post /api/inventories/requestPriceChange": true,
  "post /api/inventories/requestInventoryWithdrawal": true,
  "post /api/inventories/acceptInventoryWithdrawal": true,
  "post /api/inventories/declineInventoryWithdrawal": true,
  "post /api/inventories/deleteInventory": true,
  "post /api/inventories/declineInventoryPrice": true,
  "post /api/inventories/acceptInventoryPrice": true,
  "post /api/inventories/withdrawInventory": true,
  "post /api/inventories/declineInventory": true,
  "post /api/inventories/acceptInventory": true,
  "post /api/inventories/acceptRequestToSell": true,
  "post /api/inventories/bulkAcceptInventory": true,
  "post /api/inventories/bulkAcceptRequestToSell": true,
  "post /api/inventories/bulkPrintInventory": true,
  "post /api/inventories/fees": true,
  "post /api/inventories/transfer": true,
  "post /api/inventories/acceptTransfer": true,
  "post /api/inventories/scan": true,
  "get /api/inventories/:id": true,
  "put /api/inventories/:id": true,
  "get /api/inventoryLogs/:id": true,
  "get /api/orders": true,
  "post /api/orders/export": true,
  "post /api/orders/resync": true,
  "get /api/orders/resync/:id": true,
  "get /api/orders/:id": true,
  "get /api/payouts": true,
  "get /api/payouts/filterOptions": true,
  "post /api/payouts/export": true,
  "post /api/payouts/paid/:id": true,
  "post /api/payouts/pay": true,
  "get /api/payouts/:id": true,
  "post /api/products/shopifyProducts": true,
  "get /api/products/submittedProducts": true,
  "post /api/products/submittedProducts": true,
  "get /api/products/resync/:id": true,
  "get /api/products": true,
  "post /api/products": true,
  "post /api/products/merge": true,
  "get /api/products/:id": true,
  "put /api/products/:id": true,
  "get /api/productTemplates": true,
  "post /api/productTemplates": true,
  "get /api/productTemplates/:id": true,
  "put /api/productTemplates/:id": true,
  "delete /api/productTemplates/:id": true,
  "post /api/stores": true,
  "post /api/stores/resync": true,
  "post /api/stores/connectShop": true,
  "get /api/stores/:id": true,
  "put /api/stores/:id": true,
  "get /api/subLocations": true,
  "post /api/subLocations": true,
  "get /api/subLocations/:id": true,
  "put /api/subLocations/:id": true,
  "get /open/cheques": true,
  "get /open/employees/:id": true,
  "put /open/employees/accept/:id": true,
  "get /open/pos/code": true,
  "post /open/stores": true,
  "post /open/stores/addShopToStore": true,
  "get /open/stores/:domain": true,
  "get /open/redirectVerificationDomain": true,
  "get /shopify": true,
  "get /shopify/callback": true,
  "post /webhooks/shopify/:id": true,
  "post /api/stores/bookDemo": true,
  "put  /api/stores/storeMigrate": true,
};

export type Provider = <M extends Method, P extends Path>(
  method: M,
  path: P,
  params: Input[`${M} ${P}`]
) => Promise<Response[`${M} ${P}`]>;

export type Implementation = (
  method: Method,
  path: string,
  params: Record<string, any>
) => Promise<any>;

/*
export const exampleImplementation: Implementation = async (
  method,
  path,
  params
) => {
  const hasBody = !["get", "delete"].includes(method);
  const searchParams = hasBody ? "" : `?${new URLSearchParams(params)}`;
  const response = await fetch(`https://example.com${path}${searchParams}`, {
    method: method.toUpperCase(),
    headers: hasBody ? { "Content-Type": "application/json" } : undefined,
    body: hasBody ? JSON.stringify(params) : undefined,
  });
  if (`${method} ${path}` in jsonEndpoints) {
    return response.json();
  }
  return response.text();
};

const client = new ExpressZodAPIClient(exampleImplementation);
client.provide("get", "/v1/user/retrieve", { id: "10" });
*/
export class ExpressZodAPIClient {
  constructor(protected readonly implementation: Implementation) {}
  public readonly provide: Provider = (method, path, params) =>
    this.implementation(
      method,
      Object.keys(params).reduce(
        (acc, key) => acc.replace(`:${key}`, params[key]),
        path
      ),
      Object.keys(params).reduce(
        (acc, key) =>
          path.indexOf(`:${key}`) >= 0 ? acc : { ...acc, [key]: params[key] },
        {}
      )
    );
}
