import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CustomFooter, GridContainer, StyledNextButton } from "./ProductGrid";
import {
  Button,
  Card,
  Form,
  Image,
  Input,
  InputNumber,
  Select,
  message,
} from "antd";
import getSymbolFromCurrency from "currency-symbol-map";
import { CloseCircleOutlined } from "@ant-design/icons";
import { ButtonGroup } from "@mui/material";
import PushPinOutlined from "../assets/images/pin.png";
import ShopOutlined from "../assets/images/shop.png";
import { Inventory } from "../redux/reducers/InventoryReducer";
import { Store } from "../redux/reducers/StoreReducer";
import { useAppSelector } from "../redux/hooks";
import { AppState } from "../redux/reducers/AppReducer";
import {
  ProductTemplate,
  ProductTemplateState,
} from "../redux/reducers/ProductTemplateReducer";

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  flex-direction: column;
  min-height: calc(100vh - 85px);
  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 0;
    div.MuiButtonGroup-root {
      display: flex;
      gap: 16px;
      .ant-btn {
        display: flex;
        height: 68px;
        padding: 11.5px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
    }
    .ant-card {
      border-radius: 0;
      //   background: #fbfcff;
      margin-bottom: 13px;
      &.selected {
        outline: 1px solid #40a9ff;
      }
    }
    .ant-form {
      span {
        label {
          display: flex;
          width: 100%;
          color: #989898;
          align-items: center;
          flex-shrink: 0;
          margin-bottom: 8px;
        }
        .ant-input {
          display: flex;
          width: 100%;
          height: 47px;
          padding: 15px 17px;
          align-items: center;
          flex-shrink: 0;
          margin-bottom: 13px;
          font-size: 16px;
        }
      }
    }
  }
`;

const ProductItemList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5;
  gap: 16px;
`;

const ProductItemCard = styled(Card)`
  display: flex;
  height: auto;
  // width: 820px;
  cursor: pointer;
  .ant-card-body {
    display: flex;
    padding: 9px 4px;
    justify-content: space-between;
    .ant-image {
      max-height: 100%;
      object-fit: contain;
      img {
        max-height: 100%;
      }
    }
    div {
      flex: 1;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const PriceInput = styled.div`
  margin: 24px 0;
  display: inline-flex;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #fff;
  gap: 5px;
  & > div {
    flex: 1;
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);
    background: var(--White, #fff);
    display: flex;
    align-items: center;
    span {
      padding: 0 11px;
    }
  }
  & > span {
    flex: 1;
  }
  .ant-btn {
    flex: 0 0 80px;
    align-self: stretch;
    height: 100%;
  }
  .ant-input,
  .ant-input-number,
  .ant-input-prefix {
    height: 60px;
    color: var(--Light---Black, #292d32);
    text-align: center;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    b {
      flex: 1;
    }
  }
  .ant-input {
    text-align: left;
  }
  .ant-input-number {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    input {
      text-align: center;
    }
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-prefix {
    text-align: right;
  }
`;

const PaySummary = styled.span`
  display: flex;
  width: 320px;
  justify-content: space-between;
  align-items: flex-end;
  gap: 45px;
  label,
  b {
    flex: 0 0 auto;
    display: inline;
  }
  div {
    flex: 0 0 212px;
    border-bottom: 1px dotted gray;
  }
`;

const CustomBadge = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  min-width: auto;
  width: 25px;
  height: 25px;
  line-height: 1;
}
`;

const PayCard = styled(Card)`
  flex: 1;
  margin: 24px 0;
  cursor: pointer;

  &.selected {
    outline: 1px solid #40a9ff;
  }
  .ant-card-body {
    display: flex;
    padding: 10px 0;
    & > .ant-image {
      margin: 0 24px;
      width: 32.128px;
      height: 31.019px;
      flex-shrink: 0;
      font-size: 24px;
    }
    div {
      display: flex;
      flex-direction: column;
      .ant-btn {
        border-radius: 8px;
        background: var(--Light---Black, #292d32);
        display: flex;
        width: 82.218px;
        height: 29.772px;
        padding: 11.5px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        color: #fff;
      }
    }
    // flex-direction: column;
  }
`;

const PaymentTab = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`;

const PayHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductItemCardMobile = styled(Card)`
  &:not(:first-of-type) {
    border-top: 1px solid #262626;
  }
  .ant-card-body {
    gap: 8px;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
  span {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    p {
      margin-bottom: 4px;
      color: var(--Black, #000);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    b {
      label {
        display: block;
        color: var(--Text---Grey, #989898);
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .ant-btn {
      color: #868383;
      text-align: center;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 1; /* 173.333% */
      text-transform: capitalize;
      padding: 0;
      display: flex;
      align-items: start;
      justify-content: center;
    }
    & > [class^="ant-input-number"],
    .ant-select {
      font-size: 16px;
      flex: 1;
      text-align: left;
      border: 1px solid var(--Stroke-Color, #e4e4e7);
      background: var(--White, #fff);
      display: flex;
      height: 52px;
      padding: 16px 12px;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      .ant-select-selector {
        border: none;
      }
    }
    .ant-input-number {
      font-size: 16px;
    }
  }
`;

export const StepTwo = ({
  inventoriesToAdd,
  indexItemToEdit,
  setIndexItemToEdit,
  store,
  setInventoriesToAdd,
  newInventory,
  setNewInventory,
  candidateQty,
  candidatePrice,
  setCandidateQty,
  setCandidatePrice,
  setCurrentStep,
  methodDelivery,
  setMethodDelivery,
  location,
  setShowLocationModal,
  dataLoading,
  productTemplates,
  consignerData,
  setConsignerData,
  currentStep,
  handleCompleteProcess,
  dataSubmitting,
}: {
  inventoriesToAdd: Inventory[];
  indexItemToEdit: number;
  setIndexItemToEdit: any;
  store: Store;
  setInventoriesToAdd: any;
  newInventory: Inventory[];
  setNewInventory: any;
  candidateQty: number;
  candidatePrice: number;
  setCandidateQty: any;
  setCandidatePrice: any;
  setCurrentStep: any;
  methodDelivery: number;
  setMethodDelivery: any;
  location: string;
  setShowLocationModal: any;
  dataLoading: boolean;
  productTemplates?: ProductTemplate[];
  consignerData: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    zipCode: string;
  };
  setConsignerData: any;
  currentStep: number;
  handleCompleteProcess: any;
  dataSubmitting: boolean;
}) => {
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);

  useEffect(() => {
    // console.log(productTemplates);
  }, [isMobile]);

  useEffect(() => {
    setIndexItemToEdit(0);
  }, []);

  if (isMobile)
    return (
      <Container>
        {currentStep === 1 ? (
          <div>
            {inventoriesToAdd.map((inventory: Inventory, key: number) => (
              <ProductItemCardMobile key={key} bordered={false}>
                <span>
                  <div>
                    <p>{inventory.product.title}</p>
                    <b>
                      Request Price
                      <label>{inventory?.option1Value}</label>
                    </b>
                  </div>
                  <Button
                    type="text"
                    onClick={() =>
                      setInventoriesToAdd([
                        ...inventoriesToAdd.filter((_, idx) => idx !== key),
                      ])
                    }
                  >
                    Remove
                  </Button>
                </span>
                <span>
                  {" "}
                  <br />{" "}
                </span>
                <span>
                  <InputNumber
                    style={{ flex: 1 }}
                    value={inventory?.quantity}
                    onChange={(value: any) => {
                      setInventoriesToAdd([
                        ...inventoriesToAdd.map((inv, idx) => {
                          if (idx !== key) return inv;
                          else return { ...inv, quantity: value };
                        }),
                      ]);
                    }}
                  />
                  <Select
                    style={{ flex: 3 }}
                    value={inventory?.option2Value}
                    onChange={(value: any) => {
                      setInventoriesToAdd([
                        ...inventoriesToAdd.map((inv, idx) => {
                          if (idx !== key) return inv;
                          else return { ...inv, option2Value: value };
                        }),
                      ]);
                    }}
                  >
                    {productTemplates
                      ?.filter(
                        (productTemplate) =>
                          productTemplate.id ===
                          inventory?.product?.productTemplateId
                      )?.[0]
                      ?.option2Values?.map((option2Value, optIdx) => (
                        <Select.Option value={option2Value} key={optIdx}>
                          {option2Value}
                        </Select.Option>
                      ))}
                  </Select>
                </span>
                <span>
                  <InputNumber
                    prefix={getSymbolFromCurrency(store.currency)}
                    value={inventory?.price}
                    onChange={(value: any) => {
                      setInventoriesToAdd([
                        ...inventoriesToAdd.map((inv, idx) => {
                          if (idx !== key) return inv;
                          else return { ...inv, price: value };
                        }),
                      ]);
                    }}
                  />
                </span>
              </ProductItemCardMobile>
            ))}
            <ButtonGroup>
              <Button
                style={{ flex: 2 }}
                onClick={() => setCurrentStep((prev) => prev - 1)}
              >
                Add more
              </Button>
              <Button
                style={{ flex: 3 }}
                type="primary"
                onClick={() => setCurrentStep(2)}
                disabled={inventoriesToAdd.some(
                  (inventory) => Number(inventory.price) === 0
                )}
              >
                Next
              </Button>
            </ButtonGroup>
          </div>
        ) : (
          <div style={{ paddingBottom: 150 }}>
            <Card
              style={{
                borderRadius: 8,
                border: "1px solid #292D32",
              }}
              className={`${methodDelivery === 1 ? "selected" : ""}`}
              onClick={() => setMethodDelivery(1)}
            >
              <span>
                <Image preview={false} src={PushPinOutlined} />
                <b>Drop Off</b>
              </span>
              <Input
                bordered={false}
                readOnly
                onClick={() => setShowLocationModal(true)}
                value={location}
                placeholder="This is where we want to add the address"
              />
            </Card>
            <Card
              style={{
                borderRadius: 8,
                border: "1px solid #292D32",
              }}
              className={`${methodDelivery === 2 ? "selected" : ""}`}
              onClick={() => setMethodDelivery(1)}
            >
              {" "}
              {/* should be 2 */}
              <span>
                <Image preview={false} src={ShopOutlined} />
                <b>Ship to Store</b>
              </span>
              <Input bordered={false} readOnly placeholder="California" />
              <p style={{ margin: 0 }}>Flat fee of $15</p>
            </Card>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "30px 0",
              }}
            >
              <b>Contact</b>
              <label>Fill Your Information</label>
            </span>
            <Form>
              <span>
                <label>First Name</label>
                <Input
                  required
                  value={consignerData.firstName}
                  onChange={(value) =>
                    setConsignerData({
                      ...consignerData,
                      firstName: value.target.value,
                    })
                  }
                />
              </span>
              <span>
                <label>Last Name</label>
                <Input
                  required
                  value={consignerData.lastName}
                  onChange={(value) =>
                    setConsignerData({
                      ...consignerData,
                      lastName: value.target.value,
                    })
                  }
                />
              </span>
              <span>
                <label>Email</label>
                <Input
                  required
                  value={consignerData.email}
                  onChange={(value) =>
                    setConsignerData({
                      ...consignerData,
                      email: value.target.value,
                    })
                  }
                />
              </span>
              <span>
                <label>Phone Number</label>
                <Input
                  required
                  value={consignerData.phone}
                  onChange={(value) =>
                    setConsignerData({
                      ...consignerData,
                      phone: value.target.value,
                    })
                  }
                />
              </span>
              <span>
                <label>Zip Code</label>
                <Input
                  required
                  value={consignerData.zipCode}
                  onChange={(value) =>
                    setConsignerData({
                      ...consignerData,
                      zipCode: value.target.value,
                    })
                  }
                />
              </span>
            </Form>
            <CustomFooter style={{ padding: 8 }}>
              <span style={{ gap: 8 }}>
                <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                  <Button onClick={() => setCurrentStep(1)}>Back</Button>
                </div>
                <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                  <Button
                    disabled={
                      consignerData.email === "" ||
                      consignerData.phone === "" ||
                      consignerData.zipCode === "" ||
                      consignerData.firstName === "" ||
                      consignerData.lastName === "" ||
                      dataSubmitting ||
                      location === "" ||
                      !location
                    }
                    type="primary"
                    onClick={handleCompleteProcess}
                  >
                    Done
                  </Button>
                </div>
              </span>
            </CustomFooter>
          </div>
        )}
      </Container>
    );
  return (
    <GridContainer style={{ width: "100%", maxWidth: "100%" }}>
      <h1>Get Paid</h1>
      <div style={{ display: "flex", gap: 40 }}>
        <ProductItemList>
          {inventoriesToAdd.map((inventory: Inventory, key: number) => (
            <ProductItemCard
              bordered={key === indexItemToEdit}
              key={key}
              style={{
                borderColor: key === indexItemToEdit ? "#40a9ff" : "initial",
              }}
              onClick={() => setIndexItemToEdit(key)}
            >
              <div
                style={{
                  flex: "0 0 120px",
                  paddingRight: 10,
                  position: "relative",
                }}
              >
                <CustomBadge
                  type="primary"
                  shape="circle"
                  style={{ position: "absolute", top: 0, right: 5 }}
                  // color="#67abf4"
                >
                  {inventory?.quantity}
                </CustomBadge>
                <Image src={inventory?.product?.image} />
              </div>
              <div style={{ flex: 3 }}>
                <label>MODEL</label>
                <b>{inventory?.product?.title}</b>
              </div>
              <div>
                <label>SKU</label>
                <b style={{ wordBreak: "break-all" }}>
                  {inventory?.product?.sku}&nbsp;
                </b>
              </div>
              <div>
                <label>Size</label>
                <b>{inventory?.option1Value}</b>
              </div>
              <div>
                <label>Price</label>
                <b>
                  {getSymbolFromCurrency(store.currency)}
                  {inventory?.price}
                </b>
              </div>
              <div>
                <label>Condition</label>
                <b>{inventory?.option2Value}</b>
              </div>
              <div style={{ flex: 0 }}>
                <Button
                  type="text"
                  onClick={() =>
                    setInventoriesToAdd([
                      ...inventoriesToAdd.filter((_, idx) => idx !== key),
                    ])
                  }
                  danger
                  icon={<CloseCircleOutlined />}
                />
              </div>
            </ProductItemCard>
          ))}
          {newInventory &&
            newInventory.map((newInventoryData, idx: number) => (
              <ProductItemCard key={idx} bordered>
                <div style={{ flex: "0 0 120px", paddingRight: 10 }}>
                  <Image src={newInventoryData?.product?.image} />
                </div>
                <div>
                  <label>MODEL</label>
                  <b>{newInventoryData?.product?.title}</b>
                </div>
                <div>
                  <label>SKU</label>
                  <b>{newInventoryData?.product?.sku}&nbsp;</b>
                </div>
                <div>
                  <label>Size</label>
                  <b>{newInventoryData?.option1Value}</b>
                </div>
                <div>
                  <label>Price</label>
                  <b>
                    {getSymbolFromCurrency(store.currency)}
                    {newInventoryData?.price}
                  </b>
                </div>
                <div>
                  <label>Condition</label>
                  <b>{newInventoryData?.option2Value}</b>
                </div>
                <div style={{ flex: 0 }}>
                  <Button
                    type="text"
                    danger
                    onClick={() => setNewInventory([])}
                    icon={<CloseCircleOutlined />}
                  />
                </div>
              </ProductItemCard>
            ))}
          <Button
            style={{ color: "var(--Text---Grey, #989898)", fontSize: 14 }}
            disabled={
              inventoriesToAdd.length > 0 &&
              inventoriesToAdd?.some(
                (inventory) => !inventory.price || inventory?.price === 0
              )
            }
            type="text"
            onClick={() => setCurrentStep((prev) => prev - 1)}
          >
            ADD MORE PRODUCTS
          </Button>
        </ProductItemList>
        <PaymentTab>
          <PayHeader>
            <b>Request Price</b>
            <label>Input your price</label>
          </PayHeader>
          <PriceInput>
            <div>
              <InputNumber
                prefix={null}
                controls={{ upIcon: null, downIcon: null }}
                readOnly={indexItemToEdit < 0}
                bordered={false}
                value={candidateQty}
                onChange={(value) => setCandidateQty(value ?? 0)}
              />
            </div>
            <div style={{ flex: 3 }}>
              <Input
                readOnly={indexItemToEdit < 0}
                bordered={false}
                prefix={<b>{getSymbolFromCurrency(store.currency)}</b>}
                value={candidatePrice}
                onChange={(value) => setCandidatePrice(+value.target.value)}
              />
            </div>
          </PriceInput>
          <PayHeader>
            <b>Shipping</b>
            <label>Please select one</label>
          </PayHeader>
          <div>
            <span style={{ display: "flex", flexWrap: "nowrap", gap: 16 }}>
              <PayCard
                className={`${methodDelivery === 1 ? "selected" : ""}`}
                onClick={() => setMethodDelivery(1)}
              >
                <Image preview={false} src={PushPinOutlined} />
                <div>
                  <b>Drop Off</b>
                  <label onClick={() => setShowLocationModal(true)}>
                    {location}&nbsp;
                  </label>
                  <br />
                  <Button
                    disabled={dataLoading}
                    onClick={() => setShowLocationModal(true)}
                  >
                    Select
                  </Button>
                </div>
              </PayCard>
              <PayCard
                className={`${methodDelivery === 2 ? "selected" : ""}`}
                style={{ cursor: "not-allowed" }}
                onClick={() => setMethodDelivery(1)}
              >
                {" "}
                {/* should be 2 */}
                <Image preview={false} src={ShopOutlined} />
                <div>
                  <b>Ship to Store</b>
                  <label>{location}&nbsp;</label>
                </div>
                {/* <Button>Select</Button> */}
              </PayCard>
            </span>
          </div>
          <ButtonGroup
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              gap: 12,
              marginBottom: 50,
            }}
          >
            <Button
              style={{
                alignSelf: "stretch",
                height: 45,
                // width: 203,
                maxWidth: "50%",
                flex: 1,
                borderRadius: 8,
                background: "var(--Grey---Button-Fill, #F4F4F4)",
              }}
              type="default"
              onClick={() => setCurrentStep((prev) => prev - 1)}
            >
              Back
            </Button>
            <StyledNextButton
              style={{
                position: "relative",
                left: "initial",
                bottom: "initial",
                transform: "none",
                maxWidth: "50%",
                flex: 1,
                height: 45,
              }}
              // disabled={ /*  || (newInventory && Number(newInventory.price) === 0) */}
              onClick={() => {
                if (
                  indexItemToEdit !== inventoriesToAdd.length - 1 ||
                  inventoriesToAdd.some(
                    (inventory) => Number(inventory.price) === 0
                  )
                ) {
                  setIndexItemToEdit((prev) => prev + 1);
                } else {
                  if (location === "" || !location) {
                    message.error("Drop Off location is required.");
                  } else {
                    setCurrentStep((prev) => prev + 1);
                  }
                }
              }}
            >
              Next
            </StyledNextButton>
          </ButtonGroup>
        </PaymentTab>
      </div>
    </GridContainer>
  );
};
