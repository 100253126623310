import {
  Col,
  DatePicker,
  Form,
  Modal,
  Pagination,
  Row,
  Select,
  Spin,
  Switch,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import styled from "styled-components";
import {
  getInventoryFilterOptions,
  getInventories,
  exportInventory,
  getPaginatedInventories,
} from "../redux/actions/inventoryActions";
import { getSubLocations } from "../redux/actions/subLocationActions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Inventory, InventoryState } from "../redux/reducers/InventoryReducer";
import { ProductInventory } from "../components/Common/ProductInventoryList";
import { Product } from "../redux/reducers/ProductReducer";
import Colors from "../constants/Colors";
import { Input, Button, Image, Table } from "antd";
import Content from "../constants/Content";
import InventoryFilter from "../components/Common/InventoryFilter";
const { Search } = Input;
import { ReactComponent as SneakerMove } from "../assets/images/svg/SneakerMove.svg";
import { ReactComponent as Money } from "../assets/images/svg/Money.svg";
import { ReactComponent as Tag } from "../assets/images/svg/Tag.svg";
import moment from "moment";
import _ from "lodash";
import {
  getConsigner,
  getConsignerReport,
  updateConsigner,
} from "../redux/actions/consignerActions";
import { Consigner, ConsignerState } from "../redux/reducers/ConsignerReducer";
import { StoreState } from "../redux/reducers/StoreReducer";
import {
  // GroupOutlined,
  UnorderedListOutlined,
  EditOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ReactComponent as GroupOutlined } from "../assets/images/svg/GroupedTable.svg";
// import { ReactComponent as EditOutlined } from "../assets/images/svg/TripleDot.svg";
import { Link } from "@mui/material";
import getSymbolFromCurrency from "currency-symbol-map";
import { Footer } from "antd/lib/layout/layout";
import RedesignStyling from "../constants/RedesignStyling";
import { AppState } from "../redux/reducers/AppReducer";
import { getConsignerTypes } from "../redux/actions/consignerTypeActions";
import { ReviewListingView } from "../consignerScreens/ConsignerProducts";
import "../consignerScreens/ConsignerInventory.css";
import OrderFilter from "../components/Common/OrderFilter";
import AuthService from "../services/auth";
import {
  CustomInventoryFilterContainer,
  HorizontalScrollableContainer,
  SrchBx,
} from "./Inventory";
import { ConsignerTypeState } from "../redux/reducers/ConsignerTypeReducer";
import InventoryCardView, {
  StyledButton,
} from "../components/Common/InventoryCardView";
import ButtonGroup from "antd/lib/button/button-group";

const { Option } = Select;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${RedesignStyling.PAGE_BG};
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: ${RedesignStyling.PAGE_PADDING};
  #inventoryFilter {
    margin: 0 0 0 15px;
    flex-wrap: nowrap;
    &.ant-form-inline .ant-form-item:last-of-type {
      margin-right: 0;
    }
  }
  @media (max-width: 768px) {
    padding: ${RedesignStyling.PAGE_PADDING_MOBILE};
    #inventoryFilter {
      margin: 0;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    height: 40px;
    color: #292d32;
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);
    background: var(--White, #fff);
  }
  table {
    thead {
      .ant-checkbox-inner {
        background-color: transparent; /* Customize the check color */
      }
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  width: 100%;
  form {
    flex: 1;
    p {
      color: var(--heading-color, #2e2e2e);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 130% */
      text-transform: capitalize;
      margin: 0;
    }
    button {
      display: flex;
      width: 207px;
      height: 65px;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    span {
      display: flex;
      gap: 12px;
      .ant-form-item {
        margin: 24px 0;
        flex: 1;
        input {
          border: 1px solid var(--stroke-color, #e4e4e7);
          background: var(--base-white, #fff);
          display: flex;
          height: 65px;
          padding: 16px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1 0 0;
        }
      }
    }
  }
  @media (max-width: 768px) {
    form {
      & > span {
        flex-wrap: wrap;
        .ant-form-item {
          margin: 0;
          flex: 0 0 100%;
        }
        margin: 24px 0;
      }
    }
  }
`;

export const MobileFooter = styled(Footer)`
  display: none;
  justify-content: center;
  align-items: center;
  order: 6;
  width: 100%;
  background: ${Colors.CONSIGNER_GREY};
  color: ${Colors.WHITE};
  @media (max-width: 768px) {
    display: flex;
  }
`;

const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 74px 16px 50px;
  width: 100%;
  & > span {
    // width: 900px;
    max-width: 100%;
    height: 65px;
  }
  .ant-input-group.ant-input-wrapper {
    display: flex;
    height: 100%;
    .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH});
    }
  }
  button.ant-input-search-button {
    padding: 8px 16px;
    background-color: ${Colors.CONSIGNER_BLUE};
    color: ${Colors.WHITE};
    border: 0;
    height: 65px;
    width: ${RedesignStyling.SEARCH_BUTTON_WIDTH};
  }

  @media (max-width: 768px) {
    margin: 40px 0 8px;
    // order: 2;

    .ant-input-group.ant-input-wrapper .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE});
      width: 100%;
    }

    .ant-input-search-with-button .ant-input-group input.ant-input {
      font-size: 16px;
    }

    button.ant-input-search-button {
      width: ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE};
    }
  }
`;

export const ActionBar = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    order: 3;
  }
`;

const StatusAndTableToggleView = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  span {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media (max-width: 768px) {
    order: 4;
    padding: 8px 0;
    flex-direction: column;
    & > span {
      // display: none;
      justify-content: flex-end;
    }
  }
`;

const StatContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
  gap: 10px;
  padding: 16px 0;
  @media (max-width: 768px) {
    // order: 1;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding: 16px 0;
  }
`;

const Stat = styled.div`
  // height: 250px;
  background-color: ${Colors.WHITE};
  border: 2px solid #eceef2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  flex-grow: 1;
  flex-basis: 0;
  span {
    display: flex;
    width: 100%;
    align-items: flex-start;
    svg {
      height: 24px;
      margin: 0 16px;
    }
    b {
      text-align: justify;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      text-transform: uppercase;
    }
  }
  div {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: baseline;
    p {
      // padding: 8px;
      margin: 0 2px;
      color: #bfbebe;
      text-transform: lowercase;
      font-size: 12px;
    }
  }
  @media (max-width: 768px) {
    flex-basis: 100%;
    div {
      p {
        // display: none;
      }
    }
  }
`;

const StatNumber = styled.b`
  font-size: 55px;
  font-weight: 500;
  margin: 0 8px;
  @media (max-width: 768px) {
    text-align: justify;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 105.263% */
    letter-spacing: -0.76px;
    margin-top: 24px;
  }
`;

const CustomSpan = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 0;
  justify-content: space-between;
  gap: 5px;
  button {
    max-height: 40px;
    height: 40px;
  }
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .ant-picker-range {
      background: transparent;
      width: 225px;
      border: 0;
      color: ${Colors.CONSIGNER_GREY};
    }
    .ant-picker-input > input {
      color: #9ca3af;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const CustomB = styled.b`
  font-weight: 500;
  font-size: inherit;
  padding: 0 4px;
  color: ${Colors.CONSIGNER_BLUE};
  &.underlined {
    text-decoration: underline;
  }
  &.green {
    color: #16a34a;
  }
  &.red {
    color: #ef4444;
  }
`;

const VATContainer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  padding: 0 5px;
  align-items: center;
  button {
    height: auto;
    max-height: auto;
  }
`;

const StatusSelectorDiv = styled.div`
  @media (max-width: 768px) {
    display: block;
    width: 100%;
    margin: auto;
    white-space: nowrap;
    overflow-x: scroll;
  }
`;

const StatusSelectorButton = styled(Button)`
  border: 0;
  &.ant-btn-ghost {
    color: ${Colors.CONSIGNER_GREY};
  }
`;

export const InventoryTableStyled = styled(Table)`
  width: 100%;
  padding: ${process.env.REACT_APP_TYPE === "consigner" ? "8px 16px" : "8px 0"};
  order: ${process.env.REACT_APP_TYPE === "consigner" ? "5" : "initial"};
  .ant-table {
    .ant-table-thead .ant-table-cell {
      background-color: #746bf8;
      text-transform: uppercase;
      color: #fff;
      padding: 10px 8px;
    }
    .ant-table-tbody .ant-table-cell {
      font-size: 13px;
    }
  }
`;

const CustomForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 8px;
    .ant-col {
      flex: 0 0 100%;
      text-align: left;
    }
  }
`;

interface StatusSelectorProps {
  filters: any;
  setFilters: any;
  inventoryFilterOptions: any;
}

export const sizeSorter = (a, b) => {
  // Extract numbers from alphanumeric values
  const sizeA = a.option1Value.match(/\d+/);
  const sizeB = b.option1Value.match(/\d+/);

  // If there are no numbers in the alphanumeric values, consider them equal
  if (!sizeA && !sizeB) return 0;

  // If one of the alphanumeric values has no numbers, it should be treated as greater than the other
  if (!sizeA) return 1;
  if (!sizeB) return -1;

  // Convert extracted numbers to integers and compare
  return parseInt(sizeA[0], 10) - parseInt(sizeB[0], 10);
};

const visibleColumns: ColumnTypes[number][] = [
  // {
  //   title: "Product Id",
  //   key: "id",
  //   dataIndex: "id",
  //   render: (_: any, record: any) => record.code,
  // },
  {
    title: "Product",
    key: "productImage",
    dataIndex: "product.image",
    render: (_: any, record: any) => (
      <Image
        src={record.product.image}
        alt=""
        style={{ width: 100, height: "auto" }}
      ></Image>
    ),
  },
  {
    title: "Name",
    key: "productName",
    dataIndex: "product.title",
    render: (_: any, record: any) => (
      <div style={{ display: "inline-block" }}>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>{record.product.title}</div>
          <div>{record.code}</div>
        </span>
      </div>
    ),
  },
  {
    title: "SKU",
    key: "sku",
    dataIndex: "product.sku",
    render: (_: any, record: any) => record.product.sku,
  },
  {
    title: "Location",
    key: "option3Value",
    dataIndex: "option3Value",
    render: (_: any, record: any) => record?.option3Value ?? null,
  },
  {
    title: "Size",
    key: "option1Value",
    dataIndex: "option1Value",
    render: (_: any, record: any) => record?.option1Value ?? null,
    sorter: sizeSorter,
    defaultSortOrder: "ascend",
  },
  {
    title: "Condition",
    key: "option2Value",
    dataIndex: "option2Value",
    render: (_: any, record: any) => record?.option2Value ?? null,
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (_: any, record: any) => (
      <CustomB className="underlined green">
        {record?.status === "Pending" ? "Drop off pending" : record.status}
      </CustomB>
    ),
  },
  {
    title: "Days Active",
    key: "acceptedOn",
    dataIndex: "acceptedOn",
    render: (_: any, record: any) => {
      const acceptedOnDate = moment(record?.acceptedOn ?? new Date());
      let current = moment();
      const daysSinceAccept = current.diff(acceptedOnDate, "days");
      return daysSinceAccept + " Days";
    },
  },
  {
    title: "Price",
    key: "price",
    dataIndex: "price",
    render: (_: any, record: any) =>
      `${getSymbolFromCurrency(record.currency)}${record.price}`,
  },
  {
    title: "Payout",
    key: "total",
    dataIndex: "total",
    render: (_: any, record: any) =>
      `${getSymbolFromCurrency(record.currency)}${Number(
        record.payoutAmount
      ).toFixed(2)}`,
  },
  {
    title: "Edit",
    dataIndex: "operation",
    render: (_: any, record: any) => (
      <Link target="_blank" href={`/inventories/${record.id}`}>
        <Button type="text" icon={<EditOutlined />}></Button>
      </Link>
    ),
  },
];

const StatusSelector = (props: StatusSelectorProps) => {
  const { filters, setFilters, inventoryFilterOptions } = props;
  const { statuses } = inventoryFilterOptions;
  const consignerStatuses = statuses.filter(
    (status) => status.value !== "PendingSale"
  );
  consignerStatuses.unshift({
    label: "All",
    value: "",
  });
  const [status, setStatus] = useState(filters.status);

  const handleSetStatus = (statValue: string) => {
    if (!status.includes(statValue)) {
      setStatus([...status, statValue]);
    } else {
      setStatus(status.filter((stat) => stat !== statValue));
    }
  };

  useEffect(() => {
    setFilters({ ...filters, status });
    console.log(status);
  }, [status]);

  return (
    <StatusSelectorDiv>
      {consignerStatuses.map((stat, key) => (
        <StatusSelectorButton
          key={key}
          type={
            (status.includes(stat.value) && stat.value !== "") ||
            (stat.label === "All" && status === "")
              ? "primary"
              : "ghost"
          }
          onClick={() => setStatus(stat.value)}
        >
          {stat.label === "Pending" ? "Drop off pending" : stat.label}
        </StatusSelectorButton>
      ))}
    </StatusSelectorDiv>
  );
};

interface Props {
  isMobile?: boolean;
}

const goToProductPage = (
  history: RouteComponentProps["history"],
  productInventory: ProductInventory
) => {
  history.push(`/products/${productInventory.product.id}`);
};

export const getProductInventories = (
  inventories: Inventory[]
): ProductInventory[] => {
  const products = inventories
    .filter((item) => item.product !== null)
    .map((item) => item.product);
  const uniqueProducts = products.filter(
    (v, i, a) => a.findIndex((t) => t.id === v.id) === i
  );

  return uniqueProducts.reduce(
    (allProducts: ProductInventory[], product: Product) => {
      const productInventories = inventories
        .filter((item) => item.product !== null)
        .filter((inventory) => inventory.product.id === product.id);
      return [...allProducts, { product, inventories: productInventories }];
    },
    []
  );
};

const ExpandedRowRender = (record, inventories) => {
  const expandedDataSrc = inventories.filter(
    (inventory) => inventory.productId === record.productId
  );
  return (
    <Table
      dataSource={expandedDataSrc}
      style={{ borderLeft: `4px solid ${Colors.CONSIGNER_BLUE}` }}
      columns={visibleColumns as ColumnTypes}
      rowKey="id"
      pagination={{
        pageSize: 10,
        // responsive: true,
        hideOnSinglePage: true,
      }}
      showHeader={false}
    />
  );
};

export const ConsignerStats = (props: any) => {
  const { consignerReport, store } = props;
  const { consignerAnalytics } = consignerReport;

  const formatStatistic = (number = 0): string => {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + "M";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + "K";
    } else {
      return number.toString();
    }
  };

  return (
    <StatContainer>
      <Stat>
        <span>
          <Tag />
          <b>Total Sales</b>
        </span>
        <div>
          <StatNumber>
            {getSymbolFromCurrency(store.currency)}
            {formatStatistic(consignerAnalytics?.totalSold)}
          </StatNumber>
          <p>
            (before fees)
            {/* vs last cycle
            <CustomB>
              {getSymbolFromCurrency(store.currency)}
              {formatStatistic(consignerAnalytics?.prevCycleTotalSold)}
            </CustomB> */}
          </p>
        </div>
      </Stat>
      <Stat>
        <span>
          <SneakerMove />
          <b>Total Payout</b>
        </span>
        <div>
          <StatNumber>
            {getSymbolFromCurrency(store.currency)}
            {formatStatistic(consignerAnalytics?.totalPaid)}
          </StatNumber>
          <p>
            {/* vs  last cycle
            <CustomB>
              {formatStatistic(consignerAnalytics?.prevCycleTotalPaid)}
            </CustomB> */}
          </p>
        </div>
      </Stat>
      <Stat>
        <span>
          <Money />
          <b>Pending Payout</b>
        </span>
        <div>
          <StatNumber>
            {getSymbolFromCurrency(store.currency)}
            {formatStatistic(consignerAnalytics?.totalPending)}
          </StatNumber>
          <p>(after fees)</p>
        </div>
      </Stat>
    </StatContainer>
  );
};

export const ConsignerBankDetailsModal = ({
  consigner,
  showDetailsModal,
  setShowDetailsModal,
  update = false,
}: {
  consigner: Consigner;
  showDetailsModal: boolean;
  setShowDetailsModal: any;
  update?: boolean;
}) => {
  const [consignerData, setConsignerData] = useState<Consigner>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setConsignerData(consigner);
  }, [consigner]);

  const saveConsigner = async () => {
    await dispatch(updateConsigner(consignerData.id, consignerData, false));
    dispatch(getConsigner(consignerData.id));
    setShowDetailsModal(false);
  };

  if (!consignerData) return <Spin />;

  return (
    <Modal
      visible={showDetailsModal}
      closable
      onCancel={() => setShowDetailsModal(false)}
      footer={
        update ? (
          <ButtonGroup>
            <Button
              onClick={saveConsigner}
              type="primary"
              icon={<UserOutlined />}
            >
              Save
            </Button>
          </ButtonGroup>
        ) : (
          <></>
        )
      }
      closeIcon={<></>}
    >
      <CustomForm>
        <Form.Item label="First Name">
          <Input
            readOnly={!update}
            value={consignerData.firstName}
            onChange={(e: any) =>
              setConsignerData({ ...consignerData, firstName: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="Last Name">
          <Input
            readOnly={!update}
            value={consignerData.lastName}
            onChange={(e: any) =>
              setConsignerData({ ...consignerData, lastName: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="Email">
          <Input
            readOnly={!update}
            value={consignerData.email}
            onChange={(e: any) =>
              setConsignerData({ ...consignerData, email: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="Phone">
          <Input
            readOnly={!update}
            value={consignerData.phone}
            onChange={(e: any) =>
              setConsignerData({ ...consignerData, phone: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="Address">
          <Input
            readOnly={!update}
            value={consignerData.address}
            onChange={(e: any) =>
              setConsignerData({ ...consignerData, address: e.target.value })
            }
          />
        </Form.Item>
        <hr />
        <Form.Item label="Bank">
          <Input
            readOnly={!update}
            value={consignerData.bank ?? consigner.bankData.bank}
            onChange={(e: any) =>
              setConsignerData({
                ...consignerData,
                bank: e.target.value,
                bankData: { ...consignerData.bankData, bank: e.target.value },
              })
            }
          />
        </Form.Item>
        <Form.Item label="Bank Type" hidden={consigner.storeId === 32}>
          <Input
            readOnly={!update}
            value={consignerData.bankType ?? consigner.bankData.bankType}
            onChange={(e: any) =>
              setConsignerData({
                ...consignerData,
                bankType: e.target.value,
                bankData: {
                  ...consignerData.bankData,
                  bankType: e.target.value,
                },
              })
            }
          />
        </Form.Item>
        <Form.Item label="Branch Code">
          <Input
            readOnly={!update}
            value={consignerData.branchCode}
            onChange={(e: any) =>
              setConsignerData({ ...consignerData, branchCode: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="Account Name">
          <Input
            readOnly={!update}
            value={consignerData.accountName ?? consigner.bankData.accountName}
            onChange={(e: any) =>
              setConsignerData({
                ...consignerData,
                accountName: e.target.value,
                bankData: {
                  ...consignerData.bankData,
                  accountName: e.target.value,
                },
              })
            }
          />
        </Form.Item>
        <Form.Item label="Account Number">
          <Input
            readOnly={!update}
            value={
              consignerData.accountNumber ?? consigner.bankData.accountNumber
            }
            onChange={(e: any) =>
              setConsignerData({
                ...consignerData,
                accountNumber: e.target.value,
                bankData: {
                  ...consignerData.bankData,
                  accountNumber: e.target.value,
                },
              })
            }
          />
        </Form.Item>
        <Form.Item label="Swift Code" hidden={consigner.storeId === 32}>
          <Input
            readOnly={!update}
            value={consignerData.bankData.swiftCode}
            onChange={(e: any) =>
              setConsignerData({
                ...consignerData,
                bankData: {
                  ...consignerData.bankData,
                  swiftCode: e.target.value,
                },
              })
            }
          />
        </Form.Item>
        <Form.Item label="IBAN Number" hidden={consigner.storeId === 32}>
          <Input
            readOnly={!update}
            value={consignerData.bankData.ibanNumber}
            onChange={(e: any) =>
              setConsignerData({
                ...consignerData,
                bankData: {
                  ...consignerData.bankData,
                  ibanNumber: e.target.value,
                },
              })
            }
          />
        </Form.Item>
        <Form.Item label="Routing Number" hidden={consigner.storeId === 32}>
          <Input
            readOnly={!update}
            value={consignerData.bankData.routingNumber}
            onChange={(e: any) =>
              setConsignerData({
                ...consignerData,
                bankData: {
                  ...consignerData.bankData,
                  routingNumber: e.target.value,
                },
              })
            }
          />
        </Form.Item>
      </CustomForm>
    </Modal>
  );
};

export type EditableTableProps = Parameters<typeof Table>[0];

export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export const InventoryTable = (props: any): JSX.Element => {
  const { inventoryList, inventoryLoading, nestedView, columns } = props;
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [groupedData, setGroupedData] = useState<any[]>([]);
  const [columnSrc, setColumnSrc] = useState<any[]>([]);
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const [pageSize, setPageSize] = useState(10);
  const [priceMap, setPriceMap] = useState<any[]>([]);
  const { handleShowImagesModal } = props;

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const groupColumns = [
    {
      title: "Product",
      dataIndex: "productImage",
      key: "product.image",
      render: (image) => (
        <Image
          src={image}
          alt="Product Photo"
          style={{ width: 100, height: "auto" }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "productTitle",
      key: "product.title",
    },
    {
      title: "SKU",
      dataIndex: "productSku",
      key: "product.sku",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
  ];

  useEffect(() => {
    console.log(inventoryList);
    if (!inventoryList) return;

    if (!nestedView) {
      setDataSource(
        inventoryList.map((inventory) => ({
          ...inventory,
          key: inventory.id,
          currency: store.currency,
        }))
      ); // assign key for table
      return;
    }
    const groupedData = inventoryList.reduce((groups, item) => {
      const groupId = `${item.productId}`;
      // const groupId = `${item.productId}${item.sku}`;
      if (!groups[groupId]) {
        groups[groupId] = {
          productId: item.productId,
          key: groupId,
          productTitle: item.product.title,
          productImage: item.product.image,
          productSku: item.product.sku,
          consigner: item.consigner,
          quantity: 0,
          inventories: [],
        };
      }
      groups[groupId].quantity++;
      groups[groupId].inventories.push(item);
      return groups;
    }, {});
    setDataSource(Object.values(groupedData));
    setGroupedData(Object.values(groupedData));
    // setColumnSrc(groupColumns);
  }, [inventoryList, nestedView]);

  return (
    <InventoryCardView
      nestedView={nestedView}
      groupedData={groupedData}
      inventories={dataSource}
      store={store}
      loading={inventoryLoading}
      columnSrc={visibleColumns}
      priceMap={priceMap}
      setPriceMap={setPriceMap}
      handleShowImagesModal={handleShowImagesModal}
    />
  );
};

interface RouteParams {
  id: string;
}

const ConsignerView = (props: Props): JSX.Element => {
  const { id }: RouteParams = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    paginatedInventories,
    inventoriesLoading,
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const {
    consigner,
    consignerLoading,
    updateConsignerLoading,
    consignerReport,
    consignerReportLoading,
  }: ConsignerState = useAppSelector((state) => state.ConsignerReducer);
  const { consignerTypes, consignerTypesLoading }: ConsignerTypeState =
    useAppSelector((state) => state.ConsignerTypeReducer);
  const { subLocations, subLocationsLoading, updatedSubLocationLoading } =
    useAppSelector((state) => state.SubLocationReducer);
  const { isMobile } = useAppSelector((state) => state.AppReducer);

  const [search, setSearch] = useState("");
  // const [password, setPassword] = useState("");
  // const [conpassword, setConPassword] = useState("");
  const [tableNestedView, setTableNestedView] = useState(false);
  // const [updating, setUpdating] = useState(false);
  // const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [consignerTypeValue, setConsignerTypeValue] = useState("");
  const [dateRange, setDateRange] = useState<Array<any>>([
    moment().subtract(7, "days").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);
  const [updateAccountTypeModal, setUpdateAccountTypeModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [targetImageInventory, setTargetImageInventory] = useState<Inventory>();
  const auth = AuthService();

  const [filters, setFilters] = useState<any>({
    printed: "",
    status: "Active",
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: undefined,
    sortOption: "newestUpdated",
    consigner: id,
  });

  const handleShowImagesModal = async (inventory) => {
    setTargetImageInventory(inventory);
    setShowImagesModal(true);
  };

  useEffect(() => {
    dispatch(getConsigner(id));
    dispatch(getInventoryFilterOptions());
    dispatch(getSubLocations());
    dispatch(getConsignerTypes());
  }, []);

  useEffect(() => {
    if (!consigner) return;
    setConsignerTypeValue(consigner.accountType);
  }, [consigner]);

  useEffect(() => {
    //fetch all reports data here
    dispatch(getConsignerReport(filters));
  }, [filters]);

  useEffect(() => {
    //refresh inventory list on filter or search change
    dispatch(
      getPaginatedInventories(
        search,
        filters,
        currentPage,
        pageSize,
        tableNestedView
      )
    );
  }, [search, filters, tableNestedView, currentPage, pageSize]);

  const updatePassword = async () => {
    auth.resetPassword(consigner.email);
    // await auth.updateUserPassword(dispatch, consigner.email, password);
  };

  const onAccountTypeSave = async () => {
    message.info(
      "Confirmed Action: These changes take time, you will see the changes slowly update..."
    );
    setUpdateAccountTypeModal(false);
    await dispatch(
      updateConsigner(
        consigner.id,
        { ...consigner, accountType: consignerTypeValue },
        true
      )
    );
    await dispatch(getConsigner(consigner.id));
  };

  /**
   * Export inventories to csv
   * Maps through Inventories and creates a csv file
   * @returns {void}
   */
  const exportInventoryToCsv = () => {
    const columnTitles = [
      "Inventory ID",
      // "Product Image",
      "Product Title",
      "SKU",
      "Option1Value",
      "Option2Value",
      "Option3Value",
      "Status",
      "Date",
      "Price",
      "Total Payout",
    ];

    paginatedInventories.rows.sort((a, b) => a.productId - b.productId);
    const csvData = paginatedInventories.rows.map((inventory) => [
      `"${inventory.code}"`,
      // `"${inventory.product.image}"`,
      `"${inventory.product.title}"`,
      `"${inventory.product.sku}"`,
      `"${inventory.option1Value}"`,
      `"${inventory.option2Value}"`,
      inventory.option3Value && typeof inventory.option3Value !== "undefined"
        ? `"${inventory.option3Value}"`
        : "",
      `"${inventory.status}"`,
      `"${inventory.acceptedOn}"`,
      `"${inventory.price}"`,
      `"${inventory.payoutAmount}"`,
    ]);

    csvData.unshift(columnTitles);
    const csvContent =
      // "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "inventory.csv");

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    message.success("Inventory exported successfully", 3);
  };

  if (
    !inventoryFilterOptions ||
    inventoryFilterOptionsLoading ||
    consignerLoading
  )
    return (
      <PageContainer className="ConsignerInventoryPage">
        <Spin />
      </PageContainer>
    );

  return (
    <PageContainer className="ConsignerInventoryPage">
      {targetImageInventory && (
        <Modal
          title="Product Images"
          visible={showImagesModal}
          closable
          onCancel={() => setShowImagesModal(false)}
          footer={null}
        >
          <Row>
            {targetImageInventory.product?.image && (
              <Col>
                <Image src={targetImageInventory.product.image} alt="" />
              </Col>
            )}
            {targetImageInventory.inventoryExtras[0]?.frontImage && (
              <Col>
                <Image
                  src={targetImageInventory.inventoryExtras[0]?.frontImage}
                  alt=""
                />
              </Col>
            )}
            {targetImageInventory.inventoryExtras[0]?.brandLogo && (
              <Col>
                <Image
                  src={targetImageInventory.inventoryExtras[0]?.brandLogo}
                  alt=""
                />
              </Col>
            )}
            {targetImageInventory.inventoryExtras[0]?.manufacturingTag && (
              <Col>
                <Image
                  src={
                    targetImageInventory.inventoryExtras[0]?.manufacturingTag
                  }
                  alt=""
                />
              </Col>
            )}
            {targetImageInventory.inventoryExtras[0]?.insideLabelTwo && (
              <Col>
                <Image
                  src={targetImageInventory.inventoryExtras[0]?.insideLabelTwo}
                  alt=""
                />
              </Col>
            )}
            {targetImageInventory.inventoryExtras[0]?.insideLabelThree && (
              <Col>
                <Image
                  src={
                    targetImageInventory.inventoryExtras[0]?.insideLabelThree
                  }
                  alt=""
                />
              </Col>
            )}
            {targetImageInventory.inventoryExtras[0]?.serialCode && (
              <Col>
                <Image
                  src={targetImageInventory.inventoryExtras[0]?.serialCode}
                  alt=""
                />
              </Col>
            )}
            {targetImageInventory.inventoryExtras[0]?.zipperFront && (
              <Col>
                <Image
                  src={targetImageInventory.inventoryExtras[0]?.zipperFront}
                  alt=""
                />
              </Col>
            )}
            {targetImageInventory.inventoryExtras[0]?.zipperBack && (
              <Col>
                <Image
                  src={targetImageInventory.inventoryExtras[0]?.zipperBack}
                  alt=""
                />
              </Col>
            )}
          </Row>
        </Modal>
      )}
      <Modal
        title="Confirm consigner account type change"
        visible={updateAccountTypeModal}
        onCancel={() => setUpdateAccountTypeModal(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => onAccountTypeSave()}
          >
            Confirm
          </Button>,
        ]}
      >
        <Select
          loading={consignerTypesLoading}
          value={consignerTypeValue}
          onChange={(value) => setConsignerTypeValue(value)}
        >
          {consignerTypes.map((consignerType, index) => (
            <Option key={index} value={consignerType.accountType}>
              {consignerType.accountType}
            </Option>
          ))}
        </Select>
      </Modal>
      <ConsignerBankDetailsModal
        consigner={consigner}
        setShowDetailsModal={setShowDetailsModal}
        showDetailsModal={showDetailsModal}
        update={true}
      />
      <CustomSpan>
        <span>
          <b>
            {consigner?.firstName} {consigner?.lastName}
            <a onClick={() => setUpdateAccountTypeModal(true)}>
              {" "}
              (
              {consigner?.consignerTypeId
                ? consignerTypes?.find(
                    (ctype) => ctype.id === consigner?.consignerTypeId
                  )?.accountType
                : consigner?.accountType}
              )
            </a>
          </b>
        </span>
        <span>
          <VATContainer>
            <label>VAT</label>
            <Switch
              checked={consigner?.chargeTax}
              onChange={async (e) => {
                await dispatch(
                  updateConsigner(
                    consigner.id,
                    { ...consigner, chargeTax: e },
                    false
                  )
                );
                dispatch(getConsigner(consigner.id));
              }}
            />
          </VATContainer>
          <Button
            type="primary"
            ghost
            shape="round"
            onClick={() => setShowDetailsModal(true)}
          >
            Details
          </Button>
          <Button type="primary" onClick={() => updatePassword()}>
            Reset Password
          </Button>
          <OrderFilter
            onFilter={(values) => setFilters({ ...filters, ...values })}
            formOptions={inventoryFilterOptions}
            showConsigner={false}
          />
        </span>
      </CustomSpan>
      <ConsignerStats consignerReport={consignerReport} store={store} />

      <CustomSpan style={{ justifyContent: "space-between", gap: 0 }}>
        <span style={{ flex: 2, alignItems: "center" }}>
          <SrchBx
            value={search}
            onChange={(e) => setSearch(e)}
            placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
          />
        </span>
        <CustomInventoryFilterContainer>
          <InventoryFilter
            onFilter={(values) => setFilters(values)}
            formOptions={inventoryFilterOptions}
            showConsignerFilter={false}
            showStatusFilter
            currentFilters={filters}
            subLocations={subLocations}
            option1Values={inventoryFilterOptions.option1Values}
            option2Values={inventoryFilterOptions.option2Values}
            option3Values={inventoryFilterOptions.option3Values}
          />
        </CustomInventoryFilterContainer>
        <CustomInventoryFilterContainer
          style={{ flex: "0 0 auto", marginLeft: 16, gap: 8 }}
        >
          <Button
            icon={<UnorderedListOutlined />}
            type={tableNestedView ? "default" : "primary"}
            onClick={() => setTableNestedView(false)}
          ></Button>
          <Button
            icon={<GroupOutlined />}
            type={tableNestedView ? "primary" : "default"}
            onClick={() => setTableNestedView(true)}
          ></Button>
        </CustomInventoryFilterContainer>
      </CustomSpan>
      <CustomSpan style={{ justifyContent: "flex-end" }}>
        <StyledButton
          type="default"
          style={{ width: "auto", flex: 0, padding: 0, paddingLeft: 8 }}
        >
          Show
          <Select
            style={{ flex: 0 }}
            bordered={false}
            value={pageSize}
            onChange={(size) => {
              setPageSize(size);
              // dispatch(
              //   getPaginatedInventories(search, filters, currentPage, size)
              // );
            }}
            placeholder="Show"
          >
            {[10, 20, 50, 100, 250, 500].map((sz) => (
              <Option key={sz} value={sz}>
                {sz}
              </Option>
            ))}
          </Select>
        </StyledButton>
        <StyledButton
          type="ghost"
          onClick={() => dispatch(exportInventory(search, filters))}
        >
          {Content.FIND_INVENTORY_ACTION_BUTTON_TWO}
        </StyledButton>
      </CustomSpan>
      <InventoryTable
        inventoryList={paginatedInventories.rows}
        inventoryLoading={inventoriesLoading}
        nestedView={tableNestedView}
        columns={visibleColumns}
        handleShowImagesModal={handleShowImagesModal}
      />
      <Pagination
        current={currentPage}
        total={paginatedInventories.count}
        pageSize={pageSize}
        onChange={(page, pageSize) => {
          // setTableNestedView(false);
          setCurrentPage(page);
          setPageSize(pageSize);
          // dispatch(getPaginatedInventories(search, filters, page, pageSize));
        }}
        pageSizeOptions={[10, 20, 50, 100, 250, 500]}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        showQuickJumper={false} // allows user to type in page number
        showSizeChanger={false} // allows user to change number of items per page
        style={{
          paddingTop: 50,
          paddingBottom: 28,
        }}
      />
    </PageContainer>
  );
};

export default ConsignerView;
