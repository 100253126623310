// ProductGrid.tsx
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Steps,
  Button,
  Input,
  Modal,
  Select,
  Form,
  Spin,
  Image,
} from "antd";
import styled from "styled-components";
import ProductCard from "./ProductCard"; // Make sure to create a TypeScript version of this component
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getStoreBuyandSellInfoFromAPI } from "../redux/actions/productActions";
import RedesignStyling from "../constants/RedesignStyling";
import { Product } from "../redux/reducers/ProductReducer";
import InventoryBuyAndSellModal from "./InventoryBuyAndSelllModal";
import {
  Inventory,
  InventoryFilterOptions,
} from "../redux/reducers/InventoryReducer";
import { createBuyOrderFromApi } from "../redux/actions/inventoryActions";
import { ButtonGroup } from "@mui/material";
import { ConsignerState } from "../redux/reducers/ConsignerReducer";
import { ProductTemplate } from "../redux/reducers/ProductTemplateReducer";
import { SuccessSplash } from "./SuccessSplash";
import { Splash } from "./Splash";
import Cookies from "js-cookie";
import { AppState } from "../redux/reducers/AppReducer";
import { StepTwo } from "./StepTwo";
import { Footer } from "antd/lib/layout/layout";
import getSymbolFromCurrency from "currency-symbol-map";
import { CloseOutlined } from "@ant-design/icons";

const { Step } = Steps;

const Navbar = styled.nav`
  display: flex;
  border-bottom: 1px solid var(--Line, #ddd);
  background: #f9f9f9;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 60px;
  .ant-image {
    max-height: 65px;
    max-width: 130px;
    img {
      object-fit: cover;
      max-height: 65px;
    }
  }
  span {
    display: flex;
    gap: 8px;
  }
  @media (max-width: 768px) {
    padding: 5px 20px;
  }
`;

const NavbarInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  height: 64px;
`;

const Logo = styled.h1`
  margin: 0;
  font-size: 24px;
  color: #1890ff;
`;

const SignInButton = styled.button`
  background: none;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #40a9ff;
  }
`;

const StepsContainer = styled.div`
  margin-top: 20px;
  padding: 0 20px;
`;

export const GridContainer = styled.div`
  width: 100%; // Adjust to match the design width
  max-width: 100%;
  margin: 0 auto;
  padding: 20px; // Adjust padding to match design
  position: relative;
  h1 {
    color: #2e2e2e;
    margin: 12px 0 16px;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px; // Space between rows
`;

const StyledCol = styled(Col)`
  padding: 0 15px; // Space between columns
`;

// Adjust the styled components below to match the specific styles of the UI elements in your design
const StyledSearchInput = styled(Input.Search)`
  width: 100%;
  // padding: 10px;
  margin-bottom: 20px; // Space below the search bar
  box-sizing: border-box;
  border: 1px solid #d9d9d9; // Example border style
  border-radius: 4px; // Example border radius
  align-self: stretch;
  height: 100%;
  span {
    display: flex;
    height: 100%;
    flex: 1;
    position: relative;
    input {
      text-indent: 50px;
      font-size: 16px;
    }
    .ant-input-group-addon {
      z-index: 3;
      background: transparent;
      position: absolute;
      // right: 0;
      top: 0;
      left: 0 !important;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      button {
        border: none;
      }
    }
  }
`;

export const StyledNextButton = styled(Button)`
  position: fixed; // Fixes the position relative to the viewport
  left: 50%; // Aligns the button horizontally center
  bottom: 20px; // Distance from the bottom of the viewport
  transform: translateX(-50%); // Centers the button with respect to its width
  color: white;
  border: none;
  transition: background-color 0.3s;
  font-size: 18px; // Adjust font size to match your design
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); // Example shadow, adjust as needed
  border-radius: 8px;
  background: #292d32;
  display: flex;
  width: 375px;
  height: 68px;
  padding: 11.5px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  &:hover {
    background-color: #40a9ff; // Adjust hover state color to match your design
  }
`;

const CustomButton = styled(Button)`
  display: inline-flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #292d32;
  background: #fbfcff;
  flex: 0 0 150px;
  align-self: stretch;
  height: 100%;
`;

const Container = styled.section`
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
  display: flex;
  flex-direction: column;
`;

const CustomSteps = styled(Steps)`
  @media (max-width: 768px) {
    .ant-steps-item-title {
      display: none;
    }
    &:not(.ant-steps-label-vertical) .ant-steps-item-tail {
      display: flex;
      left: 50px;
    }
  }
`;

const ConsignerForm = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    display: flex;
    flex-direction: column;
    margin: 5px 0 30px;
  }

  .ant-form {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    span {
      width: 700px;
      display: flex;
      flex-wrap: nowrap;
      gap: 16px;
      .ant-form-item {
        flex: 1;
        display: inline;
        .ant-row {
          display: block;
        }
        .ant-input {
          height: 47px;
          padding: 15px 17px;
          align-items: center;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid var(--Line, #ddd);
          background: var(--White, #fff);
          font-size: 16px;
        }
        .ant-form-item-label {
          display: flex;
          align-self: flex-start;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 21px */
          label {
            color: var(--Text---Grey, #989898);
          }
        }
      }
    }
  }
`;

export const CustomFooter = styled(Footer)`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  flex-direction: column;
  padding: 24px 12px;
  background: #fff;
  span {
    display: flex;
    div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 28px 0;
      gap: 8px;
      .ant-btn {
        min-height: 40px;
        padding: 0;
      }
      b {
        color: var(--Black, #000);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 130% */
        text-transform: capitalize;
      }
      label {
        color: var(--Body-Text, #7f8080);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 185.714% */
        text-transform: capitalize;
      }
    }
    .ant-btn {
      flex: 1;
      display: flex;
      height: auto;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }
  }
  @media (max-width: 768px) {
    display: flex;
  }
`;

const ProductGrid: React.FC = () => {
  const [search, setSearch] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [product, setProduct] = useState<Product>();
  const [newInventory, setNewInventory] = useState<Inventory[]>([]);
  const [inventoriesToAdd, setInventoriesToAdd] = useState<Inventory[]>([]);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [methodDelivery, setMethodDelivery] = useState<1 | 2>(1);
  const [candidatePrice, setCandidatePrice] = useState(0);
  const [candidateQty, setCandidateQty] = useState(0);
  const [location, setLocation] = useState("");
  const [products, setProducts] = useState<Product[]>([]);
  const [inventoryFilterOptions, setInventoryFilterOptions] =
    useState<InventoryFilterOptions>();
  const { storeLoading, store } = useAppSelector((state) => state.StoreReducer);
  const [productTemplates, setProductTemplates] = useState<ProductTemplate[]>();
  const [dataLoading, setDataLoading] = useState(false);
  const [dataSubmitting, setDataSubmitting] = useState(false);
  const [indexItemToEdit, setIndexItemToEdit] = useState(-1);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [splashSeen, setSplashSeen] = useState(false);
  const [consignerData, setConsignerData] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    zipCode: string;
  }>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    zipCode: "",
  });
  const dispatch = useAppDispatch();

  const { updatedProduct } = useAppSelector((state) => state.ProductReducer);
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);

  const { createConsignerLoading, createdConsigner }: ConsignerState =
    useAppSelector((state) => state.ConsignerReducer);

  const searchProducts = (value: string) => {
    setSearch(value);
  };

  const initializeData = async () => {
    setDataLoading(true);
    const data = await getStoreBuyandSellInfoFromAPI(search, store.handle);
    setProducts(data.products);
    setInventoryFilterOptions(data.inventoryFilterOptions);
    setProductTemplates(data.productTemplates);
    setDataLoading(false);
  };

  useEffect(() => {
    // dispatch(getInventoryFilterOptions());
    // initializeData();
    if (store && store.handle) initializeData();
  }, [search, store]);

  useEffect(() => {
    if (
      newInventory.length > 0 &&
      newInventory.every((inventory) => inventory.productId)
    ) {
      setIsModalVisible(false);
      setCurrentStep(1);
      setInventoriesToAdd((prev) => [...prev, ...newInventory]);
      setNewInventory([]);
    }
  }, [newInventory]);

  const handleSelectProduct = (product: Product) => {
    setProduct(product);
    setNewInventory([product]);
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (
      candidatePrice > 0 ||
      candidatePrice === inventoriesToAdd?.[indexItemToEdit]?.price
    ) {
      handleSetPrice();
    }
  }, [candidatePrice]);

  useEffect(() => {
    if (
      candidateQty > 0 ||
      candidateQty === inventoriesToAdd?.[indexItemToEdit]?.quantity
    ) {
      handleSetQty();
    }
  }, [candidateQty]);

  useEffect(() => {
    if (indexItemToEdit < 0) return;
    try {
      const { price, quantity } = inventoriesToAdd?.[indexItemToEdit];
      setCandidatePrice(price);
      setCandidateQty(quantity);
    } catch (e) {
      // console.warn(indexItemToEdit);
    }
  }, [indexItemToEdit]);

  useEffect(() => {
    if (Cookies.get("seenBuySellSplash") === "1") {
      setSplashSeen(true);
    }
  }, []);

  const handleSetPrice = () => {
    setInventoriesToAdd([
      ...inventoriesToAdd.map((inventory, idx: number) => {
        if (idx === indexItemToEdit)
          return {
            ...inventory,
            price: Number(candidatePrice),
            payoutAmount: Number(candidatePrice),
            payout: Number(candidatePrice),
          };
        else return inventory;
      }),
    ]);
  };

  const handleSetQty = () => {
    setInventoriesToAdd([
      ...inventoriesToAdd.map((inventory, idx: number) => {
        if (idx === indexItemToEdit)
          return { ...inventory, quantity: Number(candidateQty) };
        else return inventory;
      }),
    ]);
  };

  const handleCompleteProcess = async () => {
    setDataSubmitting(true);
    const createBuyOrderFromApiData = {
      newConsigner: {
        ...consignerData,
        address: "",
        idNumber: "",
        accountName: "",
        bank: undefined,
        accountNumber: "",
        branchCode: "",
        bankType: undefined,
        bankData: {
          accountName: "",
          accountNumber: "",
          swiftCode: "",
          ibanNumber: "",
          routingNumber: "",
          bank: "",
          bankType: "",
          email: consignerData.email,
          routingType: "",
        },
        fbUserId: "",
        accountType: "",
        payoutData: "",
        payoutType: "",
        storeId: store.id,
      },
      inventoryFormValues: inventoriesToAdd.map((inventory) => ({
        ...inventory,
        location,
        option3Value: inventory.option3Value === "" ? location : undefined,
      })),
      store,
      inventoryExtra: undefined,
    };
    await createBuyOrderFromApi(createBuyOrderFromApiData);
    setInventoriesToAdd([]);
    setDataSubmitting(false);
    setShowSuccessModal(true);
  };

  if (!splashSeen)
    return (
      <main>
        <Navbar style={{ padding: "5px 20px" }}>
          {/* <Image src={store.logo} />
          <Button type="link" href={`//${store.consignerUrl}`}>
            Sign In
          </Button> */}
          <b>How Trace Supply Works</b>
          <Button
            type="link"
            icon={<CloseOutlined />}
            onClick={() => setSplashSeen(true)}
          />
        </Navbar>
        <Splash visible={splashSeen} setVisible={setSplashSeen} />
      </main>
    );

  if (showSuccessModal)
    return (
      <SuccessSplash
        visible={showSuccessModal}
        setVisible={setShowSuccessModal}
        setStep={setCurrentStep}
      />
    );

  return (
    <main>
      <Navbar>
        <Image src={store.logo} />
        <span>
          <Button type="link" href={`//${store.consignerUrl}`}>
            Sign In
          </Button>
          <Button
            type="primary"
            href={`//${store.redirectURL}/catalogue/${store.handle}`}
          >
            VIEW QUICK SELL ITEMS
          </Button>
        </span>
      </Navbar>
      <Container>
        <Modal
          visible={showLocationModal}
          onCancel={() => setShowLocationModal(false)}
          closable
          footer={null}
        >
          <Select
            allowClear
            placeholder="Select location"
            showSearch
            style={{ width: 200 }}
            value={location}
            onChange={(value: string) => setLocation(value)}
          >
            {inventoryFilterOptions?.locations?.map((location: any) => (
              <Select.Option key={location.label} value={location.label}>
                {location.label}
              </Select.Option>
            ))}
          </Select>
        </Modal>
        {/* <Modal
          visible={dataSubmitting}
          closable={false}
          width={"100%"}
          footer={null}
        >
          <Spin size="large" />
        </Modal> */}
        {/* <Navbar>
          <NavbarInnerContainer>
            <Logo>Hampton</Logo>
            <SignInButton>Sign in</SignInButton>
          </NavbarInnerContainer>
        </Navbar> */}
        <StepsContainer>
          <CustomSteps
            responsive={false}
            direction="horizontal"
            current={currentStep}
            onChange={setCurrentStep}
          >
            <Step title="Select Your Products" />
            <Step title="Confirm Details" />
            <Step title="Complete" />
          </CustomSteps>
        </StepsContainer>
        {currentStep === 0 && (
          <GridContainer
            style={{
              paddingBottom: isMobile && inventoriesToAdd.length > 0 ? 200 : 0,
            }}
          >
            <h1>Select Your Products</h1>
            <span
              style={{ display: "flex", gap: 20, height: 50, marginBottom: 15 }}
            >
              <StyledSearchInput
                onSearch={searchProducts}
                placeholder="Search Products"
              />
              {/* {!isMobile && (
                <CustomButton type="default">Custom Product</CustomButton>
              )} */}
            </span>
            <StyledRow gutter={[8, 8]}>
              {dataLoading ? (
                <Spin size="large" />
              ) : (
                products.map((product) => (
                  <StyledCol xs={12} sm={12} md={8} lg={6} key={product.id}>
                    <ProductCard
                      name={product.title}
                      image={product.image}
                      brand={product.brand}
                      onClick={() => handleSelectProduct(product)}
                    />
                  </StyledCol>
                ))
              )}
            </StyledRow>
            {/* <StyledNextButton onClick={() => setCurrentStep((prev) => prev+1)}>Next</StyledNextButton> */}
            {inventoriesToAdd.length > 0 && (
              <CustomFooter>
                <span>
                  <div>
                    <label>Items</label>
                    <b>{inventoriesToAdd?.length}</b>
                  </div>
                  <div>
                    <label>Cost</label>
                    <b>
                      {getSymbolFromCurrency(store.currency)}
                      {inventoriesToAdd?.length * store.payoutFee}
                    </b>
                  </div>
                  <div>
                    <label>Value</label>
                    <b>
                      {getSymbolFromCurrency(store.currency)}
                      {inventoriesToAdd?.reduce(
                        (sum, inventory) => sum + Number(inventory.price),
                        0
                      )}
                    </b>
                  </div>
                </span>
                <span>
                  <Button type="primary" onClick={() => setCurrentStep(1)}>
                    Review Items
                  </Button>
                </span>
              </CustomFooter>
            )}
          </GridContainer>
        )}
        {currentStep === 1 && (
          <StepTwo
            inventoriesToAdd={inventoriesToAdd}
            indexItemToEdit={indexItemToEdit}
            setIndexItemToEdit={setIndexItemToEdit}
            store={store}
            setInventoriesToAdd={setInventoriesToAdd}
            newInventory={newInventory}
            setNewInventory={setNewInventory}
            candidateQty={candidateQty}
            candidatePrice={candidatePrice}
            setCandidateQty={setCandidateQty}
            setCandidatePrice={setCandidatePrice}
            setCurrentStep={setCurrentStep}
            methodDelivery={methodDelivery}
            setMethodDelivery={setMethodDelivery}
            location={location}
            setShowLocationModal={setShowLocationModal}
            dataLoading={dataLoading}
            productTemplates={productTemplates}
            consignerData={consignerData}
            setConsignerData={setConsignerData}
            currentStep={currentStep}
            handleCompleteProcess={handleCompleteProcess}
            dataSubmitting={dataSubmitting}
          />
        )}
        {currentStep === 2 && (
          <div>
            {isMobile ? (
              <StepTwo
                inventoriesToAdd={inventoriesToAdd}
                indexItemToEdit={indexItemToEdit}
                setIndexItemToEdit={setIndexItemToEdit}
                store={store}
                setInventoriesToAdd={setInventoriesToAdd}
                newInventory={newInventory}
                setNewInventory={setNewInventory}
                candidateQty={candidateQty}
                candidatePrice={candidatePrice}
                setCandidateQty={setCandidateQty}
                setCandidatePrice={setCandidatePrice}
                setCurrentStep={setCurrentStep}
                methodDelivery={methodDelivery}
                setMethodDelivery={setMethodDelivery}
                location={location}
                setShowLocationModal={setShowLocationModal}
                dataLoading={dataLoading}
                productTemplates={productTemplates}
                consignerData={consignerData}
                setConsignerData={setConsignerData}
                currentStep={currentStep}
                handleCompleteProcess={handleCompleteProcess}
                dataSubmitting={dataSubmitting}
              />
            ) : (
              <GridContainer style={{ margin: 0, width: "initial" }}>
                <h1>Get Paid</h1>
                <ConsignerForm>
                  <span>
                    <b>Contact</b>
                    <label>Fill Your Information</label>
                  </span>
                  <Form>
                    <span>
                      <Form.Item label="First Name">
                        <Input
                          required
                          value={consignerData.firstName}
                          onChange={(value) =>
                            setConsignerData({
                              ...consignerData,
                              firstName: value.target.value,
                            })
                          }
                        />
                      </Form.Item>
                      <Form.Item label="Last Name">
                        <Input
                          required
                          value={consignerData.lastName}
                          onChange={(value) =>
                            setConsignerData({
                              ...consignerData,
                              lastName: value.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </span>
                    <span>
                      <Form.Item label="Email">
                        <Input
                          required
                          value={consignerData.email}
                          onChange={(value) =>
                            setConsignerData({
                              ...consignerData,
                              email: value.target.value,
                            })
                          }
                        />
                      </Form.Item>
                      <Form.Item label="Phone Number">
                        <Input
                          required
                          value={consignerData.phone}
                          onChange={(value) =>
                            setConsignerData({
                              ...consignerData,
                              phone: value.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </span>
                    <span style={{ width: 342 }}>
                      <Form.Item label="Zip Code">
                        <Input
                          required
                          value={consignerData.zipCode}
                          onChange={(value) =>
                            setConsignerData({
                              ...consignerData,
                              zipCode: value.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </span>
                  </Form>
                </ConsignerForm>
                <ButtonGroup
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 12,
                    marginTop: 190,
                  }}
                >
                  <Button
                    style={{
                      alignSelf: "stretch",
                      height: 68,
                      width: 203,
                      maxWidth: "50%",
                      flex: "0 0 auto",
                      borderRadius: 8,
                      background: "var(--Grey---Button-Fill, #F4F4F4)",
                    }}
                    type="default"
                    onClick={() => setCurrentStep((prev) => prev - 1)}
                  >
                    Back
                  </Button>
                  <StyledNextButton
                    style={{
                      position: "relative",
                      left: "initial",
                      bottom: "initial",
                      transform: "none",
                      maxWidth: "50%",
                      flex: "0 0 auto",
                    }}
                    disabled={
                      consignerData.email === "" ||
                      consignerData.phone === "" ||
                      consignerData.zipCode === "" ||
                      consignerData.firstName === "" ||
                      consignerData.lastName === "" ||
                      dataSubmitting
                    }
                    onClick={handleCompleteProcess}
                  >
                    Confirm
                  </StyledNextButton>
                </ButtonGroup>
              </GridContainer>
            )}
          </div>
        )}
        {product && (
          <InventoryBuyAndSellModal
            product={product}
            id={product.id}
            productTemplates={productTemplates}
            updatedProduct={updatedProduct}
            dispatch={dispatch}
            setIsModalVisible={setIsModalVisible}
            isModalVisible={isModalVisible}
            isNewEntry={newInventory.length > 0}
            setNewInventory={setNewInventory}
          />
        )}
      </Container>
    </main>
  );
};

export default ProductGrid;
