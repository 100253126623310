import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CustomFooter, GridContainer, StyledNextButton } from "./Lux";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  message,
  Checkbox,
} from "antd";
import getSymbolFromCurrency from "currency-symbol-map";
import { CloseCircleOutlined } from "@ant-design/icons";
import { ButtonGroup } from "@mui/material";
import PushPinOutlined from "../assets/images/pin.png";
import ShopOutlined from "../assets/images/Store.png";
import { Inventory } from "../redux/reducers/InventoryReducer";
import { Store } from "../redux/reducers/StoreReducer";
import { useAppSelector } from "../redux/hooks";
import { AppState } from "../redux/reducers/AppReducer";
import {
  ProductTemplate,
  ProductTemplateState,
} from "../redux/reducers/ProductTemplateReducer";
import ImageUpload from "../components/Common/ImageUpload";
import { Product } from "../redux/reducers/ProductReducer";
import { updateProductFn } from "../redux/actions/productActions";
import _ from "lodash";

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  flex-direction: column;
  min-height: calc(100vh - 85px);
  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 0;
    div.MuiButtonGroup-root {
      display: flex;
      gap: 16px;
      .ant-btn {
        display: flex;
        height: 68px;
        padding: 11.5px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
    }
    .ant-card {
      border-radius: 0;
      //   background: #fbfcff;
      margin-bottom: 13px;
      &.selected {
        outline: 1px solid #40a9ff;
      }
    }
    .ant-form {
      span {
        label {
          display: flex;
          width: 100%;
          color: #989898;
          align-items: center;
          flex-shrink: 0;
          margin-bottom: 8px;
        }
        .ant-input {
          display: flex;
          width: 100%;
          height: 47px;
          padding: 15px 17px;
          align-items: center;
          flex-shrink: 0;
          margin-bottom: 13px;
          font-size: 16px;
        }
      }
    }
  }
`;
export const TitleLabel = styled.span`
  display: flex;
  flex-direction: column;
  label {
    color: #989898;
    line-height: 15px;
    margin: 6px 0 24px;
  }
  b {
    line-height: 18px;
    font-weight: 600;
  }
  @media (max-width: 768px) {
    margin-top: 6px;
    label {
      margin: 6px 0;
    }
  }
`;

export const PriceInput = styled.div`
  margin: 24px 0;
  display: inline-flex;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #fff;
  gap: 5px;
  & > div {
    flex: 1;
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);
    background: var(--White, #fff);
    display: flex;
    align-items: center;
    span {
      padding: 1 11px;
    }
  }
  & > span {
    flex: 1;
  }
  .ant-btn {
    flex: 0 0 80px;
    align-self: stretch;
    height: 100%;
  }
  .ant-input,
  .ant-input-number,
  .ant-input-prefix,
  .ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector,
    .ant-select-selection-item {
      input[type="search"] {
        height: 60px;
      }
      line-height: 60px;
      height: 60px;
    }
    height: 60px;
    color: var(--Light---Black, #292d32);
    text-align: center;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    b {
      flex: 1;
    }
  }
  .ant-input {
    text-align: left;
  }
  .ant-input-number {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    input {
      text-align: center;
    }
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-prefix {
    text-align: right;
  }
`;

export const ConditionSelector = styled(Radio.Group)`
  flex: 1;
  margin-bottom: 24px; /* Space between categories and size selector */
  gap: 14px;
  display: flex;
  flex-wrap: wrap;

  .ant-radio-button-wrapper {
    margin-right: 8px; /* Spacing between buttons */
    border: 1px solid var(--Line, #ddd);
    height: 70px;
    flex-shrink: 0;
    min-width: 145px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:not(:first-child)::before {
      display: none;
    }

    /* Custom colors based on your design */
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);

    /* Active and hover styles */
    &.ant-radio-button-wrapper-checked,
    &:hover {
      border-radius: 8px;
      border: 2px solid #67abf4;
      background: #fbfcff;
    }
  }

  @media (max-width: 768px) and (orientation: portrait) {
    .ant-radio-button-wrapper {
      min-width: 100px;
    }
  }
`;

export const AccessorySelector = styled.div`
  flex: 1;
  margin-bottom: 24px; /* Space between categories and size selector */
  gap: 14px;
  display: flex;
  flex-wrap: wrap;

  .ant-btn {
    margin-right: 8px; /* Spacing between buttons */
    border: 1px solid var(--Line, #ddd);
    height: 70px;
    flex-shrink: 0;
    min-width: 145px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &:not(:first-child)::before {
      display: none;
    }

    /* Custom colors based on your design */
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);

    /* Active and hover styles */
    &.checked,
    &:hover {
      border-radius: 8px;
      border: 2px solid #67abf4;
      background: #fbfcff;
    }
  }

  @media (max-width: 768px) and (orientation: portrait) {
    .ant-checkbox-wrapper {
      // min-width: 100px;
    }
  }
`;

export const PayCard = styled(Card)`
  flex: 0 0 390px;
  margin: 24px 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &.selected {
    outline: 1px solid #40a9ff;
  }
  .ant-card-head {
    width: 100%;
    .ant-radio-wrapper {
      display: flex;
      gap: 8px;
      span {
        padding: 0;
      }
    }
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 32px 0 17px;
    flex: 1;
    & > div {
      // padding: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      flex: 1;
      align-self: stretch;
      & > .ant-image {
        margin: 0 24px;
        width: 62px;
        height: 62px;
        flex-shrink: 0;
        font-size: 24px;
        img {
          object-fit: contain;
          max-height: 100%;
          max-width: 100%;
        }
      }
      label {
        width: 235px;
        color: #989898;
        font-size: 12px;
      }
      b {
        font-size: 15px;
        line-height: 19px;
        font-weight: 400;
      }
      .ant-btn {
        width: 121px;
        height: 45px;
        // padding: 11.5px 16px 11.5px 16px;
        border-radius: 8px;
        gap: 8px;
        color: #fff;
        background-color: #292d32;
      }
    }
    // flex-direction: column;
  }

  @media (max-width: 768px) {
    flex: 1;
    margin: 4px 0;
    .ant-card-body {
      padding: 17px;
      & > div {
        & > span {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          width: 100%;
          .ant-image {
            margin: 0px;
            width: 32px;
            height: 32px;
          }
        }
        p {
          margin-top: 12px;
        }
      }
    }
  }
`;

const PaymentTab = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;

  .MuiButtonGroup-root {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 40px;
    .ant-btn {
      flex: 0 0 50%;
      height: 68px;
      padding: 11.5px 16px 11.5px 16px;
      border-radius: 8px;
      gap: 8px;
      background-color: #f4f4f4;
      &.ant-btn-primary {
        background-color: #292d32;
        flex: 0 0 50%;
      }
    }
  }
  @media (max-width: 768px) {
    .MuiButtonGroup-root {
      gap: 8px;
      .ant-btn {
        flex: 1;
        &.ant-btn-primary {
          flex: 1;
        }
      }
    }
  }
`;

export const CRow = styled(Row)`
  gap: 16px;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .ant-form {
      .ant-btn {
        background-color: #f6f6f6;
        border-radius: 8px;
      }
    }
  }
`;

export const PaymentStep = ({
  store,
  candidatePrice,
  setCandidatePrice,
  methodDelivery,
  setMethodDelivery,
  setShowLocationModal,
  dataLoading,
  location,
  setCurrentStep,
}: any) => {
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  return (
    <PaymentTab>
      <TitleLabel>
        <b>Request Price</b>
        <label>Please select one</label>
      </TitleLabel>
      <PriceInput style={{ justifyContent: "flex-start" }}>
        <PayCard
          style={{ margin: 0, flex: isMobile ? 1 : "0 0 390px" }}
          bodyStyle={{ padding: 0 }}
          title={
            <Radio defaultChecked>
              {store.storeSetPrice
                ? "Store will give you the highest offer"
                : "List your Price"}
            </Radio>
          }
        >
          {store.storeSetPrice ? (
            <p style={{ padding: "8px 24px" }}>
              We&apos;ll base our price of previous sales and demand and offer
              you the highest price.
            </p>
          ) : (
            <Input
              bordered={false}
              prefix={<b>{getSymbolFromCurrency(store.currency)}</b>}
              value={candidatePrice}
              onChange={(value) =>
                setCandidatePrice(
                  Number(value.target.value.replace(/[^0-9]/g, "")) ?? 0
                )
              }
            />
          )}
        </PayCard>
      </PriceInput>
      <TitleLabel>
        <b>Shipping</b>
        <label>Please select one</label>
      </TitleLabel>
      {isMobile ? (
        <div>
          <span style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <PayCard
              className={`${methodDelivery === 1 ? "selected" : ""}`}
              onClick={() => {
                setMethodDelivery(1);
                setShowLocationModal(true);
              }}
              style={{ height: 260 }}
            >
              <div>
                <span>
                  <Image preview={false} src={PushPinOutlined} />
                  <b>Drop Off</b>
                </span>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Nunc adipiscing nibh.
                </p>
              </div>
            </PayCard>
            <PayCard
              className={`${methodDelivery === 2 ? "selected" : ""}`}
              style={{ cursor: "not-allowed", height: 260 }}
              onClick={() => setMethodDelivery(2)}
            >
              <div>
                <span>
                  <Image preview={false} src={ShopOutlined} />
                  <b>Ship to Store</b>
                </span>
                <p>You can stop by one of our 20 locations to get your items</p>
              </div>
              {/* <Button>Select</Button> */}
            </PayCard>
            <ButtonGroup>
              <Button onClick={() => setCurrentStep((prev) => prev - 1)}>
                Back
              </Button>
              <Button
                type="primary"
                onClick={() => setCurrentStep((prev) => prev + 1)}
              >
                Next
              </Button>
            </ButtonGroup>
          </span>
        </div>
      ) : (
        <div>
          <span style={{ display: "flex", flexWrap: "nowrap", gap: 16 }}>
            <PayCard
              className={`${methodDelivery === 1 ? "selected" : ""}`}
              onClick={() => setMethodDelivery(1)}
              style={{ height: 260 }}
            >
              <div>
                <Image preview={false} src={PushPinOutlined} />
                <b>Drop Off</b>
                <label>
                  Lorem ipsum dolor sit amet consectetur. Nunc adipiscing nibh.
                </label>
                <br />
                <Button
                  disabled={dataLoading}
                  onClick={() => setShowLocationModal(true)}
                >
                  Select
                </Button>
              </div>
            </PayCard>
            <PayCard
              className={`${methodDelivery === 2 ? "selected" : ""}`}
              style={{ cursor: "not-allowed", height: 260 }}
              onClick={() => setMethodDelivery(2)}
            >
              <div>
                <Image preview={false} src={ShopOutlined} />
                <b>Ship to Store</b>
                <label>
                  You can stop by one of our 20 locations to get your items
                </label>
              </div>
              {/* <Button>Select</Button> */}
            </PayCard>
          </span>

          <ButtonGroup
            style={{ display: "flex", gap: 16, justifyContent: "flex-start" }}
          >
            <Button
              style={{ width: 390, flex: "0 0 auto" }}
              onClick={() => setCurrentStep((prev) => prev - 1)}
            >
              Back
            </Button>
            <Button
              type="primary"
              onClick={() => setCurrentStep((prev) => prev + 1)}
              style={{ width: 390, flex: "0 0 auto" }}
            >
              Next
            </Button>
          </ButtonGroup>
        </div>
      )}
    </PaymentTab>
  );
};

export const StepTwo = ({
  inventoriesToAdd,
  indexItemToEdit,
  setIndexItemToEdit,
  store,
  setInventoriesToAdd,
  newInventory,
  setNewInventory,
  candidateQty,
  candidatePrice,
  setCandidateQty,
  setCandidatePrice,
  setCurrentStep,
  methodDelivery,
  setMethodDelivery,
  location,
  setShowLocationModal,
  dataLoading,
  selectedTemplate,
  consignerData,
  setConsignerData,
  currentStep,
  handleCompleteProcess,
  dataSubmitting,
  product,
  isCustom,
  inventoryExtra,
  setInventoryExtra,
  handleConditionChange,
}: {
  inventoriesToAdd: Inventory[];
  indexItemToEdit: number;
  setIndexItemToEdit: any;
  store: Store;
  setInventoriesToAdd: any;
  newInventory: Inventory[];
  setNewInventory: any;
  candidateQty: number;
  candidatePrice: number;
  setCandidateQty: any;
  setCandidatePrice: any;
  setCurrentStep: any;
  methodDelivery: number;
  setMethodDelivery: any;
  location: string;
  setShowLocationModal: any;
  dataLoading: boolean;
  selectedTemplate: ProductTemplate;
  consignerData: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    zipCode: string;
  };
  setConsignerData: any;
  currentStep: number;
  handleCompleteProcess: any;
  product: Product;
  dataSubmitting: boolean;
  isCustom?: boolean;
  inventoryExtra: any;
  setInventoryExtra: any;
  handleConditionChange: any;
}) => {
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const [formUpload] = Form.useForm();
  const [productName, setProductName] = useState("");
  const conditions = ["Excellent", "Great", "Good", "Fair"];

  const accessories = [
    "Receipt",
    "Dust Bag",
    "Authenticity Card",
    "Care Cards",
    "Original Box",
    "Shoulder Strap",
  ];

  useEffect(() => {
    // console.log(productTemplates);
  }, [isMobile]);

  useEffect(() => {
    if (!productName || productName === "") return;
    console.log(productName);
    _.debounce(
      () =>
        updateProductFn(product.id, { ...product, title: productName }, store),
      300
    );
  }, [productName]);

  const handleOption2Change = (e: string) => {
    if (inventoriesToAdd[0].option2Value.includes(e)) {
      setInventoriesToAdd((prev) => [
        {
          ...prev[0],
          option2Value: prev[0].option2Value.filter((acc) => acc !== e),
        },
      ]);
      return;
    }
    setInventoriesToAdd((prev) => [
      {
        ...prev[0],
        option2Value: [...prev[0].option2Value, e],
      },
    ]);
  };

  const handleOption3Change = (e: string) => {
    if (inventoriesToAdd[0].option3Value.includes(e)) {
      setInventoriesToAdd((prev) => [
        {
          ...prev[0],
          option3Value: prev[0].option3Value.filter((acc) => acc !== e),
        },
      ]);
      return;
    }
    setInventoriesToAdd((prev) => [
      {
        ...prev[0],
        option3Value: [...prev[0].option3Value, e],
      },
    ]);
  };

  const handleOption4Change = (e: string) => {
    if (inventoriesToAdd[0].option4Value.includes(e)) {
      setInventoriesToAdd((prev) => [
        {
          ...prev[0],
          option4Value: prev[0].option4Value.filter((acc) => acc !== e),
        },
      ]);
      return;
    }
    setInventoriesToAdd((prev) => [
      {
        ...prev[0],
        option4Value: [...prev[0].option4Value, e],
      },
    ]);
  };

  const handleOption5Change = (e: string) => {
    if (inventoriesToAdd[0].option5Value.includes(e)) {
      setInventoriesToAdd((prev) => [
        {
          ...prev[0],
          option5Value: prev[0].option5Value.filter((acc) => acc !== e),
        },
      ]);
      return;
    }
    setInventoriesToAdd((prev) => [
      {
        ...prev[0],
        option5Value: [...prev[0].option5Value, e],
      },
    ]);
  };

  const handleOption6Change = (e: string) => {
    if (inventoriesToAdd[0].option6Value.includes(e)) {
      setInventoriesToAdd((prev) => [
        {
          ...prev[0],
          option6Value: prev[0].option6Value.filter((acc) => acc !== e),
        },
      ]);
      return;
    }
    setInventoriesToAdd((prev) => [
      {
        ...prev[0],
        option6Value: [...prev[0].option6Value, e],
      },
    ]);
  };

  useEffect(() => {
    setIndexItemToEdit(0);
  }, []);

  return (
    <GridContainer style={{ width: "100%", maxWidth: "100%" }}>
      {!isMobile && <h1>Describe the Condition</h1>}
      <div
        style={{
          display: "flex",
          gap: isMobile ? 10 : 40,
          flexDirection: "column",
        }}
      >
        {isCustom && (
          <Col style={{ flexDirection: "column", display: "flex" }}>
            <label>Name of product</label>
            <Input
              value={productName}
              onChange={(ev) => setProductName(ev.target.value)}
              style={{
                width: isMobile ? "100%" : 390,
                height: 40,
                border: "0.3px solid #000",
                fontSize: 13,
                lineHeight: 16,
              }}
            />
          </Col>
        )}
        {isMobile && (
          <TitleLabel>
            <b>Product Images</b>
            <label>You can add multiple images for your product</label>
          </TitleLabel>
        )}
        <Col>
          <label>Required Photos</label>
          <CRow>
            <div>
              <ImageUpload
                form={formUpload}
                logo={inventoryExtra.frontImage}
                uploadedLogo={(e) =>
                  setInventoryExtra({ ...inventoryExtra, frontImage: e })
                }
              />
              <p>Front</p>
            </div>
            <div>
              <ImageUpload
                form={formUpload}
                logo={inventoryExtra.brandLogo}
                uploadedLogo={(e) =>
                  setInventoryExtra({ ...inventoryExtra, brandLogo: e })
                }
              />
              <p>Brand Logo</p>
            </div>
            <div>
              <ImageUpload
                form={formUpload}
                logo={inventoryExtra.manufacturingTag}
                uploadedLogo={(e) =>
                  setInventoryExtra({ ...inventoryExtra, manufacturingTag: e })
                }
              />
              <p>Manufacturing Tag</p>
            </div>
            <div>
              <ImageUpload
                form={formUpload}
                logo={inventoryExtra.insideLabelTwo}
                uploadedLogo={(e) =>
                  setInventoryExtra({ ...inventoryExtra, insideLabelTwo: e })
                }
              />
              <p>Inside label (2)</p>
            </div>
            <div>
              <ImageUpload
                form={formUpload}
                logo={inventoryExtra.insideLabelThree}
                uploadedLogo={(e) =>
                  setInventoryExtra({ ...inventoryExtra, insideLabelThree: e })
                }
              />
              <p>Inside label (3)</p>
            </div>
            <div>
              <ImageUpload
                form={formUpload}
                logo={inventoryExtra.serialCode}
                uploadedLogo={(e) =>
                  setInventoryExtra({ ...inventoryExtra, serialCode: e })
                }
              />
              <p>Serial Code</p>
            </div>
            <div>
              <ImageUpload
                form={formUpload}
                logo={inventoryExtra.zipperFront}
                uploadedLogo={(e) =>
                  setInventoryExtra({ ...inventoryExtra, zipperFront: e })
                }
              />
              <p>Zipper (Front)</p>
            </div>
            <div>
              <ImageUpload
                form={formUpload}
                logo={inventoryExtra.zipperBack}
                uploadedLogo={(e) =>
                  setInventoryExtra({ ...inventoryExtra, zipperBack: e })
                }
              />
              <p>Zipper (Back)</p>
            </div>
          </CRow>
        </Col>
        <Row>
          <Col style={{ flex: isMobile ? "0 0 100%" : 1 }}>
            <TitleLabel>
              <b>Product Condition</b>
              <label>Please select one</label>
            </TitleLabel>
            <Row>
              <ConditionSelector onChange={handleConditionChange}>
                {conditions?.map((condition, idx) => (
                  <Radio.Button
                    key={idx}
                    value={condition}
                    style={{ textTransform: "capitalize" }}
                  >
                    {condition}
                  </Radio.Button>
                ))}
              </ConditionSelector>
            </Row>
            {selectedTemplate.option2 && selectedTemplate.option2Values && (
              <div>
                <TitleLabel>
                  <b>{selectedTemplate?.option2}</b>
                  <label>You can select multiple choices</label>
                </TitleLabel>
                <Row>
                  <AccessorySelector>
                    {selectedTemplate?.option2Values.map((acc, idx) => (
                      <Button
                        key={idx}
                        onClick={() => handleOption2Change(acc)}
                        className={`${
                          inventoriesToAdd[0].option2Value.includes(acc)
                            ? "checked"
                            : ""
                        }`}
                      >
                        <Checkbox
                          checked={inventoriesToAdd[0].option2Value.includes(
                            acc
                          )}
                          style={{ textTransform: "capitalize" }}
                        />
                        <b>{acc}</b>
                      </Button>
                    ))}
                  </AccessorySelector>
                </Row>
              </div>
            )}
            {selectedTemplate.option3 && selectedTemplate.option3Values && (
              <div>
                <TitleLabel>
                  <b>{selectedTemplate?.option3}</b>
                  <label>You can select multiple choices</label>
                </TitleLabel>
                <Row>
                  <AccessorySelector>
                    {selectedTemplate?.option3Values.map((acc, idx) => (
                      <Button
                        key={idx}
                        onClick={() => handleOption3Change(acc)}
                        className={`${
                          inventoriesToAdd[0].option3Value.includes(acc)
                            ? "checked"
                            : ""
                        }`}
                      >
                        <Checkbox
                          checked={inventoriesToAdd[0].option3Value.includes(
                            acc
                          )}
                          style={{ textTransform: "capitalize" }}
                        />
                        <b>{acc}</b>
                      </Button>
                    ))}
                  </AccessorySelector>
                </Row>
              </div>
            )}
            {selectedTemplate.option4 && selectedTemplate.option4Values && (
              <div>
                <TitleLabel>
                  <b>{selectedTemplate?.option4}</b>
                  <label>You can select multiple choices</label>
                </TitleLabel>
                <Row>
                  <AccessorySelector>
                    {selectedTemplate?.option4Values.map((acc, idx) => (
                      <Button
                        key={idx}
                        onClick={() => handleOption4Change(acc)}
                        className={`${
                          inventoriesToAdd[0].option4Value.includes(acc)
                            ? "checked"
                            : ""
                        }`}
                      >
                        <Checkbox
                          checked={inventoriesToAdd[0].option4Value.includes(
                            acc
                          )}
                          style={{ textTransform: "capitalize" }}
                        />
                        <b>{acc}</b>
                      </Button>
                    ))}
                  </AccessorySelector>
                </Row>
              </div>
            )}
            {selectedTemplate.option5 && selectedTemplate.option5Values && (
              <div>
                <TitleLabel>
                  <b>{selectedTemplate?.option5}</b>
                  <label>You can select multiple choices</label>
                </TitleLabel>
                <Row>
                  <AccessorySelector>
                    {selectedTemplate?.option5Values.map((acc, idx) => (
                      <Button
                        key={idx}
                        onClick={() => handleOption5Change(acc)}
                        className={`${
                          inventoriesToAdd[0].option5Value.includes(acc)
                            ? "checked"
                            : ""
                        }`}
                      >
                        <Checkbox
                          checked={inventoriesToAdd[0].option5Value.includes(
                            acc
                          )}
                          style={{ textTransform: "capitalize" }}
                        />
                        <b>{acc}</b>
                      </Button>
                    ))}
                  </AccessorySelector>
                </Row>
              </div>
            )}
            {selectedTemplate.option6 && selectedTemplate.option6Values && (
              <div>
                <TitleLabel>
                  <b>{selectedTemplate?.option6}</b>
                  <label>You can select multiple choices</label>
                </TitleLabel>
                <Row>
                  <AccessorySelector>
                    {selectedTemplate?.option6Values.map((acc, idx) => (
                      <Button
                        key={idx}
                        onClick={() => handleOption6Change(acc)}
                        className={`${
                          inventoriesToAdd[0].option6Value.includes(acc)
                            ? "checked"
                            : ""
                        }`}
                      >
                        <Checkbox
                          checked={inventoriesToAdd[0].option6Value.includes(
                            acc
                          )}
                          style={{ textTransform: "capitalize" }}
                        />
                        <b>{acc}</b>
                      </Button>
                    ))}
                  </AccessorySelector>
                </Row>
              </div>
            )}
          </Col>
          <Col
            style={{
              flex: isMobile ? "0 0 100%" : 1,
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Card
              style={{ flex: 1, alignSelf: "stretch", display: "flex" }}
              bodyStyle={{
                flex: 1,
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                height: isMobile ? 300 : "initial",
              }}
            >
              <label style={{ flex: "0 0 auto" }}>Notes</label>
              <Input.TextArea
                bordered={false}
                style={{ flex: 1 }}
                onChange={(e) =>
                  setInventoriesToAdd((prev) => [
                    { ...prev[0], notes: e.target.value },
                  ])
                }
                value={inventoriesToAdd[0].notes}
                placeholder="You can add notes here for your product"
              />
            </Card>
            <ButtonGroup
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                gap: 12,
                marginBottom: isMobile ? 0 : 50,
                marginTop: 10,
              }}
            >
              <Button
                style={{
                  alignSelf: "stretch",
                  height: 68,
                  width: "50%",
                  maxWidth: "50%",
                  // flex: 1,
                  borderRadius: 8,
                  background: "var(--Grey---Button-Fill, #F4F4F4)",
                }}
                type="default"
                onClick={() => setCurrentStep((prev) => prev - 1)}
              >
                Back
              </Button>
              <StyledNextButton
                style={{
                  position: "relative",
                  left: "initial",
                  bottom: "initial",
                  transform: "none",
                  maxWidth: "50%",
                  // flex: 1,
                  height: 68,
                  width: "50%",
                }}
                // disabled={ /*  || (newInventory && Number(newInventory.price) === 0) */}
                onClick={() => {
                  setCurrentStep((prev) => prev + 1);
                }}
              >
                Next
              </StyledNextButton>
            </ButtonGroup>
          </Col>
        </Row>
      </div>
    </GridContainer>
  );
};
