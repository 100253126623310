// import { NewConsignerFormValues } from "../../consignerScreens/Signup";
// import { StoreFormValues } from "../../components/Common/SetupSettings";
import { ProductTemplate } from "../reducers/ProductTemplateReducer";
import { AppDispatch } from "../store";
import {
  CREATE_PRODUCTTEMPLATE,
  DELETE_PRODUCTTEMPLATE,
  GET_PRODUCTTEMPLATE,
  GET_PRODUCTTEMPLATES,
  UPDATE_PRODUCTTEMPLATE,
} from "./types";
import { api } from "../../api";

const updateProductTemplateLoading = () => ({
  type: UPDATE_PRODUCTTEMPLATE,
  updateProductTemplate: null,
  updateProductTemplateLoading: true,
});

const updateProductTemplateError = () => ({
  type: UPDATE_PRODUCTTEMPLATE,
  updateProductTemplate: null,
  updateProductTemplateLoading: false,
});

const updateProductTemplateSuccess = (
  updateProductTemplate: ProductTemplate
) => ({
  type: UPDATE_PRODUCTTEMPLATE,
  updateProductTemplate,
  updateProductTemplateLoading: false,
});

const createProductTemplateLoading = () => ({
  type: CREATE_PRODUCTTEMPLATE,
  createProductTemplate: null,
  createProductTemplateLoading: true,
});

const createProductTemplateError = () => ({
  type: CREATE_PRODUCTTEMPLATE,
  createProductTemplate: null,
  createProductTemplateLoading: false,
});

const createProductTemplateSuccess = (
  createProductTemplate: ProductTemplate
) => ({
  type: CREATE_PRODUCTTEMPLATE,
  createProductTemplate,
  createProductTemplateLoading: false,
});
const getProductTemplatesLoading = () => ({
  type: GET_PRODUCTTEMPLATES,
  productTemplates: [],
  productTemplatesLoading: true,
});

const getProductTemplatesError = () => ({
  type: GET_PRODUCTTEMPLATES,
  productTemplates: [],
  productTemplatesLoading: false,
});

const getProductTemplatesSuccess = (productTemplates: ProductTemplate[]) => ({
  type: GET_PRODUCTTEMPLATES,
  productTemplates,
  productTemplatesLoading: false,
});

const getProductTemplateLoading = () => ({
  type: GET_PRODUCTTEMPLATE,
  productTemplate: null,
  productTemplateLoading: true,
});

const getProductTemplateError = () => ({
  type: GET_PRODUCTTEMPLATE,
  productTemplate: null,
  productTemplateLoading: false,
});

const getProductTemplateSuccess = (productTemplate: ProductTemplate) => ({
  type: GET_PRODUCTTEMPLATE,
  productTemplate,
  productTemplateLoading: false,
});

const deleteProductTemplateLoading = () => ({
  type: DELETE_PRODUCTTEMPLATE,
  productTemplate: null,
  productTemplateLoading: true,
});

const deleteProductTemplateError = () => ({
  type: DELETE_PRODUCTTEMPLATE,
  productTemplate: null,
  productTemplateLoading: false,
});

const deleteProductTemplateSuccess = (productTemplate: ProductTemplate) => ({
  type: DELETE_PRODUCTTEMPLATE,
  productTemplate,
  productTemplateLoading: false,
});

//api
const getProductTemplatesFromAPI = async () => {
  const { data, error } = await api.provide("get", "/api/productTemplates", {});
  if (error) throw error;
  return (data?.productTemplates || []) as any;
};

const getProductTemplateFromAPI = async (id: string) => {
  const { data, error } = await api.provide(
    "get",
    "/api/productTemplates/:id",
    {
      id,
    }
  );
  if (error) throw error;
  return data;
};

const deleteProductTemplateFromAPI = async (id: string) => {
  const { data, error } = await api.provide(
    "delete",
    "/api/productTemplates/:id",
    {
      id,
    }
  );
  if (error) throw error;
  return data;
};

const updateProductTemplateFromAPI = async (
  id: string,
  updateProductTemplate: ProductTemplate
) => {
  const payload = {
    id,
    title: updateProductTemplate.title,
    brand: updateProductTemplate.brand,
    category: updateProductTemplate.category,
    option1: updateProductTemplate.option1,
    option2: updateProductTemplate.option2,
    option3: updateProductTemplate.option3,
    option1Values: updateProductTemplate.option1Values,
    option2Values: updateProductTemplate.option2Values,
    option3Values: updateProductTemplate.option3Values,
    option4: updateProductTemplate.option4,
    option5: updateProductTemplate.option5,
    option6: updateProductTemplate.option6,
    option4Values: updateProductTemplate.option4Values,
    option5Values: updateProductTemplate.option5Values,
    option6Values: updateProductTemplate.option6Values,
    weight: updateProductTemplate.weight,
    gender: updateProductTemplate.gender,
  };

  const { data, error } = await api.provide(
    "put",
    "/api/productTemplates/:id",
    payload
  );
  if (error) throw error;
  return data;
};

const createProductTemplateFromAPI = async (
  newProductTemplate: ProductTemplate
) => {
  const payload = {
    title: newProductTemplate.title,
    brand: newProductTemplate.brand,
    category: newProductTemplate.category,
    option1: newProductTemplate.option1,
    option2: newProductTemplate.option2,
    option3: newProductTemplate.option3,
    option1Values: newProductTemplate.option1Values,
    option2Values: newProductTemplate.option2Values,
    option3Values: newProductTemplate.option3Values,
    option4: newProductTemplate.option4,
    option5: newProductTemplate.option5,
    option6: newProductTemplate.option6,
    option4Values: newProductTemplate.option4Values,
    option5Values: newProductTemplate.option5Values,
    option6Values: newProductTemplate.option6Values,
    weight: newProductTemplate.weight,
    gender: newProductTemplate.gender,
  };
  const { data, error } = await api.provide(
    "post",
    "/api/productTemplates",
    payload
  );
  if (error) throw error;
  return data;
};

//actions
export const getProductTemplates = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(getProductTemplatesLoading());
    try {
      dispatch(getProductTemplatesSuccess(await getProductTemplatesFromAPI()));
    } catch (e) {
      dispatch(getProductTemplatesError());
    }
  };
};

export const getProductTemplate = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getProductTemplateLoading());
    try {
      dispatch(getProductTemplateSuccess(await getProductTemplateFromAPI(id)));
    } catch (e) {
      dispatch(getProductTemplateError());
    }
  };
};

export const deleteProductTemplate = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(deleteProductTemplateLoading());
    try {
      dispatch(
        deleteProductTemplateSuccess(await deleteProductTemplateFromAPI(id))
      );
    } catch (e) {
      dispatch(deleteProductTemplateError());
    }
  };
};

export const createProductTemplate = (productTemplate: ProductTemplate) => {
  return async (dispatch: AppDispatch) => {
    dispatch(createProductTemplateLoading());
    try {
      dispatch(
        createProductTemplateSuccess(
          await createProductTemplateFromAPI(productTemplate)
        )
      );
    } catch (e) {
      dispatch(createProductTemplateError());
    }
  };
};

export const updateProductTemplate = (
  id: string,
  productTemplate: ProductTemplate
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateProductTemplateLoading());
    try {
      dispatch(
        updateProductTemplateSuccess(
          await updateProductTemplateFromAPI(id, productTemplate)
        )
      );
    } catch (e) {
      dispatch(updateProductTemplateError());
    }
  };
};
