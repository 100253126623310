import React, { useEffect, useState } from "react";
import { Progress, Image, Input, Select, message } from "antd";
import {
  Box,
  BoxButton,
  OverlayBox,
  PopDiv,
  PopupClose,
  PopupContent,
  BoxContentP,
  FlexDiv,
  PercentDiv,
  StepsDiv,
  SelectDiv,
  CustomCard,
  CustomButton,
  RadiusDiv,
  StyledSelect,
} from "../styles/PopUpModals";
import CheckImg from "../assets/tick-circle.png";
// import RocketIcon from "./../../assets/images/Icon.png";
import { ReusableModal } from "../components/Common/ReusableModal";
import { CloseOutlined } from "@ant-design/icons";
import {
  bookDemo,
  getStore,
  updateStoreShop,
} from "../redux/actions/storeActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { api } from "../api";

interface Steps {
  signUp: boolean;
  bookDemo: boolean;
  syncStore: boolean;
  migrateInventory: boolean;
  storeSettings: boolean;
}

const { Option } = Select;

export const SignUpSteps = () => {
  const { store, storeLoading } = useAppSelector((state) => state.StoreReducer);
  const [storeData, setStoreData] = useState<any>();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenSync, setIsModalOpenSync] = useState(false);
  const [isModalOpenMigrate, setIsModalOpenMigrate] = useState(false);
  const [isModalOpenSetting, setIsModalOpenSetting] = useState(false);
  const [steps, setSteps] = useState<Steps>({
    signUp: true,
    bookDemo: store?.isDemo,
    syncStore: store?.shop?.shop?.length > 0 ? true : false,
    migrateInventory: store.isMigrate,
    storeSettings: false,
  });
  const [progress, setProgress] = useState(20);
  const [currentStep, setCurrentStep] = useState(2); // Starting from step 2
  //shopify url
  const shopQuery = queryParams.get("shop");

  const [shopURL, setShopURL] = useState(shopQuery || "");
  const getStoreFromAPI = async (id: string) => {
    const { data, error } = await api.provide("get", "/api/stores/:id", { id });
    if (error) throw error;
    setStoreData(data);
    return data;
  };
  useEffect(() => {
    const id = store?.id.toString();
    getStoreFromAPI(id);
  }, []);
  const allStoreSettingsAvailable = () => {
    return (
      store.currency &&
      store.consignerUrl &&
      store.inStoreUrl &&
      store.labelOptions.length > 0 &&
      Object.values(store.labelSize).length > 0 &&
      store.name &&
      store.payoutOptions.length > 0 &&
      store.redirectURL &&
      store.shipInstructions &&
      store.supportEmail
    );
  };
  useEffect(() => {
    const completedSteps = Object.values(steps).filter((step) => step).length;
    const totalSteps = 5; // Including the signup step
    const percentage = (completedSteps / totalSteps) * 100;
    setProgress(percentage);

    // Calculate the current step based on the completed steps excluding signUp
    const currentStepCount = completedSteps - 1; // Subtracting the signUp step
    if (currentStep < 4) {
      setCurrentStep(currentStepCount + 1); // Adding 1 to match the step count display
    }
  }, [steps]);
  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (allStoreSettingsAvailable()) {
      setSteps((prevSteps) => ({
        ...prevSteps,
        storeSettings: true,
      }));
    }
  }, [storeData]);

  const showModalSync = () => {
    setIsModalOpenSync(true);
  };
  const showModalMigrate = () => {
    setIsModalOpenMigrate(true);
  };
  const showModalSetting = () => {
    setIsModalOpenSetting(true);
  };
  const openNextIncompleteModal = (updatedSteps: Steps) => {
    const id = store?.id.toString();
    getStoreFromAPI(id);
    if (!updatedSteps.bookDemo) {
      setIsModalOpen(true);
    } else if (!updatedSteps.syncStore) {
      setIsModalOpenSync(true);
    } else if (!updatedSteps.migrateInventory) {
      setIsModalOpenMigrate(true);
    } else if (!updatedSteps.storeSettings) {
      setIsModalOpenSetting(true);
    }
  };

  const handleSelectionChange = async (value) => {
    setSteps((prevSteps) => {
      const updatedSteps = { ...prevSteps, migrateInventory: value };
      return updatedSteps;
    });
  };
  const isMigrateApi = async () => {
    try {
      const { data, error } = await api.provide(
        "put",
        "/api/stores/storeMigrate",
        { isMigrate: steps.migrateInventory }
      );
      if (error) {
        message.error(error.message);
      }
      setStoreData(data);
      return data;
    } catch (error) {
      console.error("Error handling selection change");
      // Handle the error (e.g., show a notification to the user)
    }
  };
  const handleShopifySync = () => {
    if (shopURL && shopURL !== "") {
      dispatch(bookDemo(shopURL));
    } else {
      alert("Please enter your Shopify URL");
    }
    setSteps((prevSteps) => {
      const updatedSteps = { ...prevSteps, syncStore: true };
      openNextIncompleteModal(updatedSteps);
      return updatedSteps;
    });
    setIsModalOpenSync(false);
  };

  const handleBookDemo = () => {
    dispatch(bookDemo(true));
    window.open("https://calendly.com/jointrace/15mins", "_blank");
    setSteps((prevSteps) => {
      const updatedSteps = { ...prevSteps, bookDemo: true };
      openNextIncompleteModal(updatedSteps);
      return updatedSteps;
    });
    setIsModalOpen(false);
  };

  const handleMigrateInventory = async () => {
    const data = await isMigrateApi();
    if (data) {
      setSteps((prevSteps) => {
        const updatedSteps = {
          ...prevSteps,
          migrateInventory: steps.migrateInventory,
        };
        openNextIncompleteModal(updatedSteps);
        return updatedSteps;
      });
      setIsModalOpenMigrate(false);
    }
  };

  const handleStoreSettings = () => {
    setSteps((prevSteps) => {
      const updatedSteps = { ...prevSteps, storeSettings: true };
      openNextIncompleteModal(updatedSteps);
      return updatedSteps;
    });
    setIsModalOpenSetting(false);
    history.push("/settings");
  };

  return (
    <>
      <ReusableModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onPrimaryAction={handleBookDemo}
        onSecondaryAction={() => {
          setIsModalOpen(false);
          setIsModalOpenSync(true);
        }}
        title="Getting Started"
        step="step 1/4"
        mainTitle="Book Demo"
        sideTitle="Figma ipsum component variant main layer. Component figma connection distribute library team underline. Link plugin vertical arrange pen rectangle figma fill"
        primaryButtonText="Book Demo"
        secondaryButtonText="Next"
      />

      <ReusableModal
        isOpen={isModalOpenSync}
        onClose={() => setIsModalOpenSync(false)}
        onPrimaryAction={handleShopifySync}
        onSecondaryAction={() => {
          setIsModalOpenSync(false);
          setIsModalOpenMigrate(true);
        }}
        title="Getting Started"
        step="step 2/4"
        mainTitle="Shopify Sync"
        sideTitle="Add your Shopify URL, it should end in .myshopify.com and it can be found on your DOMAIN PAGE in your setting"
        primaryButtonText="Sync Now"
        secondaryButtonText="Next"
      >
        <Input
          placeholder="Link"
          style={{ width: "614px", height: "48px", borderRadius: "8px" }}
          onChange={(e) => setShopURL(e.target.value)}
        />
      </ReusableModal>
      <ReusableModal
        isOpen={isModalOpenMigrate}
        onClose={() => setIsModalOpenMigrate(false)}
        onPrimaryAction={handleMigrateInventory}
        onSecondaryAction={() => {
          setIsModalOpenMigrate(false);
          setIsModalOpenSetting(true);
        }}
        title="Getting Started"
        step="step 3/4"
        mainTitle="How do you want to migrate all your inventory?"
        primaryButtonText="Submit"
        secondaryButtonText="Next"
      >
        <StyledSelect
          size="large"
          defaultValue="Answer"
          placeholder="Answer"
          onChange={handleSelectionChange}
        >
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </StyledSelect>
      </ReusableModal>
      <ReusableModal
        isOpen={isModalOpenSetting}
        onClose={() => setIsModalOpenSetting(false)}
        onPrimaryAction={handleStoreSettings}
        title="Getting Started"
        step="step 4/4"
        mainTitle="Complete all your store details in the settings option"
        primaryButtonText="GO TO SETTING"
      />
      {/* {steps?.migrateInventory === null ? null : ( */}
      {steps?.bookDemo &&
      steps?.syncStore &&
      steps?.migrateInventory &&
      steps?.storeSettings ? (
        ""
      ) : (
        <Box>
          <BoxButton href="#Steps">
            <RadiusDiv>{currentStep}/4</RadiusDiv>
            <CloseOutlined />
          </BoxButton>
        </Box>
      )}
      {/* )} */}

      <OverlayBox id="Steps">
        <PopDiv>
          <BoxContentP>Getting started</BoxContentP>
          <PopupClose href="#">
            <CloseOutlined />
          </PopupClose>
          <PopupContent>
            unlock the secrets to a smoother running <br /> store with Trace
          </PopupContent>
          <FlexDiv>
            <PercentDiv>{progress}%</PercentDiv>
            <StepsDiv>Step {currentStep} of 4</StepsDiv>
          </FlexDiv>
          <Progress percent={progress} showInfo={false} />
        </PopDiv>
        <SelectDiv>
          <div style={{ margin: "0.3rem" }}>
            <CustomCard
              onClick={() => !steps.bookDemo && showModal()}
              style={{
                cursor: `${steps.bookDemo ? "not-allowed" : "pointer"}`,
                color: `${steps.bookDemo ? "green" : ""}`,
              }}
            >
              {steps.bookDemo && <Image width={25} src={CheckImg} />}
              1. Book demo to complete set up
            </CustomCard>
            <CustomCard
              onClick={() => !steps.syncStore && showModalSync()}
              style={{
                cursor: `${steps.syncStore ? "not-allowed" : "pointer"}`,
                color: `${steps.syncStore ? "green" : ""}`,
              }}
            >
              {steps.syncStore && <Image width={25} src={CheckImg} />}
              2. Shopify URL - Sent invite for Syncing
            </CustomCard>
            <CustomCard
              onClick={() => !steps.migrateInventory && showModalMigrate()}
              style={{
                cursor: `${steps.migrateInventory ? "not-allowed" : "pointer"}`,
                color: `${steps.migrateInventory ? "green" : ""}`,
              }}
            >
              {steps.migrateInventory && <Image width={25} src={CheckImg} />}
              3. Migrate all your inventory
            </CustomCard>
            <CustomCard
              onClick={() => !steps.storeSettings && showModalSetting()}
              style={{
                cursor: `${steps.storeSettings ? "not-allowed" : "pointer"}`,
                color: `${steps.storeSettings ? "green" : ""}`,
              }}
            >
              {steps.storeSettings && <Image width={25} src={CheckImg} />}
              4. Store Settings
            </CustomCard>

            <CustomButton>Finish</CustomButton>
          </div>
        </SelectDiv>
      </OverlayBox>
    </>
  );
};
