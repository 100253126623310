import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Spin,
  Row,
  Col,
  Input,
  Checkbox,
  Form,
  Radio,
  Select,
  Image,
  message,
} from "antd";
import {
  GridContainer,
  StyledNextButton,
  StyledSearchInput,
} from "../../luxury/Lux";
import {
  createProduct,
  updateProduct,
} from "../../redux/actions/productActions";
import { AppState } from "../../redux/reducers/AppReducer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ProductCard } from "../../luxury/ProductCard";
import { Product, ProductState } from "../../redux/reducers/ProductReducer";
import {
  ProductTemplate,
  ProductTemplateState,
} from "../../redux/reducers/ProductTemplateReducer";
import styled from "styled-components";
import ImageUpload from "../Common/ImageUpload";
import { ButtonGroup } from "@mui/material";
import {
  AccessorySelector,
  CRow,
  ConditionSelector,
  PayCard,
  PriceInput,
  TitleLabel,
} from "../../luxury/StepTwo";
import {
  Inventory,
  InventoryState,
} from "../../redux/reducers/InventoryReducer";
import { InventoryFormValues } from "../../screens/AddInventory";
import {
  createBuyOrderFromApi,
  getInventoryFilterOptions,
} from "../../redux/actions/inventoryActions";
import { StoreState } from "../../redux/reducers/StoreReducer";
import getSymbolFromCurrency from "currency-symbol-map";

const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px; // Space between rows
`;

const StyledCol = styled(Col)`
  padding: 0 15px; // Space between columns
`;

const CustomSelect = styled(Select)`
  &.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: 100%;
    }
  }
`;

export const LocationPriceInput = styled.div`
  margin: 24px 0;
  display: inline-flex;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #fff;
  gap: 20px;
  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    label {
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
  .ant-input-affix-wrapper-focused {
    border-color: #685ff9b2;
    box-shadow: none;
  }
  .ant-input-affix-wrapper:hover {
    border-color: #685ff9b2 !important;
  }
  .ant-select {
    flex: 1;
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);
    background: var(--White, #fff);
    display: flex;
    align-items: center;
    span {
      padding: 1 11px;
    }
  }
  .ant-input-affix-wrapper {
    flex: 1;
    padding: 0 11px;
    border-radius: 8px;
    gap: 5px;
  }
  .ant-btn {
    flex: 0 0 80px;
    align-self: stretch;
    height: 100%;
  }
  .ant-input,
  .ant-input-number,
  .ant-input-prefix,
  .ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector,
    .ant-select-selection-item {
      line-height: 50px;
      height: 50px;
    }
    height: 50px;
    color: var(--Light---Black, #292d32);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
    b {
      flex: 1;
      font-weight: 500 !important;
    }
  }
  .ant-input {
    text-align: left;
  }
  .ant-input-number {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    input {
      text-align: center;
    }
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-prefix {
    text-align: right;
  }
`;

interface AddProductModalProps {
  showAddProductModal: boolean;
  setShowAddProductModal: any;
  searchProducts: any;
  reloadInventories: any;
}

const AddProductModal = ({
  showAddProductModal,
  setShowAddProductModal,
  searchProducts,
  reloadInventories,
}: AddProductModalProps) => {
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const [isCustom, setIsCustom] = useState(false);
  const dispatch = useAppDispatch();
  const {
    createdProduct,
    products,
    productsLoading,
    updatedProduct,
    updateProductLoading,
    createProductLoading,
  }: ProductState = useAppSelector((state) => state.ProductReducer);
  const { productTemplates, productTemplatesLoading }: ProductTemplateState =
    useAppSelector((state) => state.ProductTemplateReducer);
  const {
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const [productData, setProductData] = useState<Product>();
  const [formUpload] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedTemplate, setSelectedTemplate] = useState<ProductTemplate>();
  const [inventoryToAdd, setInventoryToAdd] = useState<InventoryFormValues>({
    quantity: 1,
    option1Value: "",
    option2Value: [],
    option3Value: [],
    option4Value: [],
    option5Value: [],
    option6Value: [],
    status: "Requested",
    price: 0,
    cost: 0,
    consigner: "",
    category: "",
    productId: "",
    location: "",
  });
  const conditions = ["Excellent", "Great", "Good", "Fair"];
  const accessories = [
    "Receipt",
    "Dust Bag",
    "Authenticity Card",
    "Care Cards",
    "Original Box",
    "Shoulder Strap",
  ];
  const [inventoryExtra, setInventoryExtra] = useState<{
    brandLogo: string;
    frontImage: string;
    manufacturingTag: string;
    insideLabelTwo: string;
    insideLabelThree: string;
    serialCode: string;
    zipperFront: string;
    zipperBack: string;
    // accessories: string[];
  }>({
    brandLogo: "",
    frontImage: "",
    manufacturingTag: "",
    insideLabelTwo: "",
    insideLabelThree: "",
    serialCode: "",
    zipperFront: "",
    zipperBack: "",
    // accessories: [],
  });
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);

  useEffect(() => {
    if (!inventoryToAdd) return;
    console.log(inventoryToAdd);
  }, [inventoryToAdd]);

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
  }, []);

  useEffect(() => {
    if (store.noCatalogue && productTemplates && productTemplates.length > 0) {
      setIsCustom(true);
      const tpl = productTemplates.filter(
        (template) => template.brand === "trace"
      )?.[0];
      setSelectedTemplate(tpl);
      dispatch(
        createProduct({
          title: "",
          brand: "",
          category: "custom",
          sku: "",
          images: "",
          productTemplateId: tpl.id,
          stockXId: "",
          shopifyId: "",
          image: "",
          gender: "",
        })
      );
      setCurrentStep(2);
    }
  }, [store.noCatalogue, productTemplates, showAddProductModal]);

  const handleOption2Change = (e: string) => {
    if (inventoryToAdd.option2Value.includes(e)) {
      setInventoryToAdd((prev) => ({
        ...prev,
        option2Value: prev.option2Value.filter((acc) => acc !== e),
      }));
      return;
    }
    setInventoryToAdd((prev) => ({
      ...prev,
      option2Value: [...prev.option2Value, e],
    }));
  };

  const handleOption3Change = (e: string) => {
    if (inventoryToAdd.option3Value.includes(e)) {
      setInventoryToAdd((prev) => ({
        ...prev,
        option3Value: prev.option3Value.filter((acc) => acc !== e),
      }));
      return;
    }
    setInventoryToAdd((prev) => ({
      ...prev,
      option3Value: [...prev.option3Value, e],
    }));
  };
  const handleOption4Change = (e: string) => {
    if (inventoryToAdd.option4Value.includes(e)) {
      setInventoryToAdd((prev) => ({
        ...prev,
        option4Value: prev.option4Value.filter((acc) => acc !== e),
      }));
      return;
    }
    setInventoryToAdd((prev) => ({
      ...prev,
      option4Value: [...prev.option4Value, e],
    }));
  };

  const handleOption5Change = (e: string) => {
    if (inventoryToAdd.option5Value.includes(e)) {
      setInventoryToAdd((prev) => ({
        ...prev,
        option5Value: prev.option5Value.filter((acc) => acc !== e),
      }));
      return;
    }
    setInventoryToAdd((prev) => ({
      ...prev,
      option5Value: [...prev.option5Value, e],
    }));
  };

  const handleOption6Change = (e: string) => {
    if (inventoryToAdd.option6Value.includes(e)) {
      setInventoryToAdd((prev) => ({
        ...prev,
        option6Value: prev.option6Value.filter((acc) => acc !== e),
      }));
      return;
    }
    setInventoryToAdd((prev) => ({
      ...prev,
      option6Value: [...prev.option6Value, e],
    }));
  };

  const handleSelectProduct = async (product: Product) => {
    const tpl = productTemplates.filter(
      (template) => template.brand === "trace"
    )?.[0];
    setSelectedTemplate(tpl);
    product.productTemplateId = tpl.id;
    message.info({ key: "loading", content: "Setting up..." });
    const newProduct = await dispatch(updateProduct(product.id, product));
    message.destroy("loading");
  };

  const handleConditionChange = (e: any) => {
    setInventoryToAdd({
      ...inventoryToAdd,
      option1Value: e.target.value,
    });
  };

  useEffect(() => {
    if (updatedProduct) {
      setProductData({ ...updatedProduct });
      setInventoryToAdd({ ...inventoryToAdd, productId: updatedProduct.id });
      setCurrentStep((prev) => prev + 1);
    }
  }, [updatedProduct]);

  useEffect(() => {
    if (createdProduct) {
      setIsCustom(true);
      setProductData({ ...createdProduct });
      setInventoryToAdd({ ...inventoryToAdd, productId: createdProduct.id });
      setCurrentStep((prev) => prev + 1);
    }
  }, [createdProduct]);

  const updateLocation = (location: string) => {
    setInventoryToAdd({ ...inventoryToAdd, location });
  };

  const handleCompleteProcess = async () => {
    const createBuyOrderFromApiData = {
      inventoryFormValues: [
        {
          ...inventoryToAdd,
          option2Value:
            inventoryToAdd.option2Value &&
            Array.isArray(inventoryToAdd.option2Value)
              ? inventoryToAdd.option2Value.join(",")
              : undefined,
          option3Value:
            inventoryToAdd.option3Value &&
            Array.isArray(inventoryToAdd.option3Value)
              ? inventoryToAdd.option3Value.join(",")
              : undefined,
          option4Value:
            inventoryToAdd.option4Value &&
            Array.isArray(inventoryToAdd.option4Value)
              ? inventoryToAdd.option4Value.join(",")
              : undefined,
          option5Value:
            inventoryToAdd.option5Value &&
            Array.isArray(inventoryToAdd.option5Value)
              ? inventoryToAdd.option5Value.join(",")
              : undefined,
          option6Value:
            inventoryToAdd.option6Value &&
            Array.isArray(inventoryToAdd.option6Value)
              ? inventoryToAdd.option6Value.join(",")
              : undefined,
        },
      ],
      store,
      inventoryExtra,
    };
    if (isCustom) {
      await dispatch(updateProduct(createdProduct.id, productData));
    }
    console.log(createBuyOrderFromApiData);
    await createBuyOrderFromApi(createBuyOrderFromApiData);
    message.success("Product added!");
    reloadInventories();
    setShowAddProductModal(false);
  };

  useEffect(() => {
    if (!showAddProductModal) {
      setIsCustom(false);
      setCurrentStep(1);
      setInventoryToAdd({
        quantity: 1,
        option1Value: "",
        option2Value: "",
        option3Value: "",
        status: "Pending",
        price: 0,
        cost: 0,
        consigner: "",
        category: "",
        productId: "",
        location: "",
      });
      setInventoryExtra({
        brandLogo: "",
        frontImage: "",
        manufacturingTag: "",
        insideLabelTwo: "",
        insideLabelThree: "",
        serialCode: "",
        zipperFront: "",
        zipperBack: "",
        // accessories: [],
      });
    }
  }, [showAddProductModal]);

  if (
    updateProductLoading ||
    productTemplatesLoading ||
    inventoryFilterOptionsLoading ||
    createProductLoading
  )
    return <Spin />;

  if (currentStep !== 1 && !productData) return <Spin />;

  return (
    <Modal
      title="Add product"
      visible={showAddProductModal}
      onCancel={() => setShowAddProductModal(false)}
      cancelText="Back"
      okText="Done"
      width={1200}
      style={{ top: 0 }}
      maskStyle={{ top: 0 }}
      bodyStyle={{ width: 1200, maxWidth: "100%" }}
      footer={null}
    >
      {currentStep === 1 ? (
        <main>
          <GridContainer>
            {!isMobile && <h1>Select Your Products</h1>}
            <span
              style={{ display: "flex", gap: 20, height: 50, marginBottom: 15 }}
            >
              <StyledSearchInput
                onSearch={searchProducts}
                placeholder="Search Products"
              />
              <Button
                type="default"
                style={{
                  height: 50,
                  background: "#000",
                  color: "#fff",
                  textTransform: "uppercase",
                  borderRadius: 8,
                }}
                onClick={() => {
                  setIsCustom(true);
                  dispatch(
                    createProduct({
                      title: "",
                      brand: "",
                      category: "custom",
                      sku: "",
                      images: "",
                      productTemplateId: productTemplates![0].id,
                      stockXId: "",
                      shopifyId: "",
                      image: "",
                      gender: "",
                    })
                  );
                }}
              >
                Custom Product
              </Button>
            </span>
            <StyledRow gutter={[8, 8]}>
              {productsLoading ? (
                <Spin size="large" />
              ) : (
                products.map((product) => (
                  <StyledCol xs={12} sm={12} md={8} lg={6} key={product.id}>
                    {/* <Checkbox checked={inventoriesToAdd.find((inventory) => inventory.productId === product.id)} /> */}
                    <ProductCard
                      name={product.title}
                      image={product.image}
                      brand={product.brand}
                      onClick={() => handleSelectProduct(product)}
                    />
                  </StyledCol>
                ))
              )}
            </StyledRow>
          </GridContainer>
        </main>
      ) : (
        <GridContainer style={{ width: "100%", maxWidth: "100%" }}>
          {!isMobile && <h1>Describe the Condition</h1>}
          <div
            style={{
              display: "flex",
              gap: isMobile ? 10 : 40,
              flexDirection: "column",
            }}
          >
            <Row
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "nowrap",
                gap: 16,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {productData?.image && (
                <Col style={{ flex: "0 0 80px" }}>
                  <Image src={productData?.image} />
                </Col>
              )}
              <Col style={{ flex: 1 }}>
                <label>Name of product</label>
                <Input
                  value={productData?.title}
                  onChange={(ev) =>
                    setProductData({ ...productData, title: ev.target.value })
                  }
                  style={{
                    width: "100%",
                    height: 40,
                    fontSize: 13,
                    lineHeight: 16,
                  }}
                />
              </Col>
              <Col style={{ flex: 1 }}>
                <label>SKU</label>
                <Input
                  value={productData?.sku}
                  onChange={(ev) =>
                    setProductData({ ...productData, sku: ev.target.value })
                  }
                  style={{
                    width: "100%",
                    height: 40,
                    fontSize: 13,
                    lineHeight: 16,
                  }}
                />
              </Col>
              <Col style={{ flex: 1 }}>
                <label>Consignor</label>
                <CustomSelect
                  value={inventoryToAdd.consigner}
                  onChange={(ev: any) =>
                    setInventoryToAdd({ ...inventoryToAdd, consigner: ev })
                  }
                  style={{
                    width: "100%",
                    height: 40,
                    fontSize: 13,
                    lineHeight: 16,
                    alignSelf: "stretch",
                  }}
                >
                  {inventoryFilterOptions.consigners.map((consigner, idx) => (
                    <Select.Option key={idx} value={consigner.value.fbUserId}>
                      {consigner.label}
                    </Select.Option>
                  ))}
                </CustomSelect>
              </Col>
            </Row>

            {isMobile && (
              <TitleLabel>
                <b>Product Images</b>
                <label>You can add multiple images for your product</label>
              </TitleLabel>
            )}
            <Col>
              <label>Required Photos</label>
              <CRow>
                <div>
                  <ImageUpload
                    form={formUpload}
                    logo={inventoryExtra.frontImage}
                    uploadedLogo={(e) =>
                      setInventoryExtra({ ...inventoryExtra, frontImage: e })
                    }
                  />
                  <p>Front</p>
                </div>
                <div>
                  <ImageUpload
                    form={formUpload}
                    logo={inventoryExtra.brandLogo}
                    uploadedLogo={(e) =>
                      setInventoryExtra({ ...inventoryExtra, brandLogo: e })
                    }
                  />
                  <p>Brand Logo</p>
                </div>
                <div>
                  <ImageUpload
                    form={formUpload}
                    logo={inventoryExtra.manufacturingTag}
                    uploadedLogo={(e) =>
                      setInventoryExtra({
                        ...inventoryExtra,
                        manufacturingTag: e,
                      })
                    }
                  />
                  <p>Manufacturing Tag</p>
                </div>
                <div>
                  <ImageUpload
                    form={formUpload}
                    logo={inventoryExtra.insideLabelTwo}
                    uploadedLogo={(e) =>
                      setInventoryExtra({
                        ...inventoryExtra,
                        insideLabelTwo: e,
                      })
                    }
                  />
                  <p>Inside label (2)</p>
                </div>
                <div>
                  <ImageUpload
                    form={formUpload}
                    logo={inventoryExtra.insideLabelThree}
                    uploadedLogo={(e) =>
                      setInventoryExtra({
                        ...inventoryExtra,
                        insideLabelThree: e,
                      })
                    }
                  />
                  <p>Inside label (3)</p>
                </div>
                <div>
                  <ImageUpload
                    form={formUpload}
                    logo={inventoryExtra.serialCode}
                    uploadedLogo={(e) =>
                      setInventoryExtra({ ...inventoryExtra, serialCode: e })
                    }
                  />
                  <p>Serial Code</p>
                </div>
                <div>
                  <ImageUpload
                    form={formUpload}
                    logo={inventoryExtra.zipperFront}
                    uploadedLogo={(e) =>
                      setInventoryExtra({ ...inventoryExtra, zipperFront: e })
                    }
                  />
                  <p>Zipper (Front)</p>
                </div>
                <div>
                  <ImageUpload
                    form={formUpload}
                    logo={inventoryExtra.zipperBack}
                    uploadedLogo={(e) =>
                      setInventoryExtra({ ...inventoryExtra, zipperBack: e })
                    }
                  />
                  <p>Zipper (Back)</p>
                </div>
              </CRow>
            </Col>
            <Row>
              <Col style={{ flex: isMobile ? "0 0 100%" : 1 }}>
                <TitleLabel>
                  <b>Product Condition</b>
                  <label>Please select one</label>
                </TitleLabel>
                <Row>
                  <ConditionSelector onChange={handleConditionChange}>
                    {conditions?.map((condition, idx) => (
                      <Radio.Button
                        key={idx}
                        value={condition}
                        style={{ textTransform: "capitalize" }}
                      >
                        {condition}
                      </Radio.Button>
                    ))}
                  </ConditionSelector>
                </Row>
                {selectedTemplate &&
                  selectedTemplate?.option2 &&
                  selectedTemplate?.option2Values && (
                    <div>
                      <TitleLabel>
                        <b>{selectedTemplate?.option2}</b>
                        <label>You can select multiple choices</label>
                      </TitleLabel>
                      <Row>
                        <AccessorySelector>
                          {selectedTemplate?.option2Values.map((acc, idx) => (
                            <Button
                              key={idx}
                              onClick={() => handleOption2Change(acc)}
                              className={`${
                                inventoryToAdd.option2Value.includes(acc)
                                  ? "checked"
                                  : ""
                              }`}
                            >
                              <Checkbox
                                checked={inventoryToAdd.option2Value.includes(
                                  acc
                                )}
                                style={{ textTransform: "capitalize" }}
                              />
                              <b>{acc}</b>
                            </Button>
                          ))}
                        </AccessorySelector>
                      </Row>
                    </div>
                  )}
                {selectedTemplate &&
                  selectedTemplate?.option3 &&
                  selectedTemplate?.option3Values && (
                    <div>
                      <TitleLabel>
                        <b>{selectedTemplate?.option3}</b>
                        <label>You can select multiple choices</label>
                      </TitleLabel>
                      <Row>
                        <AccessorySelector>
                          {selectedTemplate?.option3Values.map((acc, idx) => (
                            <Button
                              key={idx}
                              onClick={() => handleOption3Change(acc)}
                              className={`${
                                inventoryToAdd.option3Value.includes(acc)
                                  ? "checked"
                                  : ""
                              }`}
                            >
                              <Checkbox
                                checked={inventoryToAdd.option3Value.includes(
                                  acc
                                )}
                                style={{ textTransform: "capitalize" }}
                              />
                              <b>{acc}</b>
                            </Button>
                          ))}
                        </AccessorySelector>
                      </Row>
                    </div>
                  )}
                {selectedTemplate &&
                  selectedTemplate?.option4 &&
                  selectedTemplate?.option4Values && (
                    <div>
                      <TitleLabel>
                        <b>{selectedTemplate?.option4}</b>
                        <label>You can select multiple choices</label>
                      </TitleLabel>
                      <Row>
                        <AccessorySelector>
                          {selectedTemplate?.option4Values.map((acc, idx) => (
                            <Button
                              key={idx}
                              onClick={() => handleOption4Change(acc)}
                              className={`${
                                inventoryToAdd.option4Value.includes(acc)
                                  ? "checked"
                                  : ""
                              }`}
                            >
                              <Checkbox
                                checked={inventoryToAdd.option4Value.includes(
                                  acc
                                )}
                                style={{ textTransform: "capitalize" }}
                              />
                              <b>{acc}</b>
                            </Button>
                          ))}
                        </AccessorySelector>
                      </Row>
                    </div>
                  )}
                {selectedTemplate &&
                  selectedTemplate?.option5 &&
                  selectedTemplate?.option5Values && (
                    <div>
                      <TitleLabel>
                        <b>{selectedTemplate?.option5}</b>
                        <label>You can select multiple choices</label>
                      </TitleLabel>
                      <Row>
                        <AccessorySelector>
                          {selectedTemplate?.option5Values.map((acc, idx) => (
                            <Button
                              key={idx}
                              onClick={() => handleOption5Change(acc)}
                              className={`${
                                inventoryToAdd.option5Value.includes(acc)
                                  ? "checked"
                                  : ""
                              }`}
                            >
                              <Checkbox
                                checked={inventoryToAdd.option5Value.includes(
                                  acc
                                )}
                                style={{ textTransform: "capitalize" }}
                              />
                              <b>{acc}</b>
                            </Button>
                          ))}
                        </AccessorySelector>
                      </Row>
                    </div>
                  )}
                {selectedTemplate &&
                  selectedTemplate?.option6 &&
                  selectedTemplate?.option6Values && (
                    <div>
                      <TitleLabel>
                        <b>{selectedTemplate?.option6}</b>
                        <label>You can select multiple choices</label>
                      </TitleLabel>
                      <Row>
                        <AccessorySelector>
                          {selectedTemplate?.option6Values.map((acc, idx) => (
                            <Button
                              key={idx}
                              onClick={() => handleOption6Change(acc)}
                              className={`${
                                inventoryToAdd.option6Value.includes(acc)
                                  ? "checked"
                                  : ""
                              }`}
                            >
                              <Checkbox
                                checked={inventoryToAdd.option6Value.includes(
                                  acc
                                )}
                                style={{ textTransform: "capitalize" }}
                              />
                              <b>{acc}</b>
                            </Button>
                          ))}
                        </AccessorySelector>
                      </Row>
                    </div>
                  )}
              </Col>
            </Row>
            <LocationPriceInput style={{ justifyContent: "space-evenly" }}>
              <div>
                <label htmlFor="">Set Price</label>
                <Input
                  // bordered={false}
                  prefix={getSymbolFromCurrency(store.currency) || "$"}
                  value={inventoryToAdd.price > 0 ? inventoryToAdd.price : ""}
                  onChange={(value) =>
                    setInventoryToAdd({
                      ...inventoryToAdd,
                      price:
                        Number(value.target.value.replace(/[^0-9]/g, "")) ?? 0,
                    })
                  }
                />
              </div>
              <div>
                <label htmlFor="">Location</label>
                <Select
                  allowClear
                  placeholder="Select location"
                  showSearch
                  bordered={false}
                  style={{ width: "100%" }}
                  value={inventoryToAdd.location}
                  onChange={(value: string) => updateLocation(value)}
                >
                  {inventoryFilterOptions?.locations?.map((location: any) => (
                    <Select.Option key={location.label} value={location.label}>
                      {location.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </LocationPriceInput>
            <ButtonGroup
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                gap: 12,
              }}
            >
              <Button
                style={{
                  alignSelf: "stretch",
                  height: 68,
                  width: 203,
                  maxWidth: "50%",
                  // flex: 1,
                  borderRadius: 8,
                  background: "var(--Grey---Button-Fill, #F4F4F4)",
                }}
                type="default"
                onClick={() => setCurrentStep((prev) => prev - 1)}
              >
                Back
              </Button>
              <StyledNextButton
                style={{
                  position: "relative",
                  left: "initial",
                  bottom: "initial",
                  transform: "none",
                  background: "#000",
                  color: "#fff",
                  // flex: 1,
                  height: 68,
                  width: 520,
                }}
                disabled={
                  !inventoryToAdd.consigner || inventoryToAdd.consigner === ""
                }
                onClick={handleCompleteProcess}
                // disabled={ /*  || (newInventory && Number(newInventory.price) === 0) */}
              >
                Done
              </StyledNextButton>
            </ButtonGroup>
          </div>
        </GridContainer>
      )}
    </Modal>
  );
};

export default AddProductModal;
