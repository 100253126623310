import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { AppState } from "../redux/reducers/AppReducer";
import { ConsignerState } from "../redux/reducers/ConsignerReducer";
import { InventoryState } from "../redux/reducers/InventoryReducer";
import { StoreState } from "../redux/reducers/StoreReducer";
import { UserState } from "../redux/reducers/UserReducer";
import {
  ConsignerStats,
  CustomB,
  MobileFooter,
  PageContainer,
} from "./ConsignerInventory";
import { getConsignerReport } from "../redux/actions/consignerActions";
import {
  getConsignerPayouts,
  getPayoutFilterOptions,
} from "../redux/actions/payoutActions";
import { Payout } from "../redux/reducers/PayoutReducer";
import { Order } from "../redux/reducers/OrderReducer";
import {
  DatePicker,
  Spin,
  Table,
  Image,
  Card,
  List,
  Button,
  Select,
  Collapse,
} from "antd";
import { ReactComponent as Calendar } from "../assets/images/svg/Calendar.svg";
const { RangePicker } = DatePicker;
import styled from "styled-components";
import Colors from "../constants/Colors";
import moment from "moment";
import Link from "antd/lib/typography/Link";
import { EditOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import getSymbolFromCurrency from "currency-symbol-map";
import { getPayoutInventories } from "../redux/actions/inventoryActions";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import _ from "lodash";

const CustomSpan = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  justify-content: flex-end;
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .ant-picker-range {
      background: transparent;
      width: 225px;
      border: 0;
      color: ${Colors.CONSIGNER_GREY};
    }
    .ant-picker-input > input {
      color: #9ca3af;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 8px;
    span {
      .ant-picker-input > input {
        font-size: 12px;
      }

      .ant-picker-range {
        width: 200px;
      }
    }
  }
`;

const ReviewListingContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    order: 5;
    padding-bottom: 24px;
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          // padding: 0;
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            padding: 0;
          }
        }
      }
    }
  }
`;

const Listing = styled.div`
  padding: 8px 12px;
  display: flex;
  border: 1px solid var(--stroke-color, #e4e4e7);
  background: #fff;
  align-items: center;
  .ant-image {
    flex: 0 0 85px;
    img {
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  label {
    flex: 0 0 35px;
  }
  @media (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const ReviewSpan = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-weight: 400;
  position: relative;
  font-size: 11px;
  button {
    border: 0;
    color: ${Colors.CONSIGNER_BLUE};
  }
  b {
    max-width: 60%;
    font-size: 12px;
  }
  div {
    color: ${Colors.CONSIGNER_GREY};
    text-transform: uppercase;
    align-self: end;
  }
`;

const DetailsPanel = styled.div`
  display: flex;
  flex: 5 1 auto;
  padding: 0 0 0 20px;
  flex-direction: column;
`;

export const ConsignerTable = styled(Table)`
  width: 100%;
  padding: 8px 16px;
  order: 5;

  .ant-table .ant-table-thead .ant-table-cell {
    background: var(--neutral-100, #f3f4f6);
    text-transform: uppercase;
    color: #2e2e2e;
    padding: 10px 8px;
  }
  .ant-table {
    .ant-table-tbody {
      .ant-table-cell {
        .ant-table {
          margin: 0;
        }
      }
    }
  }
`;

const CustomCard = styled(Card)`
  padding: 0;
  .ant-card-head {
    padding: 8px 12px;
    border: 1px solid var(--stroke-color, #e4e4e7);
    background: #fff;
    .ant-card-head-title {
      padding: 0;
    }
  }
  .ant-card-body {
    padding: 0;
    border: 1px solid var(--stroke-color, #e4e4e7);
    background: #fff;
  }
`;

const goToPayoutPage = (
  history: RouteComponentProps["history"],
  payout: Order | Payout
) => {
  history.push(`/payouts/${payout.id}`);
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const visibleColumns: ColumnTypes[number][] = [
  {
    title: "ID",
    key: "id",
    dataIndex: "id",
    render: (_: any, record: any) => record.code,
  },
  {
    title: "Product",
    key: "productImage",
    dataIndex: "product.image",
    render: (_: any, record: any) => (
      <Image
        src={record.product.image}
        alt=""
        style={{ width: 100, height: "auto" }}
      ></Image>
    ),
  },
  {
    title: "Name",
    key: "productName",
    dataIndex: "product.title",
    render: (_: any, record: any) => (
      <span>
        {record.product.title}
        <br />
        {record.code}
      </span>
    ),
  },
  {
    title: "SKU",
    key: "sku",
    dataIndex: "product.sku",
    render: (_: any, record: any) => record.product.sku,
  },
  {
    title: "Location",
    key: "option3Value",
    dataIndex: "option3Value",
    render: (_: any, record: any) => record?.option3Value ?? null,
  },
  {
    title: "Size",
    key: "option1Value",
    dataIndex: "option1Value",
    render: (_: any, record: any) => record?.option1Value ?? null,
  },
  {
    title: "Condition",
    key: "option2Value",
    dataIndex: "option2Value",
    render: (_: any, record: any) => record?.option2Value ?? null,
  },
  // {
  //   title: "Status",
  //   key: "status",
  //   dataIndex: "status",
  //   render: (_: any, record: any) => (
  //     <CustomB className="underlined green">{record.status}</CustomB>
  //   ),
  // },
  {
    title: "Active Date",
    key: "acceptedOn",
    dataIndex: "acceptedOn",
    render: (_: any, record: any) =>
      moment(record.acceptedOn ?? record.updatedAt).format("YY/MM/DD"),
  },
  {
    title: "Price",
    key: "price",
    dataIndex: "price",
    render: (_: any, record: any) =>
      `${getSymbolFromCurrency(record.currency)}${record.price}`,
  },
  {
    title: "Payout",
    key: "total",
    dataIndex: "total",
    render: (_: any, record: any) =>
      `${getSymbolFromCurrency(record.currency)}${Number(
        record.payoutAmount
      ).toFixed(2)}`,
  },
  // {
  //   title: "Edit",
  //   dataIndex: "operation",
  //   render: (_: any, record: any) => (
  //     <Link target="_blank" href={`/productItem/${record.productId}`}>
  //       <Button icon={<EditOutlined />}></Button>
  //     </Link>
  //   ),
  // },
];

export const ExpandedRowRenderForPayout = (
  record,
  store,
  cols = visibleColumns
) => {
  const { inventories } = record;
  const dataSource = inventories.map((inventory) => ({
    ...inventory,
    currency: store.currency,
    checkId: record.name,
  }));

  return (
    <Table
      dataSource={dataSource}
      // style={{ marginBottom: 50, marginTop: 12 }}
      columns={cols as ColumnTypes}
      scroll={{ x: "max-content" }}
      rowKey="id"
      pagination={{
        pageSize: 10,
        // responsive: true,
        hideOnSinglePage: true,
      }}
      showHeader={false} //process.env.REACT_APP_TYPE === "employee"
    />
  );
};

export const PayoutListingView = (props: any) => {
  const { payouts, store, loading, actions } = props;

  if (loading) return <Spin />;
  return (
    <ReviewListingContainer>
      <Collapse accordion>
        {payouts.map((payout, key) => {
          const { inventories } = payout;
          return (
            <CollapsePanel
              key={key}
              header={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <b>{payout?.name}</b>
                  <b>
                    {getSymbolFromCurrency(store.currency)}
                    {inventories
                      .reduce(
                        (sum, inventory) =>
                          sum + Number(inventory.payoutAmount),
                        0
                      )
                      .toFixed(2)}
                  </b>
                  <b>{inventories.length} items</b>
                  {actions(payout)}
                </div>
              }
            >
              <CustomCard>
                {inventories.map((record) => (
                  <Listing key={record.id}>
                    <Image src={record?.product?.image}></Image>
                    <DetailsPanel>
                      <ReviewSpan>
                        <b>{record?.product?.title}</b>
                        <b>
                          {getSymbolFromCurrency(store.currency)}
                          {Number(record.payoutAmount).toFixed(2)}
                        </b>
                      </ReviewSpan>
                      <ReviewSpan>
                        <div>
                          {record.code} |{record?.product?.sku ?? record.sku} |{" "}
                          {record.option1Value} | {record.option2Value}
                        </div>
                        {/* <Link target="_blank" href={`/productItem/${record?.id}`}>
                        <Button type="ghost" icon={<EditOutlined />}></Button>
                      </Link> */}
                      </ReviewSpan>
                    </DetailsPanel>
                  </Listing>
                ))}
              </CustomCard>
            </CollapsePanel>
          );
        })}
      </Collapse>
    </ReviewListingContainer>
  );
};

const StatusSelectorDiv = styled.div`
  width: 100%;
  text-align: left;
  @media (max-width: 768px) {
    order: 2;
    margin: 40px 0 20px;
  }
`;

const StatusSelectorButton = styled(Button)`
  border: 0;
  &.ant-btn-ghost {
    color: ${Colors.CONSIGNER_GREY};
  }
`;

export const StatusSelector = (props: any) => {
  const { filters, setFilters, payoutFilterOptions, isMobile } = props;
  const { statuses } = payoutFilterOptions;
  const consignerFilterStatuses = statuses.filter(
    (payoutFilter) => payoutFilter.value !== "Failed"
  );
  consignerFilterStatuses.unshift({
    label: "Ready to Pay",
    value: "Ready to Pay",
  });
  const [status, setStatus] = useState(filters.status);

  const handleSetStatus = (statValue: string) => {
    if (!status.includes(statValue)) {
      setStatus([...status, statValue]);
    } else {
      setStatus(status.filter((stat) => stat !== statValue));
    }
  };

  useEffect(() => {
    setFilters({ ...filters, status });
  }, [status]);

  return (
    <StatusSelectorDiv>
      {isMobile ? (
        <Select
          value={status}
          onChange={(value) => setStatus(value)}
          showSearch
          size="middle"
          style={{
            width: 99,
            border: "1px solid var(--stroke-color, #E4E4E7)",
            height: 30,
            fontSize: 16,
          }}
        >
          {consignerFilterStatuses.map((stat, key) => (
            <Select.Option key={key} value={stat.value}>
              {stat.label}
            </Select.Option>
          ))}
        </Select>
      ) : (
        consignerFilterStatuses.map((stat, key) => (
          <StatusSelectorButton
            key={key}
            type={status.includes(stat.value) ? "primary" : "ghost"}
            onClick={() => setStatus(stat.value)}
          >
            {stat.label}
          </StatusSelectorButton>
        ))
      )}
    </StatusSelectorDiv>
  );
};

const ConsignerPayout = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const { consignerReport, consignerReportLoading }: ConsignerState =
    useAppSelector((state) => state.ConsignerReducer);
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const [search, setSearch] = useState("");
  const [readyToPayData, setReadyToPayData] = useState<any[]>([]);
  const [filters, setFilters] = useState({
    printed: "",
    status: "Paid",
    option1Value: "",
    option2Value: "",
    option3Value: "",
    category: "",
    consigner: dbUser && dbUser.id ? dbUser.id : "",
    orderDateRange: undefined,
  });
  const {
    payouts,
    payoutsLoading,
    payoutFilterOptions,
    payoutFilterOptionsLoading,
  } = useAppSelector((state) => state.PayoutReducer);

  const { inventories, inventoriesLoading }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );

  useEffect(() => {
    dispatch(getPayoutFilterOptions());
  }, []);

  useEffect(() => {
    //fetch all reports data here
    dispatch(getConsignerReport(filters, filters?.orderDateRange));
  }, [filters.orderDateRange]);

  useEffect(() => {
    console.log(filters);
    if (filters.status === "Ready to Pay") {
      // let [dateStart, dateEnd] = filters.orderDateRange;
      // dateEnd = moment(dateEnd).add(1, "days").format("YYYY-MM-DD");
      const inventoryFilters = {
        consigner: filters.consigner,
        orderDateRange: [undefined, undefined],
        payoutsId: null,
      };
      dispatch(getPayoutInventories(search, inventoryFilters));
    } else {
      dispatch(getConsignerPayouts(search, filters));
    }
  }, [search, filters]);

  useEffect(() => {
    if (inventories && inventories.length > 0) {
      setReadyToPayData([
        {
          id: 0,
          name: "Ready to Pay",
          total: inventories
            .reduce((sum, inventory) => sum + Number(inventory.payoutAmount), 0)
            .toFixed(2),
          createdAt: moment(),
          status: "Ready to Pay",
          inventories,
          itemCount: inventories.length,
        },
      ]);
    }
  }, [inventories]);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Orders")
  ) {
    return <Redirect to="/" />;
  }
  if (!payoutFilterOptions || payoutFilterOptionsLoading)
    return (
      <PageContainer className="ConsignerPayoutPage">
        <Spin />
      </PageContainer>
    );

  const getColumns = (
    onItemAction: (payout: Payout) => void
  ): ColumnsType<any> => [
    {
      title: "Check ID",
      dataIndex: "id",
      key: "id",
      render: (text: string) => (
        <span data-testid="payoutTableItemId">#{text}</span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => (
        <span data-testid="payoutTableItemName">{text}</span>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      sorter: (a: any, b: any) => a.total - b.total,
      render: (text: string, record: any) => (
        <span data-testid="payoutTableItemTotal">
          {getSymbolFromCurrency(store?.currency)}
          {Number(
            record.inventories.reduce(
              (sum, inventory) => sum + Number(inventory.payoutAmount),
              0
            )
          ).toFixed(2)}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (text: string) => (
        <span data-testid="payoutTableItemOrderDate">
          {moment(text).format("LLL")}
        </span>
      ),
    },
    {
      title: "# of Shoes",
      dataIndex: "payout.inventories.length",
      render: (_: any, record: any) => record.inventories.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => (
        <span data-testid="payoutTableItemOrderDate">{text}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text: string, data: Payout) => (
        <Button type="link" color={Colors.CONSIGNER_BLUE}>
          View
        </Button>
      ),
    },
  ];

  return (
    <PageContainer className="ConsignerPayoutPage">
      <CustomSpan>
        <span>
          <Calendar />
          <RangePicker
            onChange={(dates, dateString: any) =>
              setFilters({ ...filters, orderDateRange: dateString })
            }
            allowClear={true}
            suffixIcon={false}
          />
        </span>
      </CustomSpan>
      <ConsignerStats consignerReport={consignerReport} store={store} />
      <StatusSelector
        filters={filters}
        setFilters={setFilters}
        payoutFilterOptions={payoutFilterOptions}
        isMobile={isMobile}
      />
      <>
        {isMobile ? (
          <PayoutListingView
            payouts={
              filters.status === "Ready to Pay" ? readyToPayData : payouts
            }
            store={store}
            loading={
              filters.status === "Ready to Pay"
                ? inventoriesLoading
                : payoutsLoading
            }
          />
        ) : (
          <ConsignerTable
            data-testid="payoutTable"
            columns={getColumns((payout) => goToPayoutPage(history, payout))}
            expandable={{
              expandedRowRender: (record) =>
                ExpandedRowRenderForPayout(record, store),
              rowExpandable: (data: any) => data.itemCount > 0,
              expandRowByClick: true,
              showExpandColumn: false,
            }}
            dataSource={
              filters.status === "Ready to Pay" ? readyToPayData : payouts
            }
            rowKey="id"
            loading={
              filters.status === "Ready to Pay"
                ? inventoriesLoading
                : payoutsLoading
            }
          />
        )}
      </>
    </PageContainer>
  );
};

export default ConsignerPayout;
