import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useLocation } from "react-router-dom";
import { Button, InputAdornment, Tab, Tabs, TextField } from "@mui/material";
import { createStore, getStoreForDomain } from "../redux/actions/storeActions";
import { StoreState } from "../redux/reducers/StoreReducer";
import AuthService from "../services/auth";
import { message } from "antd";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Cookies from "js-cookie";
import logo from "../assets/images/logo.png";

/**
 * Store Signup Screen
 * renders a screen where store can signup
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  background: rgb(100, 90, 255);
  background: linear-gradient(
    180deg,
    rgba(100, 90, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const AuthContainer = styled.div`
  background-color: white;
  margin-bottom: 50px;
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 36%);
  border-radius: 10px;
  width: fit-content;
  height: fit-content;
  padding-bottom: 30px;
  min-height: 50vh;
`;

const BodyContainer = styled.div`
  margin: auto 15px;
`;

const Logo = styled.img`
  height: 60px;
  width: auto;
  margin: 20px auto 20px;
  object-fit: coontain;
`;
const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.svg`
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-top: 0.2em solid #ffffff;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: ${Rotate360} 0.6s linear infinite;
`;

const SuccessMessage = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
`;

const validateEmail = (email: String) => {
  var re =
    /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  return re.test(String(email));
};

const StoreSignup = () => {
  const dispatch = useAppDispatch();
  const { createdSuccess, createStoreLoading }: StoreState = useAppSelector(
    (state) => state.StoreReducer
  );
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [handle, setHandle] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hideCard, setHideCard] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const auth = AuthService();

  useEffect(() => {
    const domain = Cookies.get("domain");
    if (domain) {
      Cookies.remove("domain");
    }
  }, []);

  useEffect(() => {
    if (createStoreLoading === false && loading === true) {
      setLoading(false);
    }
    if (createdSuccess === true) {
      setHideCard(true);
    } else {
      setHideCard(false);
    }
  });

  useEffect(() => {
    if (store?.domain) {
      message.success("Loading...");
      window.open(
        `${process.env.REACT_APP_APP_URL}/login?domain=${store.handle}.${
          process.env.REACT_APP_HOST ?? "fulltrace.co"
        }&shop=${store.shop?.shop}`,
        "_self"
      );
    } else {
      if (window.sessionStorage.getItem("setClick") === "clicked") {
        message.error("Handle doesn't exist.");
      }
    }
    window.sessionStorage.removeItem("setClick");
  }, [store]);

  const signupStore = async (name: string, email: string, handle: string) => {
    setLoading(true);
    if (email !== "" && validateEmail(email) && name !== "" && handle !== "") {
      const shop = queryParams.get("shop");
      const accessToken = queryParams.get("accessToken");
      const platform = queryParams.get("platform");
      message.success("Please check your email to continue");
      dispatch(createStore(name, email, handle, shop, accessToken, platform));
    } else {
      message.error("Please enter a valid email, name and handle");
      setLoading(false);
    }
  };

  const loginStore = async (handle: string) => {
    if (handle !== "") {
      Cookies.remove("domain");
      window.sessionStorage.setItem("setClick", "clicked");
      await dispatch(
        getStoreForDomain(
          handle + `.${process.env.REACT_APP_HOST}` ?? ".fulltrace.co"
        )
      );
    } else {
      message.error("Please enter a valid handle");
    }
  };
  return (
    <Container>
      <AuthContainer>
        <Tabs
          value={selectedTab}
          onChange={(event: React.SyntheticEvent, index: number) =>
            setSelectedTab(index)
          }
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Login" />
          <Tab label="Signup" />
        </Tabs>
        <BodyContainer>
          <LogoContainer>
            <Logo src={logo} />
          </LogoContainer>
          {selectedTab === 0 && (
            <Card>
              <TextField
                id="filled-basic"
                label="Handle"
                variant="filled"
                style={{ marginBottom: 15 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      .{`${process.env.REACT_APP_HOST ?? "fulltrace.co"}`}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setHandle(e.target.value)}
              />

              <Button onClick={() => loginStore(handle)} variant="contained">
                Login
              </Button>
            </Card>
          )}
          {hideCard && selectedTab === 0 ? (
            <SuccessMessage>
              <p style={{ fontSize: "2em", margin: "1em" }}>
                Success! Please check your email to complete your signup.
              </p>
            </SuccessMessage>
          ) : (
            <>
              {selectedTab === 1 && (
                <Card>
                  <TextField
                    type="text"
                    id="filled-basic"
                    label="Company Name"
                    variant="filled"
                    style={{ marginBottom: 15 }}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    type="email"
                    id="filled-basic"
                    label="Email"
                    variant="filled"
                    style={{ marginBottom: 15 }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    id="filled-basic"
                    label="Handle"
                    variant="filled"
                    style={{ marginBottom: 15 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          .{`${process.env.REACT_APP_HOST ?? "fulltrace.co"}`}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setHandle(e.target.value)}
                  />

                  <Button
                    onClick={() => signupStore(name, email, handle)}
                    variant="contained"
                    disabled={loading}
                  >
                    {loading ? <StyledSpinner /> : "Signup"}
                  </Button>
                </Card>
              )}
            </>
          )}
        </BodyContainer>
      </AuthContainer>
    </Container>
  );
};

export default StoreSignup;
