import React from "react";
import { Button, Table } from "antd";
import styled from "styled-components";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import Content from "../../constants/Content";
import {
  Inventory,
  InventoryFilterOptions,
} from "../../redux/reducers/InventoryReducer";
import InventoryFilter from "./InventoryFilter";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import getSymbolFromCurrency from "currency-symbol-map";
import type { ColumnsType, TableProps } from "antd/es/table";
import { Store } from "../../redux/reducers/StoreReducer";
import { consigner } from "../../constants/TestData";

/**
 * Inventory Table
 * Takes a list of inventory items and renders a filterable table
 * - List of inventory
 * - Filter
 * - Action to go to inventory page
 * TODO Test
 * - Filter changes
 */
const Container = styled.div``;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const HeaderTitle = styled.p``;

//types
interface Props {
  onFilter: (values: any) => void;
  inventories: Inventory[];
  loading: boolean;
  onInventoryItemAction: (inventory: Inventory) => void;
  inventoryFilterOptions: InventoryFilterOptions;
  resyncProduct: () => void;
  resyncProductLoading: boolean;
  store: Store;
}
interface Columns {
  title: string;
  dataIndex: string;
  key: string;
  responsive?: Breakpoint[] | undefined;
  colSpan?: number;
  render: (key: string, data: Inventory) => JSX.Element;
  code: string;
  option1Value: string;
  status: string;
  location: string;
  subLocation: string;
  consigner: string;
  price: string;
  payout: string;
  shopifyId: string;
  id: string;
}
interface HeaderProps {
  onFilter: (values: any) => void;
  inventoryFilterOptions: InventoryFilterOptions;
  resyncProduct: () => void;
  resyncProductLoading: boolean;
  inventories: any;
}

const Header = (props: HeaderProps): JSX.Element => {
  const {
    onFilter,
    inventoryFilterOptions,
    resyncProduct,
    resyncProductLoading,
    inventories,
  } = props;
  return (
    <HeaderContainer data-testid="inventoryTableHeader">
      <HeaderTitle data-testid="inventoryTableHeaderTitle">
        {Content.INVENTORY_TABLE_HEADER_TITLE}
      </HeaderTitle>
      {/* <InventoryFilter
        onFilter={onFilter}
        formOptions={inventoryFilterOptions}
        showConsignerFilter
      />
     */}
      <Button
        loading={resyncProductLoading}
        danger
        type="primary"
        disabled={inventories.length === 0}
        onClick={() => resyncProduct()}
      >
        {Content.RESYNC_BUTTON_TEXT}
      </Button>
    </HeaderContainer>
  );
};

const InventoryTable = (props: Props) => {
  const {
    onFilter,
    inventories,
    loading,
    onInventoryItemAction,
    inventoryFilterOptions,
    resyncProduct,
    resyncProductLoading,
    store,
  } = props;

  const { statuses } = inventoryFilterOptions;

  const onFilterAction = (
    value: string | boolean | number,
    record: any
  ): boolean => {
    if (record.consigner.isActive === value) return true;
    return record.consigner.isActive === value;
  };

  const getColumns = (
    onInventoryItemAction: (inventory: Inventory) => void
  ): ColumnsType<any> => [
    {
      title: "QR",
      dataIndex: "code",
      key: "qr",
      render: (text: string) => (
        <QRCode size={80} data-testid="inventoryTableItemQR" value={text} />
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text: string) => (
        <span
          style={{ wordBreak: "break-all" }}
          data-testid="inventoryTableItemCode"
        >
          {text}
        </span>
      ),
    },
    {
      title: "Options",
      dataIndex: "option1Value",
      key: "option1Value",
      sorter: (a: any, b: any) => a.option1Value.localeCompare(b.option1Value),
      render: (text: string, record: Inventory) => (
        <span
          style={{ wordBreak: "break-all" }}
          data-testid="inventoryTableItemOption"
        >
          <div>
            {record.option1Value} {record.option2Value} {record.option3Value}
          </div>
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // responsive: ["md"],
      filters: statuses.map((status) => ({
        text: status.label,
        value: status.value,
      })),
      onFilter: (value: any, record) => record.status === value,
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      render: (text: string, record: Inventory) => (
        <div style={{ wordBreak: "break-all" }}>{record.status}</div>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      sorter: (a: any, b: any) => {
        if (a.location === null) return 1;
        if (b.location === null) return -1;
        return a.location.localeCompare(b.location);
      },
      render: (text: string) => (
        <span
          style={{ wordBreak: "break-all" }}
          data-testid="inventoryTableItemLocation"
        >
          {text}
        </span>
      ),
    },
    {
      title: "Sublocation",
      dataIndex: "subLocation",
      key: "subLocation",
      responsive: ["md"],
      sorter: (a: any, b: any) => {
        if (a.subLocation === null) return 1;
        if (b.subLocation === null) return -1;
        return a.subLocation.localeCompare(b.subLocation);
      },
      render: (text: string) => (
        <span
          style={{ wordBreak: "break-all" }}
          data-testid="inventoryTableItemSubLocation"
        >
          {text}
        </span>
      ),
    },
    {
      title: "Consigner",
      dataIndex: "consigner",
      key: "consigner",
      //filters: getFilters(),
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Disabled",
          value: false,
        },
      ],
      onFilter: (value, record) => onFilterAction(value, record),
      sorter: (a: any, b: any) =>
        a.consigner.firstName.localeCompare(b.consigner.firstName),
      render: (text: string, record: Inventory) =>
        record.consigner && (
          <Link
            data-testid="inventoryTableItemConsignerLink"
            to={`/consigners/${record.consigner.id}`}
          >
            <span data-testid="inventoryTableItemConsigner">
              {record.consigner.firstName} {record.consigner.lastName}
            </span>
          </Link>
        ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a: any, b: any) => a.price - b.price,
      render: (text: string) => (
        <span
          style={{ wordBreak: "break-all" }}
          data-testid="inventoryTableItemPrice"
        >
          {getSymbolFromCurrency(store?.currency)}
          {text}
        </span>
      ),
    },
    {
      title: "Payout",
      dataIndex: "payoutAmount",
      key: "payout",
      sorter: (a: any, b: any) => a.payoutAmount - b.payoutAmount,
      render: (text: string) => (
        <span
          style={{ wordBreak: "break-all" }}
          data-testid="inventoryTableItemPrice"
        >
          {getSymbolFromCurrency(store?.currency)}
          {text}
        </span>
      ),
    },
    {
      title: "Synced?",
      dataIndex: "shopifyId",
      key: "shopifyId",
      responsive: ["md"],
      render: (text: string, record: Inventory) =>
        record.consigner && (
          <span data-testid="inventoryTableItemShopifyId">
            {record.shopifyId && record.status === "Active"
              ? "Synced"
              : "Not Synced"}
          </span>
        ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text: string, data: Inventory) => (
        <Button
          size="small"
          data-testid="inventoryTableItemActionBtn"
          onClick={() => onInventoryItemAction(data)}
          type="primary"
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <Container data-testid="inventoryTableContainer" style={{ marginTop: 10 }}>
      <Table
        data-testid="inventoryTable"
        title={() => (
          <Header
            inventoryFilterOptions={inventoryFilterOptions}
            onFilter={onFilter}
            resyncProduct={resyncProduct}
            resyncProductLoading={resyncProductLoading}
            inventories={inventories}
          />
        )}
        columns={getColumns(onInventoryItemAction)}
        dataSource={inventories}
        rowKey="id"
        loading={loading}
        size="small"
        scroll={{ x: "max-content" }}
      />
    </Container>
  );
};

export default InventoryTable;
