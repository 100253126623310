import { ExpressZodAPIClient } from "../generated/client";
import { getToken } from "../redux/actions/usersActions";

export const api = new ExpressZodAPIClient(async (method, path, params) => {
  const hasBody = !["get", "delete"].includes(method);
  const searchParams = hasBody ? "" : `?${new URLSearchParams(params)}`;

  const isApi = path.replaceAll("/", "").substring(0, 3) === "api";

  const headers = {
    ...(isApi && { Authorization: `Bearer ${await getToken()}` }),
    ...(hasBody && { "Content-Type": "application/json" }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API}${path}${searchParams}`,
    {
      method: method.toUpperCase(),
      headers,
      body: hasBody ? JSON.stringify(params) : undefined,
    }
  );

  return response.json();
});
