import React, { ReactElement, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
//Redux Stuff
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import AuthService from "../../services/auth";
//components
import Topbar from "../../components/Layout/Topbar";
import Footer from "../../components/Layout/Footer";
import LoginCard from "../../components/Auth/LoginCard";
import { Result, Button } from "antd";
//constants
import Content from "../../constants/Content";
import Colors from "../../constants/Colors";
import { consignerNavItems, navItems } from "../../nav/navData";
/*
Login Component using Firebase Auth
When a user logges is, firebase sign in is called
User is redirected to secret routes via a firebase onAuthStateChanged listener
onAuthStateChanged fires when user login states is changed from logged in to logged off
The logic for this function is found in ./redux/actions/users/setUserAuthState.ts
*/
//styles
const Container = styled.div`
  background: ${Colors.BACKGROUND};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
`;
const ContentContainer = styled.div`
  width: 90%;

  @media (min-width: 412px) {
    width: 70%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 50%;
  }
  @media (min-width: 1025px) and (max-width: 1440px) {
    width: 25%;
  }
  @media (min-width: 1441px) {
    width: 20%;
  }
`;

//types
export interface LoginProps {}

export default function Login({}: LoginProps): JSX.Element {
  //global state / redux
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const { fbUser, isLoggedIn } = useAppSelector((state) => state.UserReducer);
  const dispatch = useAppDispatch();
  const auth = AuthService();
  const { store } = useAppSelector((state) => state.StoreReducer);

  //helpers
  const login = async (email: string, password: string) => {
    setLoginLoading(true);
    await auth.login(email, password);
    setLoginLoading(false);
  };
  const luxLogin = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const storedEmail = urlParams.get("string1");
    const storedPassword = urlParams.get("string2");
    console.log(
      "====storedEmail====",
      storedEmail,
      "====storedPassword====",
      storedPassword
    );

    if (storedEmail && storedPassword) {
      const user = await auth.signup(storedEmail, storedPassword);
      if (user) {
        setTimeout(async () => {
          await auth.login(storedEmail, storedPassword);
        }, 1000);
      }
    }
  };
  useEffect(() => {
    luxLogin();
  }, []);
  //component
  if (fbUser && isLoggedIn)
    return <Redirect data-testid="dashboardRedirect" to="/" />;
  else if (!isLoggedIn && fbUser)
    //This setTimeout is needed, if not, when signing out with an employee account, this page renders for 2 seconds before going to the login page.
    setTimeout(() => {
      return (
        <Container data-testid="login">
          <Topbar
            logo={store?.logo!}
            backText={Content.TOPBAR_BACK_TEXT}
            backLink={`https://${store?.redirectURL!}`}
            nav={
              process.env.REACT_APP_TYPE === "consigner"
                ? consignerNavItems
                : navItems
            }
          />
          <ContentContainer>
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Button
                  type="primary"
                  // href={Content.TOPBAR_BACK_LINK}
                  onClick={() => auth.logout()}
                >
                  Back To Login
                </Button>
              }
            />
          </ContentContainer>
          <Footer />
        </Container>
      );
    }, 300);

  return (
    <Container data-testid="login">
      <Topbar
        logo={store?.logo!}
        backText={Content.TOPBAR_BACK_TEXT}
        backLink={`https://${store?.redirectURL!}`}
        nav={
          process.env.REACT_APP_TYPE === "consigner"
            ? consignerNavItems
            : navItems
        }
      />
      <ContentContainer>
        <LoginCard
          onLogin={(email, password) => login(email, password)}
          email={email}
          password={password}
          onEmailChange={(newEmail: string) => setEmail(newEmail)}
          onPasswordChange={(newPassword: string) => setPassword(newPassword)}
          loginLoading={loginLoading}
        />
      </ContentContainer>

      <Footer />
    </Container>
  );
}
