export default {
  BLACK: "black",
  BACKGROUND: "#E5E5E5",
  WHITE: "white",
  GREY: "#757575",
  INPUT_BACKGROUND: "#F5F5F5",
  INPUT_BORDER: "#D8DCDF",
  BLUE: "#038FDE",
  BLUE_MAGENTA: "#645AFF",
  CONSIGNER_BG: "#F9FAFB",
  CONSIGNER_BLUE: "#746BF8",
  CONSIGNER_GREY: "#7F8080",
  ADMIN_NEUTRAL: "#F3F4F6",
  ADMIN_BLUE: "#67abf4",
  ADMIN_TR_HEADER: "#F8FAFC",
};
